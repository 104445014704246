import { parseJSON, isValid } from 'date-fns'

/* eslint-disable camelcase */
export class TrafficEvent {
  constructor(props) {
    const { id = null, url = null, title = '', description = '', reason = '', scope = {}, endTime = null, stationMap = {} } = props ?? {}

    Object.assign(this, {
      id,
      url,
      title,
      description,
      reason,
      scope: {
        ...scope,
        stations: scope.stations?.map?.((station) => ({ ...station, stopId: stationMap[String(station.id).padStart(4, '0')] ?? '' })) ?? [],
      },
      endTime: isValid(parseJSON(endTime)) ? parseJSON(endTime) : null,
    })
  }

  static mapKeysFromApi(props) {
    const {
      AlertID,
      AlertURL,
      Title,
      Description,
      Scope,
      Reason,
      EndTime,

      // NOTE: un-used
      // Level,
      // Direction,
      // Status,
      // Effect,
      // StartTime,
      // PublishTime,
      // UpdateTime,
    } = props ?? {}

    return {
      id: AlertID,
      url: AlertURL,
      title: Title,
      description: Description,
      reason: Reason,
      scope: TrafficEvent.mapScopeKeysFromApi(Scope),
      endTime: EndTime,
    }
  }

  static mapScopeKeysFromApi(props) {
    const {
      Stations,

      // NOTE: un-used
      // NetworkList,
      // Lines,
      // Routes,
      // Trains,
      // LineSections,
    } = props ?? {}

    return {
      stations: Stations?.map(({ StationID, StationName }) => ({ id: StationID, name: StationName })),
    }
  }
}
