import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Stack, Flex, Icon, Text, Badge } from '@chakra-ui/react'
import i18 from 'i18next'
import FiChevronRight from '@meronex/icons/fi/FiChevronRight'

import { ROUTING_SOURCE, FORMAT_OF_DATE } from 'src/configs'
import { convertSecondsToUnits, convertSecondsToDuration, getDistanceWithUnit } from 'src/libs/utils'
import { Context, TRANSPORT_MODE } from 'src/views/trip-planning/constants'
import { getFirstDepartureTime, getLastArrivalTime, getIcon, getTransitNotation } from 'src/views/trip-planning/utils'

export const RouteCard = ({ useGoogle, route, ...rest }) => {
  const { env } = React.useContext(Context)

  return (
    <Stack cursor='pointer' flexShrink='0' spacing='0.5rem' {...rest}>
      <Stack spacing='0'>
        {env.VISIBLE_OPTION.ADVANCED && useGoogle && (
          <Flex>
            <Badge
              paddingX='0.5rem'
              colorScheme={{ [ROUTING_SOURCE.GOOGLE]: 'green', [ROUTING_SOURCE.METROPIA]: 'orange' }[route.source]}
              color='gray.700'
              lineHeight='1rem'
              fontWeight='500'
            >
              {
                {
                  [ROUTING_SOURCE.GOOGLE]: i18.t('trip-planning.suggestions-by-google'),
                  [ROUTING_SOURCE.METROPIA]: {
                    [window.LOCATION.TAIWAN]: i18.t('trip-planning.suggestions-by-umaji'),
                    [window.LOCATION.HOUSTON]: i18.t('trip-planning.suggestions-by-connect-smart'),
                  }[env.LOCATION],
                }[route.source]
              }
            </Badge>
          </Flex>
        )}

        <Flex align='center'>
          <Text fontWeight='600'>
            {format(getFirstDepartureTime(route.sections), i18.t('format-pattern.time'), { locale: window.datefns_locale })}
          </Text>
          <Icon marginX='0.5rem' as={FiChevronRight} boxSize='1rem' color='gray.700' />
          <Text fontWeight='600'>
            {format(getLastArrivalTime(route.sections), i18.t('format-pattern.time'), { locale: window.datefns_locale })}
            {route.isCrossDay && (
              <Text as='sup' marginLeft='0.25rem'>
                +1
              </Text>
            )}
          </Text>
        </Flex>

        <Text fontSize='xs'>
          {FORMAT_OF_DATE.DURATION(convertSecondsToDuration(route.seconds))} • {getDistanceWithUnit(route.meters)}
        </Text>
      </Stack>

      <Flex margin='calc(0.25rem / 2 * -1)' align='center' flexWrap='wrap'>
        {route.sections.map((section, index, self) => (
          <React.Fragment key={index}>
            <Flex margin='calc(0.25rem / 2)' align='center'>
              {[TRANSPORT_MODE.WAITING, TRANSPORT_MODE.CYCLING, TRANSPORT_MODE.DRIVING, TRANSPORT_MODE.SCOOTER, TRANSPORT_MODE.WALKING]
                .join(',')
                .split(',')
                .includes(section.transport.mode) ? (
                <>
                  <Icon as={getIcon(section)} boxSize='1rem' />

                  {/* display minutes value but no units */}
                  <Flex marginLeft='0.125rem' align='center' fontSize='xs'>
                    {convertSecondsToUnits(section.travelSummary.duration).roundedDividedMinutes}
                  </Flex>

                  {(section.type === metropia.TransportMode.WALKING_STATION || typeof section.transport?.agency === 'object') && (
                    <Flex ml='0.25rem' px='0.25rem' color='white' fontSize='xs' bgColor={getTransitNotation(section).color} borderRadius='md'>
                      {section.type === metropia.TransportMode.WALKING_STATION
                        ? i18.t('common.transport-mode.in-station')
                        : section.type === metropia.TransportMode.SCOOTER
                        ? i18.t('trip-planning.label.rental-scooter')
                        : section.type === metropia.TransportMode.DRIVING
                        ? i18.t('trip-planning.label.rental-car')
                        : null}
                    </Flex>
                  )}
                </>
              ) : (
                <>
                  <Icon as={getIcon(section)} boxSize='1rem' />
                  <Flex
                    ml='0.125rem'
                    px='0.25rem'
                    color='white'
                    fontSize='xs'
                    bgColor={getTransitNotation(section).color}
                    borderRadius='md'
                    whiteSpace='break-spaces'
                  >
                    {section.transport.name}
                  </Flex>
                </>
              )}
            </Flex>

            {index !== self.length - 1 && <Icon margin='calc(0.25rem / 2)' as={FiChevronRight} boxSize='1rem' color='gray.700' />}
          </React.Fragment>
        ))}
      </Flex>
    </Stack>
  )
}

RouteCard.propTypes = {
  route: PropTypes.object.isRequired,
  useGoogle: PropTypes.bool,
}
