import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import {
  Stack,
  Tag,
  Flex,
  Text,
  Icon,
  Button,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react'
import { Icons, toast } from '@metropia/react-tools'
import i18 from 'i18next'
import MdcSeatPassenger from '@meronex/icons/mdc/MdcSeatPassenger'

import * as api from 'src/libs/api'
import { FORMAT_OF_DATE, FORMAT } from 'src/configs'
import { clickAnchor, checkIsIRent, convertSecondsToDuration, getDistanceWithUnit } from 'src/libs/utils'
import { TrafficEvent } from 'src/libs/models'
import { RouteCard } from 'src/components/route-card'
import { Context, TRANSPORT_MODE, TRANSPORT_TEXT_ENTRIES } from 'src/views/trip-planning/constants'
import { getFirstDepartureTime, getLastArrivalTime, getIcon, getInEntries, getTransitNotation } from 'src/views/trip-planning/utils'

const STYLE = {
  // to display circle icon before each action in Accordion component (which has uncontrollable `overflow: hidden` property)
  OFFSET_LEFT_OF_CONTENT: '2.5rem',
  // 0.5rem + half-width-of-route
  OFFSET_LEFT_OF_ROUTE: '0.625rem',
}
const Circle = (props) => (
  <Flex
    zIndex='1'
    position='absolute'
    left={STYLE.OFFSET_LEFT_OF_ROUTE}
    top='50%'
    transform='translate(-50%, -50%)'
    boxSize='0.5rem'
    bgColor='white'
    borderWidth='1px'
    borderColor='currentcolor'
    borderRadius='full'
    {...props}
  />
)
const Line = (props) => (
  <Flex
    zIndex='0'
    position='absolute'
    top='0.75rem'
    bottom='0.75rem'
    left={STYLE.OFFSET_LEFT_OF_ROUTE}
    transform='translateX(-50%)'
    width='0.25rem'
    {...props}
  />
)
const Dots = (props) => (
  <Flex
    zIndex='0'
    position='absolute'
    top='-1.25rem'
    bottom='-1.25rem'
    left={STYLE.OFFSET_LEFT_OF_ROUTE}
    transform='translateX(-50%)'
    width='0.375rem'
    bgImage='radial-gradient(currentColor 33%, transparent 33%)'
    bgPosition='-0.125rem 0'
    bgSize='0.625rem 0.625rem'
    {...props}
  />
)
const TrafficEventAlert = ({ event }) => (
  <Stack spacing='0' p='0.25rem 0.5rem' borderRadius='lg' border='1px dashed red'>
    <Text fontSize='sm' color='red.500'>
      {event.title}
    </Text>
    <Text fontSize='xs' color='gray.700'>
      {event.description}
    </Text>
  </Stack>
)
TrafficEventAlert.propTypes = {
  event: PropTypes.instanceOf(TrafficEvent),
}
const TrafficEventTag = (props) => <Tag ml='0.25rem' colorScheme='red' variant='outline' size='sm' minH='auto' lineHeight='auto' {...props} />

export const RouteDetail = ({ directions, route, option }) => {
  const { env, currentTrafficEvents } = React.useContext(Context)
  const [departure, arrival] = React.useMemo(
    () => [
      { ...directions[0], time: getFirstDepartureTime(route.sections) },
      { ...directions[1], time: getLastArrivalTime(route.sections) },
    ],
    [directions, route.sections],
  )
  const requestDeeplink = React.useCallback(async (transport) => {
    try {
      let link = null
      switch (transport.mode) {
        case window.metropia.TransitMode.TRA: {
          link = (await api.requestTRA({ uuid: transport.uuid })).data
          break
        }
        case window.metropia.TransitMode.THSR: {
          link = (await api.requestTHSR({ uuid: transport.uuid })).data
          break
        }
        case window.metropia.TransportMode.YOXI: {
          link = (await api.requestYOXI({ uuid: transport.uuid })).data
          break
        }
        default:
          break
      }
      clickAnchor(link, [['target', '_blank']])

      if (window.open('') === null) {
        throw new Error(i18.t('error.required-pop-up-and-redirect-setting'))
      }
    } catch (error) {
      toast({ status: 'error', description: error.msg || error.message })
    }
  }, [])

  return (
    <Stack
      spacing='0'
      divider={<Divider borderColor='gray.300' />}
      w='inherit'
      h='100%'
      overflow='hidden' // NOTE: to make content scrollable
    >
      <RouteCard useGoogle={option.useGoogle} p='1rem' borderTopRadius='inherit' route={route} />

      <Stack
        flex='1'
        spacing='1.25rem'
        divider={<Divider marginLeft='auto !important' width={`calc(100% - ${STYLE.OFFSET_LEFT_OF_CONTENT})`} />}
        p='1rem'
        overflow='auto'
      >
        {[departure, ...route.sections, arrival].map((section, index, self) => {
          const hasInstruction = section.actions?.length > 0 && section.actions.some((action) => typeof action.instruction !== 'undefined')
          const hasIntermediateStops = section.intermediateStops?.length > 0
          const icon = getIcon(section)
          const { color } = getTransitNotation(section)
          const duration = FORMAT_OF_DATE.DURATION(convertSecondsToDuration(section.travelSummary?.duration ?? 0))
          const distance = section.travelSummary?.length > 0 ? ` • ${getDistanceWithUnit(section.travelSummary.length)}` : null
          const fare =
            !Number.isNaN(Number(section.transport?.price)) && section.transport?.price > 0
              ? ` • ${FORMAT.CURRENCY(section.transport.price)} (${i18.t('common.estimated-fare')})`
              : null
          const enableDeeplink =
            env.ENABLE_DEEPLINK &&
            [metropia.TransitMode.TRA, metropia.TransitMode.THSR, metropia.TransportMode.YOXI].includes(section.transport?.mode)
          // NOTE: 目前只有 TRA 有 traffic event 資料，未來若有其他資料來源可以改成只使用 env.ENABLE_TRAFFIC_EVENT 做判斷
          const enableTrafficEvent = env.ENABLE_TRAFFIC_EVENT && [metropia.TransitMode.TRA].includes(section.transport?.mode)

          return (
            <Flex key={index} flexShrink='0' position='relative' direction='column'>
              {index === 0 || index === self.length - 1 ? (
                <>
                  {index === 0 ? <Dots color='gray.600' top='0.75rem' /> : <Dots color='gray.600' bottom='auto' height='2rem' />}

                  <Flex position='relative' align='center' paddingLeft={STYLE.OFFSET_LEFT_OF_CONTENT}>
                    <Icon
                      as={index === 0 ? Icons.MapMarkerCircle : Icons.MapMarkerPin}
                      position='absolute'
                      left={STYLE.OFFSET_LEFT_OF_ROUTE}
                      top='50%'
                      transform='translate(-50%, -50%)'
                      color={index === 0 ? 'green.500' : 'orange.500'}
                      boxSize='1.25rem'
                    />
                    <Text fontWeight='600'>{section.name}</Text>
                    <Text flexShrink='0' marginLeft='auto' fontSize='xs'>
                      {format(section.time, i18.t('format-pattern.time'), { locale: window.datefns_locale })}
                    </Text>
                  </Flex>

                  <Text marginTop='0.5rem' fontSize='xs' color='gray.700' pl={STYLE.OFFSET_LEFT_OF_CONTENT}>
                    {section.address}
                  </Text>
                </>
              ) : [TRANSPORT_MODE.WAITING, TRANSPORT_MODE.WALKING, TRANSPORT_MODE.DRIVING, TRANSPORT_MODE.SCOOTER]
                  .join(',')
                  .split(',')
                  .includes(section.type) ? (
                <>
                  <Dots color='gray.600' />

                  <Flex align='center' pl={STYLE.OFFSET_LEFT_OF_CONTENT} flexWrap='wrap'>
                    <Icon mr='0.5rem' as={icon} boxSize='1rem' />

                    {(section.type === metropia.TransportMode.WALKING_STATION || checkIsIRent(section)) && (
                      <Flex mr='0.5rem' px='0.25rem' color='white' fontSize='xs' bgColor={color} borderRadius='md'>
                        {section.type === metropia.TransportMode.WALKING_STATION
                          ? i18.t('common.transport-mode.in-station')
                          : section.type === metropia.TransportMode.SCOOTER
                          ? i18.t('trip-planning.label.rental-scooter')
                          : section.type === metropia.TransportMode.DRIVING
                          ? i18.t('trip-planning.label.rental-car')
                          : null}
                      </Flex>
                    )}

                    <Text>
                      {section.type === metropia.TransportMode.WAITING || checkIsIRent(section)
                        ? section.type === metropia.TransportMode.WAITING
                          ? `${i18.t('common.transport-mode.waiting')} ${duration}`
                          : section.type === metropia.TransportMode.SCOOTER
                          ? section.transport.carNumber
                          : section.type === metropia.TransportMode.DRIVING
                          ? section.transport.station
                          : null
                        : getInEntries(TRANSPORT_TEXT_ENTRIES, section.type)}
                    </Text>

                    {/* NOTE: only `section.type = 'drive'` && `transport.mode = 'YOXI'` will see this button */}
                    {enableDeeplink && (
                      <Button
                        ml='auto'
                        px='0.25rem'
                        h='auto'
                        lineHeight='auto'
                        color='white'
                        fontSize='xs'
                        bgColor={color}
                        borderRadius='md'
                        onClick={() => requestDeeplink(section.transport)}
                        _hover={{}}
                      >
                        {i18.t('common.open-yoxi-app')}
                      </Button>
                    )}
                  </Flex>

                  {/* NOTE: open walking in station and rental (scooter/car) by default */}
                  <Accordion
                    mt='0.5rem'
                    allowToggle
                    defaultIndex={
                      section.type === metropia.TransportMode.WALKING_STATION ||
                      (checkIsIRent(section) && [metropia.TransportMode.SCOOTER, metropia.TransportMode.DRIVING].includes(section.type))
                        ? [0]
                        : []
                    }
                  >
                    <AccordionItem border='none' isDisabled={!hasInstruction && !checkIsIRent(section)}>
                      <AccordionButton
                        p='0'
                        ml='auto'
                        w={`calc(100% - ${STYLE.OFFSET_LEFT_OF_CONTENT})`}
                        _disabled={{ _hover: { cursor: 'default', bgColor: 'transparent' } }}
                      >
                        {(hasInstruction || checkIsIRent(section)) && <AccordionIcon mr='0.5rem' boxSize='1rem' />}
                        {section.type !== metropia.TransportMode.WAITING && (
                          <Text fontSize='xs' color='gray.700'>
                            {duration}
                            {distance}
                          </Text>
                        )}
                      </AccordionButton>
                      <AccordionPanel as={Stack} spacing='0.75rem' pt='1rem' pb='0' px='0'>
                        {section.actions?.filter((action) => action.instruction)?.length > 0 &&
                          section.actions?.map((action, index) => (
                            <Flex key={index} align='flex-start' fontSize='xs' color='gray.700' paddingLeft={STYLE.OFFSET_LEFT_OF_CONTENT}>
                              <Text pr='1rem' dangerouslySetInnerHTML={{ __html: action.instruction }} />
                            </Flex>
                          ))}

                        {checkIsIRent(section) && (
                          <Stack fontSize='xs' color='gray.700' paddingLeft={STYLE.OFFSET_LEFT_OF_CONTENT}>
                            <Flex direction='row' align='center' lineHeight='1' flexWrap='wrap'>
                              <Stack direction='row' align='center' spacing='0' px='0.25rem' py='0.125rem' bgColor='gray.200' borderRadius='sm'>
                                <Icon as={MdcSeatPassenger} boxSize='0.75rem' />
                                <Text>{section.transport.seat}</Text>
                              </Stack>

                              <Text ml='0.25rem'>{section.transport.carTypeName}</Text>
                              <Text m='0.125rem' ml='0.25rem' px='0.25rem' py='0.125rem' color='black' bgColor='yellow.300' borderRadius='sm'>
                                {section.transport.projectName ?? section.transport.address}
                              </Text>
                            </Flex>

                            {section.transport?.content?.length > 0 && <Text whiteSpace='pre-line'>{section.transport.content}</Text>}
                          </Stack>
                        )}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </>
              ) : (
                <>
                  <Dots color='gray.600' bottom='auto' height='2rem' />
                  <Dots color='gray.600' top='auto' height='2rem' />
                  <Line bgColor={color} />

                  <Flex position='relative' align='center' paddingLeft={STYLE.OFFSET_LEFT_OF_CONTENT}>
                    <Circle />
                    <Text fontWeight='600'>
                      {TRANSPORT_MODE.CYCLING.split(',').includes(section.type) && index === 1 ? self[index - 1].name : section.departure.place.name}
                    </Text>

                    {enableTrafficEvent &&
                      currentTrafficEvents.map((trafficEvent, index) =>
                        trafficEvent.scope.stations.some((station) => section.departure.place.stopId === station.stopId) ? (
                          <TrafficEventTag key={index}>{trafficEvent.reason}</TrafficEventTag>
                        ) : null,
                      )}

                    <Text flexShrink='0' marginLeft='auto' fontSize='xs'>
                      {format(new Date(section.departure.time), i18.t('format-pattern.time'), { locale: window.datefns_locale })}
                    </Text>
                  </Flex>

                  <Flex mt='0.5rem' align='center' flexWrap='wrap' pl={STYLE.OFFSET_LEFT_OF_CONTENT}>
                    <Icon mr='0.5rem' as={icon} flexShrink='0' boxSize='1rem' />

                    {section.transport.name?.length > 0 && (
                      <Flex mr='0.5rem' px='0.25rem' maxWidth='10rem' color='white' fontSize='xs' bgColor={color} borderRadius='md' isTruncated>
                        {section.transport.name}
                      </Flex>
                    )}

                    <Text>
                      {section.transport.headsign?.length > 0 ? section.transport.headsign : getInEntries(TRANSPORT_TEXT_ENTRIES, section.type)}
                    </Text>

                    {/* NOTE: don't display deeplink button when TRA type is '區間' */}
                    {enableDeeplink && !section.transport.name?.includes('區間') && (
                      <Button
                        ml='auto'
                        px='0.25rem'
                        h='auto'
                        lineHeight='auto'
                        color='white'
                        fontSize='xs'
                        bgColor={color}
                        borderRadius='md'
                        onClick={() => requestDeeplink(section.transport)}
                        _hover={{}}
                      >
                        {section.transport.mode === metropia.TransitMode.THSR
                          ? i18.t('common.buy-in-app-thsr')
                          : section.transport.mode === metropia.TransitMode.TRA
                          ? i18.t('common.buy-in-app-tra')
                          : null}
                      </Button>
                    )}

                    <Stack mt='0.25rem' spacing='0.25rem' w='100%' _empty={{ display: 'none' }}>
                      {Boolean(Number(section.agency?.reserve)) && (
                        <Stack spacing='0' p='0.25rem 0.5rem' borderRadius='lg' border='1px dashed red'>
                          <Text fontSize='sm' color='gray.900'>
                            {i18.t('common.agency-phone-title')} {section.agency?.phone}
                          </Text>
                          <Text fontSize='xs' color='gray.700'>
                            {i18.t('common.agency-phone-description')}
                          </Text>
                        </Stack>
                      )}

                      {enableTrafficEvent &&
                        currentTrafficEvents.map((trafficEvent, index) =>
                          trafficEvent.scope.stations.some((station) =>
                            section.intermediateStops
                              .map(({ departure }) => departure)
                              .concat([section.departure])
                              .some((stop) => stop.place.stopId === station.stopId),
                          ) ? (
                            <TrafficEventAlert key={index} event={trafficEvent} />
                          ) : null,
                        )}
                    </Stack>
                  </Flex>

                  <Accordion mt='0.5rem' allowToggle>
                    <AccordionItem border='none' isDisabled={!hasIntermediateStops}>
                      <AccordionButton
                        p='0'
                        ml='auto'
                        w={`calc(100% - ${STYLE.OFFSET_LEFT_OF_CONTENT})`}
                        _disabled={{ _hover: { cursor: 'default', bgColor: 'transparent' } }}
                      >
                        {hasIntermediateStops && <AccordionIcon mr='0.5rem' boxSize='1rem' />}
                        <Text letterSpacing='-0.25px' textAlign='left' fontSize='xs' color='gray.700'>
                          {duration}
                          {hasIntermediateStops ? ` (${section.intermediateStops.length} stops)` : null}
                          {distance}
                          {fare}
                        </Text>
                      </AccordionButton>
                      <AccordionPanel as={Stack} spacing='0.75rem' paddingTop='1rem' paddingBottom='0' paddingX='0'>
                        {section.intermediateStops?.map((stop, index) => (
                          <Flex key={index} position='relative' fontSize='xs' color='gray.700' paddingLeft={STYLE.OFFSET_LEFT_OF_CONTENT}>
                            <Circle color={color} />
                            <Text pr='0.5rem'>
                              {stop.departure.place.name}

                              {enableTrafficEvent &&
                                currentTrafficEvents.flatMap((trafficEvent) =>
                                  trafficEvent.scope.stations.map((station) =>
                                    stop.departure.place.stopId === station.stopId ? (
                                      <TrafficEventTag key={station.stopId}>{trafficEvent.reason}</TrafficEventTag>
                                    ) : null,
                                  ),
                                )}
                            </Text>
                            <Text flexShrink='0' marginLeft='auto'>
                              {format(new Date(stop.departure.time), i18.t('format-pattern.time'), { locale: window.datefns_locale })}
                            </Text>
                          </Flex>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>

                  <Divider marginY='1.25rem' marginLeft='auto' width={`calc(100% - ${STYLE.OFFSET_LEFT_OF_CONTENT})`} />

                  <Flex position='relative' align='center' paddingLeft={STYLE.OFFSET_LEFT_OF_CONTENT}>
                    <Circle />
                    <Text fontWeight='600'>
                      {TRANSPORT_MODE.CYCLING.split(',').includes(section.type) && index === self.length - 2
                        ? self[index + 1].name
                        : section.arrival.place.name}
                    </Text>

                    {enableTrafficEvent &&
                      currentTrafficEvents.map((trafficEvent, index) =>
                        trafficEvent.scope.stations.some((station) => section.arrival.place.stopId === station.stopId) ? (
                          <TrafficEventTag key={index}>{trafficEvent.reason}</TrafficEventTag>
                        ) : null,
                      )}

                    <Text flexShrink='0' marginLeft='auto' fontSize='xs'>
                      {format(new Date(section.arrival.time), i18.t('format-pattern.time'), { locale: window.datefns_locale })}
                    </Text>
                  </Flex>

                  <Flex mt='0.5rem' align='center' flexWrap='wrap' pl={STYLE.OFFSET_LEFT_OF_CONTENT}>
                    {enableTrafficEvent &&
                      currentTrafficEvents.map((trafficEvent, index) =>
                        trafficEvent.scope.stations.some((station) => section.arrival.place.stopId === station.stopId) ? (
                          <TrafficEventAlert key={index} event={trafficEvent} />
                        ) : null,
                      )}
                  </Flex>
                </>
              )}
            </Flex>
          )
        })}
      </Stack>
    </Stack>
  )
}

RouteDetail.propTypes = {
  directions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, address: PropTypes.string })),
  route: PropTypes.object,
  option: PropTypes.object,
}
