import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import { LANG } from 'src/configs'
import zhTW from 'date-fns/locale/zh-TW'
import enUS from 'date-fns/locale/en-US'

i18next
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      backend: { loadPath: '/locales/{{lng}}.json' },
      load: 'currentOnly',
      supportedLngs: Object.values(LANG),
      fallbackLng: LANG.zhTW,
      debug: process.env.NODE_ENV === 'development',
      interpolation: { escapeValue: false }, // not needed for react as it escapes by default
    },
    () => {
      switch (i18next.language) {
        case LANG.enUS:
          window.datefns_locale = enUS
          break

        case LANG.zhTW:
        default:
          window.datefns_locale = zhTW
          break
      }
    },
  )
