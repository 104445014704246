import React from 'react'
import PropTypes from 'prop-types'
import useSWRInfinite from 'swr/infinite'
import { SWRInfiniteHelper, useDebouncedValue } from '@metropia/react-tools'

export const Query = ({ queryWhenEmpty, isScrollingEnd, keyword, fetcher, debouncedWait, fallbackData, children }) => {
  const debouncedValue = useDebouncedValue(keyword, debouncedWait)
  const {
    data: pagedData,
    size,
    setSize,
    error,
    isValidating,
  } = useSWRInfinite(
    (index) => (queryWhenEmpty ? [index, debouncedValue] : debouncedValue.length > 0 ? [index, debouncedValue, 'query'] : null),
    fetcher,
    { fallbackData },
  )
  const isEmpty = React.useMemo(() => SWRInfiniteHelper.checkIsEmpty(pagedData), [pagedData])
  const isReachingEnd = React.useMemo(() => SWRInfiniteHelper.checkIsReachingEnd(pagedData), [pagedData])
  const isRefreshing = React.useMemo(() => isValidating && pagedData?.length === size, [isValidating, pagedData, size])

  React.useEffect(() => {
    if (isScrollingEnd && !isReachingEnd && !isRefreshing) {
      setSize((size) => size + 1)
    }
  }, [isScrollingEnd, isReachingEnd, isRefreshing, setSize])

  return children({
    error,
    isValidating,
    isEmpty,
    isReachingEnd,
    isRefreshing,
    size,
    setSize,
    items: pagedData.flatMap(({ data }) => data),
  })
}

Query.propTypes = {
  queryWhenEmpty: PropTypes.bool,
  isScrollingEnd: PropTypes.bool,
  keyword: PropTypes.string,
  fetcher: PropTypes.func.isRequired,
  debouncedWait: PropTypes.number.isRequired,
  fallbackData: PropTypes.any,
  children: PropTypes.func.isRequired,
}

Query.defaultProps = {
  queryWhenEmpty: false,
  isScrollingEnd: false,
  keyword: '',
  debouncedWait: 400,
  fallbackData: [],
}
