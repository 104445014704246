// https://next.chakra-ui.com/docs/theming/customize-theme
// https://github.com/chakra-ui/chakra-ui/tree/master/packages/theme/src
import { extendTheme } from '@chakra-ui/react'
import { theme } from '@metropia/react-tools'
import * as components from './components'
import * as foundations from './foundations'

export const THEME = extendTheme(theme, {
  // NOTE: es module will break theme, need spreading to plan object
  // Component style overrides
  components: { ...components },

  // Foundational style overrides
  ...foundations,
})
