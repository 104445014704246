import React from 'react'
import PropTypes from 'prop-types'
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp'
import { Flex, Stack, IconButton, Icon, Divider } from '@chakra-ui/react'
import BiTargetLock from '@meronex/icons/bi/BiTargetLock'
import FiPlus from '@meronex/icons/fi/FiPlus'
import FiMinus from '@meronex/icons/fi/FiMinus'

const ZOOM = {
  SPEED: 1,
  // NOTE: even there is `H.util.constants.DEFAULT_{MIN/MAX}_ZOOM_LEVEL`, but the min level is 0, max level is 22.
  // They aren't real value, actually the map can't be zoomed in or out when level is 2 (min) and 19 (max).
  MAX_LEVEL: 19,
  MIN_LEVEL: 2,
}

const Control = React.forwardRef(({ icon, ...rest }, ref) => (
  <IconButton
    ref={ref}
    icon={<Icon as={icon} boxSize='1rem' />}
    variant='ghost'
    boxSize='2.5rem'
    borderRadius='inherit'
    _notFirst={{ borderTopRadius: 'none', borderBottomRadius: 'inherit' }}
    _notLast={{ borderTopRadius: 'inherit', borderBottomRadius: 'none' }}
    {...rest}
  />
))

Control.propTypes = {
  icon: PropTypes.elementType,
}

export const MapControl = React.forwardRef(({ map, mapMeta, isTargetDisabled, onTarget, ...rest }, ref) => {
  const onZoomIn = React.useCallback(() => map.setZoom(map.getZoom() + ZOOM.SPEED, true), [map])
  const onZoomOut = React.useCallback(() => map.setZoom(map.getZoom() - ZOOM.SPEED, true), [map])

  return (
    <Stack ref={ref} {...rest}>
      <Flex bgColor='white' borderRadius='lg' shadow='md'>
        {/* use aria-disabled for disabled style and keep onClick event working for toast */}
        <Control icon={BiTargetLock} aria-disabled={isTargetDisabled} onClick={onTarget} />
      </Flex>

      <Stack spacing='0' divider={<Divider />} bgColor='white' borderRadius='lg' shadow='md'>
        <Control icon={FiPlus} isDisabled={mapMeta.zoom === ZOOM.MAX_LEVEL} onClick={onZoomIn} />
        <Control icon={FiMinus} isDisabled={mapMeta.zoom === ZOOM.MIN_LEVEL} onClick={onZoomOut} />
      </Stack>
    </Stack>
  )
})

MapControl.propTypes = {
  map: PropTypes.instanceOf(H.Map),
  mapMeta: PropTypes.shape({ zoom: PropTypes.number }).isRequired,
  isTargetDisabled: PropTypes.bool,
  onTarget: PropTypes.func,
}
