import { tripService, deeplinkService } from 'src/libs/api/_service'

export const requestCarpool = ({ stationId, plateNumber } = {}) => {
  return tripService({
    method: 'GET',
    url: '/Carpooling',
    params: {
      station_uid: stationId,
      plate_number: plateNumber,
    },
  }).then((response) => ({ ...response, data: response.data.ShortLinks }))
}

export const requestTRA = ({ uuid } = {}) => {
  return deeplinkService({
    method: 'GET',
    url: '/booking/deeplink/url/tra',
    params: { uuid },
  }).then((response) => ({ ...response, data: response.data.data.deeplink }))
}

export const requestTHSR = ({ uuid } = {}) => {
  return deeplinkService({
    method: 'GET',
    url: '/booking/deeplink/url/hsr',
    params: { uuid },
  }).then((response) => ({ ...response, data: response.data.data.deeplink }))
}

export const requestYOXI = ({ uuid } = {}) => {
  return deeplinkService({
    method: 'GET',
    url: '/booking/deeplink/url/yoxi',
    params: { uuid },
  }).then((response) => ({ ...response, data: response.data.data.deeplink }))
}
