import i18 from 'i18next'

// DOC: https://developers.google.com/maps/documentation/javascript/reference/directions#VehicleType
// google has `VehicleType` constants in responses but there are not constants in global variables
// but patch them will cause error with `window.google is undefined`, so use them by a new constant
const VehicleType = {
  BUS: 'BUS',
  CABLE_CAR: 'CABLE_CAR',
  COMMUTER_TRAIN: 'COMMUTER_TRAIN',
  FERRY: 'FERRY',
  FUNICULAR: 'FUNICULAR',
  GONDOLA_LIFT: 'GONDOLA_LIFT',
  HEAVY_RAIL: 'HEAVY_RAIL',
  HIGH_SPEED_TRAIN: 'HIGH_SPEED_TRAIN',
  INTERCITY_BUS: 'INTERCITY_BUS',
  METRO_RAIL: 'METRO_RAIL',
  MONORAIL: 'MONORAIL',
  OTHER: 'OTHER',
  RAIL: 'RAIL',
  SHARE_TAXI: 'SHARE_TAXI',
  SUBWAY: 'SUBWAY',
  TRAM: 'TRAM',
  TROLLEYBUS: 'TROLLEYBUS',
}

// TRANSIT: to render route icons in route-card and route-detail
export const TRANSIT_MODE = {
  WALKING: [google.maps.TravelMode.WALKING, metropia.TransportMode.WALKING].join(','),
  BUS: [google.maps.TransitMode.BUS, metropia.TransitMode.BUS, metropia.TransitMode.HIGHWAY_BUS].join(','),
  SUBWAY: [google.maps.TransitMode.SUBWAY, metropia.TransitMode.SUBWAY].join(','),
  TRAIN: [google.maps.TransitMode.TRAIN, metropia.TransitMode.TRA].join(','),
  RAIL: [
    google.maps.TransitMode.RAIL,
    VehicleType.HEAVY_RAIL,
    VehicleType.HIGH_SPEED_TRAIN,
    metropia.TransitMode.THSR,
    metropia.TransitMode.RAIL,
  ].join(','),
  TRAM: [google.maps.TransitMode.TRAM],
  AIRPLANE: [metropia.TransitMode.AIRPLANE],
}
export const TRANSIT_MODE_TO_GOOGLE_API = {
  [metropia.PreferredTransit.BUS]: google.maps.TransitMode.BUS,
  [metropia.PreferredTransit.SUBWAY]: google.maps.TransitMode.SUBWAY,
  [metropia.PreferredTransit.THSR]: google.maps.TransitMode.TRAIN,
  [metropia.PreferredTransit.TRA]: google.maps.TransitMode.TRAIN,
  [metropia.PreferredTransit.FERRY]: null,
  [metropia.PreferredTransit.METRO_RAIL]: null,
  [metropia.PreferredTransit.AIRPLANE]: null,
}

// https://docs.google.com/spreadsheets/d/1s4wAw7W3f-dcAVdCue-v0v0jwakVz8ya8MOUrVaMfSo/edit#gid=1665928402
export const TRANSIT_MODE_TO_METROPIA_API = {
  [metropia.PreferredTransit.BUS]: 5,
  [metropia.PreferredTransit.SUBWAY]: [6, 7, 9],
  [metropia.PreferredTransit.THSR]: 3,
  [metropia.PreferredTransit.TRA]: 4,
  [metropia.PreferredTransit.FERRY]: 8,
  [metropia.PreferredTransit.METRO_RAIL]: 10,
  [metropia.PreferredTransit.AIRPLANE]: 20,
}

// TRANSPORT: to render route icons in route-card and route-detail
export const TRANSPORT_MODE = {
  WAITING: [metropia.TransportMode.WAITING].join(','),
  TRANSIT: [google.maps.TravelMode.TRANSIT, metropia.TransportMode.TRANSIT].join(','),
  WALKING: [google.maps.TravelMode.WALKING, metropia.TransportMode.WALKING, metropia.TransportMode.WALKING_STATION].join(','),
  CYCLING: [google.maps.TravelMode.BICYCLING, metropia.TransportMode.CYCLING].join(','),
  DRIVING: [google.maps.TravelMode.DRIVING, metropia.TransportMode.DRIVING, metropia.TransportMode.YOXI].join(','),
  SCOOTER: [metropia.TransportMode.SCOOTER].join(','),
}

export const TRANSPORT_TEXT = {}
export let TRANSPORT_TEXT_ENTRIES = []
export const FIRST_AND_LAST_MILE_MODE_TEXT = {}
export const LEAVE_TYPE_TEXT = {}
export const PREFERRED_TRANSIT_TEXT = {}
export const BIKE_TYPE_TEXT = {}
i18.on('initialized', () => {
  TRANSPORT_TEXT[TRANSPORT_MODE.WALKING] = i18.t('common.transport-mode.walking')
  TRANSPORT_TEXT[TRANSPORT_MODE.CYCLING] = i18.t('common.transport-mode.cycling')
  TRANSPORT_TEXT[TRANSPORT_MODE.DRIVING] = i18.t('common.transport-mode.driving')
  TRANSPORT_TEXT_ENTRIES = Object.entries(TRANSPORT_TEXT)

  FIRST_AND_LAST_MILE_MODE_TEXT[metropia.FirstAndLastMileMode.WALKING] = i18.t('common.transport-mode.walking')
  FIRST_AND_LAST_MILE_MODE_TEXT[metropia.FirstAndLastMileMode.CYCLING] = i18.t('common.transport-mode.cycling')
  FIRST_AND_LAST_MILE_MODE_TEXT[metropia.FirstAndLastMileMode.DRIVING] = i18.t('common.transport-mode.driving')

  LEAVE_TYPE_TEXT[metropia.LeaveType.LEAVE_NOW] = i18.t('trip-planning.label.date-time-preference.leave-now')
  LEAVE_TYPE_TEXT[metropia.LeaveType.DEPART_AT] = i18.t('trip-planning.label.date-time-preference.depart-at')
  LEAVE_TYPE_TEXT[metropia.LeaveType.ARRIVE_AT] = i18.t('trip-planning.label.date-time-preference.arrive-at')

  PREFERRED_TRANSIT_TEXT[metropia.PreferredTransit.BUS] = i18.t('common.transit-mode.bus')
  PREFERRED_TRANSIT_TEXT[metropia.PreferredTransit.SUBWAY] = i18.t('common.transit-mode.subway')
  PREFERRED_TRANSIT_TEXT[metropia.PreferredTransit.TRA] = i18.t('common.transit-mode.tra')
  PREFERRED_TRANSIT_TEXT[metropia.PreferredTransit.THSR] = i18.t('common.transit-mode.thsr')
  PREFERRED_TRANSIT_TEXT[metropia.PreferredTransit.FERRY] = i18.t('common.transit-mode.ferry')
  PREFERRED_TRANSIT_TEXT[metropia.PreferredTransit.METRO_RAIL] = i18.t('common.transit-mode.metro-rail')
  PREFERRED_TRANSIT_TEXT[metropia.PreferredTransit.AIRPLANE] = i18.t('common.transit-mode.airplane')

  BIKE_TYPE_TEXT[metropia.BikeType.OWN_BIKE] = i18.t('trip-planning.label.own-bike')
  BIKE_TYPE_TEXT[metropia.BikeType.SHARED_BIKE] = i18.t('trip-planning.label.shared-bike')
})
