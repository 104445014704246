import React from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuButton, MenuList, MenuItem, Portal, Button, useDisclosure, useControllableState } from '@chakra-ui/react'

export const Selector = ({ options, defaultValue, value: valueProp, onChange, isDisabled }) => {
  const [value, setValue] = useControllableState({ defaultValue: defaultValue ?? options[0].value, value: valueProp, onChange })
  const selectedOption = React.useMemo(() => options.find((option) => option.value === value), [options, value])
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Menu placement='bottom-start' isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <MenuButton
        as={Button}
        display='inline-flex'
        variant='unstyled'
        color='brand.500'
        fontSize='sm'
        fontWeight='600'
        height='auto'
        isDisabled={isDisabled}
      >
        {selectedOption.label}
      </MenuButton>

      <Portal>
        <MenuList padding='0.5rem' minWidth='9rem'>
          {options.map((option, index) => (
            <MenuItem key={index} padding='0.5rem 1rem' fontSize='sm' borderRadius='lg' onClick={() => setValue(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  )
}

Selector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.node, value: PropTypes.any })),
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
}
