import i18 from 'i18next'

export const SORT_OPTION_TEXT = {}
export let SORT_OPTIONS = []
i18.on('initialized', () => {
  SORT_OPTION_TEXT[metropia.Sort.EARLIEST_DEPARTURE] = i18.t('trip-planning.label.sort-preference.earliest-departure')
  SORT_OPTION_TEXT[metropia.Sort.EARLIEST_ARRIVAL] = i18.t('trip-planning.label.sort-preference.earliest-arrival')
  SORT_OPTION_TEXT[metropia.Sort.SHORTEST_TIME] = i18.t('trip-planning.label.sort-preference.shortest-time')
  SORT_OPTION_TEXT[metropia.Sort.LOWEST_PRICE] = i18.t('trip-planning.label.sort-preference.lowest-price')
  SORT_OPTION_TEXT[metropia.Sort.FEWEST_WALKING] = i18.t('trip-planning.label.sort-preference.fewest-walking')
  SORT_OPTION_TEXT[metropia.Sort.FEWEST_TRANSFER] = i18.t('trip-planning.label.sort-preference.fewest-transfer')
  SORT_OPTIONS = Object.entries(SORT_OPTION_TEXT).map(([value, label]) => ({ label, value }))
})

export const INITIAL_SORT = {
  first: metropia.Sort.SHORTEST_TIME,
  then: metropia.Sort.FEWEST_TRANSFER,
}
