import { routingService, tripService, directionsService } from 'src/libs/api/_service'
import { dateHelper } from '@metropia/react-tools'
import BigNumber from 'bignumber.js'
import { parse, getTime, getDate } from 'date-fns'
import { ROUTING_SOURCE } from 'src/configs'
import { getEnv, checkIsIRent } from 'src/libs/utils'

export const fetchWaypoints = ({ origin, destination, comfort } = {}) => {
  return routingService({
    method: 'GET',
    url: `/path`,
    params: {
      o: origin,
      d: destination,
      pc: comfort,
    },
  }).then((response) => ({ ...response, data: response.data.waypoints }))
}

export const fetchTripServiceVersion = () => {
  return tripService({
    method: 'GET',
    url: `/version`,
  }).then((response) => ({ ...response, data: { engine: response.data.EngineVersion, api: response.data.ApiVersion } }))
}

export const fetchDirectionRoutesByGoogle = ({ origin, destination, transitOptions }) => {
  return new Promise((resolve, reject) => {
    directionsService().route(
      // google.maps.TransitMode BUS / RAIL / SUBWAY / TRAIN / TRAM
      { origin, destination, travelMode: google.maps.TravelMode.TRANSIT, transitOptions },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK || status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
          resolve(results)
        } else {
          reject(status)
        }
      },
    )
  }).then((response) => ({
    // mapping keys to metropia and component usage
    data: response.routes.map((route) => {
      const [summary] = route.legs

      return {
        sections: summary.steps.reduce((acc, section, index) => {
          const departure = (index === 0 ? summary?.departure_time?.value : acc[acc.length - 1]?.arrival?.time) ?? 0
          const arrival =
            (index === summary.length - 1 ? summary?.arrival_time?.value : new Date(getTime(departure) + section.duration.value * 1000)) ?? 0

          return [
            ...acc,
            {
              type: section.travel_mode,
              polyline: section.polyline.points,
              travelSummary: {
                duration: section.duration.value, // seconds
                length: section.distance.value, // meters
              },
              departure: {
                time: section.transit ? section.transit.departure_time.value : departure,
                place: {
                  name: section.transit ? section.transit.departure_stop.name : null,
                  location: { lat: section.start_location.lat(), lng: section.start_location.lng() },
                },
              },
              arrival: {
                time: section.transit ? section.transit.arrival_time.value : arrival,
                place: {
                  name: section.transit ? section.transit.arrival_stop.name : null,
                  location: { lat: section.end_location.lat(), lng: section.end_location.lng() },
                },
              },
              transport: Object.assign(
                { mode: section.travel_mode },
                section.transit
                  ? {
                      mode: section.transit.line.vehicle.type,
                      name: section.transit.trip_short_name ?? section.transit.line.short_name ?? section.transit.line.name,
                      headsign: section.transit.headsign,
                      ...(section.transit.line?.color ? { color: section.transit.line?.color } : null),
                    }
                  : null,
              ),
              // only for walking
              actions:
                section.steps?.map((step) => ({ instruction: step.instructions ?? section.instructions, duration: step.duration.value })) ?? [],
              // transit type in google direction api should return steps property, but they don't
              // https://developers.google.com/maps/documentation/javascript/reference/directions#DirectionsStep.steps
              intermediateStops: [],
            },
          ]
        }, []),
        isCrossDay:
          summary.arrival_time?.value && summary.departure_time?.value && getDate(summary.arrival_time.value) > getDate(summary.departure_time.value),
        source: ROUTING_SOURCE.GOOGLE,
        seconds: summary.duration.value,
        meters: summary.distance.value,
        transferTimes: null,
        fare: route.fare?.value ?? null,
      }
    }),
  }))
}

const mapRentalTransport = (section) => {
  if (!checkIsIRent(section)) return section.transport

  switch (section.transport.mode) {
    case metropia.TransportMode.SCOOTER:
      return {
        projectName: section.transport.ProjectName,
        carNumber: section.transport.CarNo,
        carTypeName: section.transport.CarTypeName,
        seat: section.transport.Seat,
        rental: section.transport.Rental, // NOTE: not sure meaning
      }
    case metropia.TransportMode.DRIVING:
      return {
        city: section.transport.CityName,
        area: section.transport.AreaName,
        station: section.transport.StationName,
        address: section.transport.Addr,
        price: section.transport.Price, // NOTE: not sure meaning
        priceH: section.transport.Price_H, // NOTE: not sure meaning
        priceBill: section.transport.PriceBill, // NOTE: not sure meaning
        milage: section.transport.MilageBase,
        carTypeName: section.transport.CarTypeName,
        seat: section.transport.Seat,
        insurancePerHours: section.transport.InsurancePerHours,
        content: section.transport.ContentForAPP,
        projectDescription: section.transport.ProjDesc,
      }
    default:
      return section.transport
  }
}

const DAILY_SECONDS = 60 * 60 * 24
// DOC: https://dev-trip-api.connectsmartx.com/docs/swagger.html
export const fetchDirectionRoutesByMetropia = ({
  // NOTE: for cost down api
  isBackground = false,

  // NOTE: for tracing
  vendor,
  groupId,

  // NOTE: for no transit and using walking routes
  distance,
  duration,

  origin,
  destination,
  originName,
  originAddress,
  destinationName,
  destinationAddress,
  weekday,
  departureDate,
  departureTime,
  arrivalTime,

  transitModes,
  minTransferSeconds,
  maxTransferSeconds,
  timeCostPreference,
  firstMileBikeTypes,
  firstMileModes,
  firstMileMaxMinutes,
  useFirstMileRentalScooter,
  lastMileBikeTypes,
  lastMileModes,
  lastMileMaxMinutes,
  useLastMileRentalCar,

  timeCostOfNormalTravel,
  timeCostOfBusinessTravel,
  timeCostOfLastMile,
  transitOfTimeCost,
  transitOfFare,
  speedOfWalking,
  speedOfCycling,
  speedOfDriving,
  labeling,
  top,
  dimension,
  unitFare,
}) => {
  const env = getEnv()

  const referenceDate = departureTime ?? arrivalTime
  const paramsOfNormal = {
    background: typeof isBackground === 'boolean' ? Number(isBackground) : 0,
    vendor,
    groupId,
    distance,
    duration,

    origin,
    origin_name: originName,
    origin_address: originAddress,
    destination,
    destination_name: destinationName,
    destination_address: destinationAddress,
    day: weekday,
    date: dateHelper.formatDate(departureDate),
    depart: dateHelper.formatTime(departureTime, { pattern: 'HH:mm' }),
    arrival: dateHelper.formatTime(arrivalTime, { pattern: 'HH:mm' }),

    transit: transitModes.join(','),
    transfer_time_lb: minTransferSeconds,
    transfer_time_ub: maxTransferSeconds,
    // larger value means cost more time in UI, but larger value means cost less time in API
    gc: new BigNumber(1).minus(timeCostPreference).toNumber(),
    // NOTE: taiwan use radio and use the value directly; houston use checkbox and use array value
    firstmile_sharebike: firstMileModes.includes(window.metropia.FirstAndLastMileMode.CYCLING)
      ? Array.isArray(firstMileBikeTypes)
        ? firstMileBikeTypes.join(',')
        : firstMileBikeTypes
      : window.metropia.BikeType.OWN_BIKE,
    first_mile_mode: firstMileModes.slice().sort().join(','),
    first_mile_time: firstMileModes
      .slice()
      .sort()
      .map((mode) => firstMileMaxMinutes[mode])
      .join(','),
    firstmile_scooter: typeof useFirstMileRentalScooter === 'boolean' ? Number(useFirstMileRentalScooter) : 0,
    // NOTE: taiwan use radio and use the value directly; houston use checkbox and use array value
    lastmile_sharebike: lastMileModes.includes(window.metropia.FirstAndLastMileMode.CYCLING)
      ? Array.isArray(lastMileBikeTypes)
        ? lastMileBikeTypes.join(',')
        : lastMileBikeTypes
      : window.metropia.BikeType.OWN_BIKE,
    last_mile_mode: lastMileModes.slice().sort().join(','),
    last_mile_time: lastMileModes
      .slice()
      .sort()
      .map((mode) => lastMileMaxMinutes[mode])
      .join(','),
    lastmile_rentalcar: typeof useLastMileRentalCar === 'boolean' ? Number(useLastMileRentalCar) : 0,
    top, // required
    dimension, // required
  }

  const paramsOfAdvanced = {
    vot: [timeCostOfNormalTravel, timeCostOfBusinessTravel, transitOfTimeCost, transitOfFare, timeCostOfLastMile].map((n) => n.toFixed(1)).join(','),
    speed: [speedOfWalking, speedOfCycling, speedOfDriving].map((n) => n.toFixed(1)).join(','),
    label_setting_times: labeling,
    unit_fare: [unitFare.thsr, unitFare.tra, unitFare.bus, unitFare.mrt, unitFare.lrt, unitFare.ferry, unitFare.gondola, unitFare.airplane]
      .map((n) => n.toFixed(3))
      .join(','),
  }

  return tripService({
    method: 'GET',
    url: `/routes`,
    params: env.VISIBLE_OPTION.ADVANCED ? Object.assign(paramsOfNormal, paramsOfAdvanced) : paramsOfNormal,
  }).then((response) => ({
    ...response,
    data:
      response.data.routes?.map((route) => ({
        sections: route.sections.map((section) => ({
          type: section.type,
          polyline: section.polyline,
          travelSummary: section.travelSummary,
          departure: {
            time: parse(section.departure.time, 'HH:mm:ss', referenceDate),
            place: {
              name: section.departure.place.name ?? null,
              stopId: section.departure.place.stop_id ?? null,
              location: section.departure.place.location,
            },
          },
          arrival: {
            time: parse(section.arrival.time, 'HH:mm:ss', referenceDate),
            place: {
              name: section.arrival.place.name ?? null,
              stopId: section.arrival.place.stop_id ?? null,
              location: section.arrival.place.location,
            },
          },
          transport: {
            ...section.transport,
            ...mapRentalTransport(section),
            color: section.transport?.color ?? (section.transport?.route_color ? `#${section.transport?.route_color}` : null),
          },
          // only for walking
          actions: section.actions ?? [],
          // only for transit
          intermediateStops:
            section.intermediateStops?.map((stop) => ({
              departure: {
                time: parse(stop.departure.time, 'HH:mm:ss', referenceDate),
                place: {
                  name: stop.departure.place.name ?? null,
                  stopId: stop.departure.place.stop_id ?? null,
                  location: stop.departure.place.location,
                },
              },
            })) ?? [],
          agency: section.agency,
        })),
        isCrossDay: route.end_time > DAILY_SECONDS,
        source: ROUTING_SOURCE.METROPIA,
        seconds: route.total_travel_time,
        meters: route.total_travel_meters,
        transferTimes: route.transfers,
        fare: route.total_price,
      })) ?? [],
  }))
}
