import BigNumber from 'bignumber.js'
import i18 from 'i18next'
import { LANG } from './lang'

const currency = { [LANG.enUS]: 'USD', [LANG.zhTW]: 'TWD' }
const minimumFractionDigits = { [LANG.enUS]: 2, [LANG.zhTW]: 0 }
let intl = new Intl.NumberFormat('zh-TW', {
  style: 'currency',
  currency: currency[LANG.zhTW],
  minimumFractionDigits: minimumFractionDigits[LANG.zhTW],
})
i18.on('initialized', () => {
  intl = new Intl.NumberFormat(i18.language, {
    style: 'currency',
    currency: currency[i18.language],
    minimumFractionDigits: minimumFractionDigits[i18.language],
  })
})

const format = {
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
}
export const FORMAT = {
  GROUP: (value, decimalPlace = 0, options = null) => new BigNumber(value).toFormat(decimalPlace, { ...format, ...options }),
  CURRENCY: (value) => intl.format(value),
  FEET: (value, decimalPlace = 0) => FORMAT.GROUP(value, decimalPlace, { suffix: ` ${i18.t('common.unit.feet-short')}` }),
  MILE: (value, decimalPlace = 1) => FORMAT.GROUP(value, decimalPlace, { suffix: ` ${i18.t('common.unit.mile-short')}` }),
  METER: (value, decimalPlace = 0) => FORMAT.GROUP(value, decimalPlace, { suffix: ` ${i18.t('common.unit.meter-short')}` }),
  KILOMETER: (value, decimalPlace = 1) => FORMAT.GROUP(value, decimalPlace, { suffix: ` ${i18.t('common.unit.kilometer-short')}` }),
}

export const FORMAT_OF_DATE = {
  DURATION: (duration) =>
    Object.entries(duration)
      .map(([key, value]) => (typeof value === 'number' ? `${value} ${key}` : ''))
      .join(' ')
      .trim(),
}
