import React from 'react'
import useSWR from 'swr'
import { isValid, getDay, format } from 'date-fns'
import {
  Flex,
  Text,
  Stack,
  CheckboxGroup,
  Checkbox,
  Divider,
  Collapse,
  Icon,
  Input,
  Portal,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Loading, Icons, toast } from '@metropia/react-tools'
import useChange from '@react-hook/change'
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp'
import i18 from 'i18next'

import { FORMAT_OF_DATE } from 'src/configs'
import * as api from 'src/libs/api'
import { Position } from 'src/libs/models'
import { useCurrentLocationInstance } from 'src/libs/hooks'
import { convertSecondsToDuration } from 'src/libs/utils'
import { QueryCombobox, Selector, RouteCard, RouteDetail, PharmacyCard, NavigationArrow, NAVIGATION_DIRECTION } from 'src/components'

import {
  Context,
  MAP_ICON,
  VIEW,
  MD_WIDTH,
  VIEWPORT_PADDING,
  LEAVE_TYPE_TEXT,
  SORT_OPTIONS,
  TRANSIT_MODE_TO_METROPIA_API,
  TRANSPORT_MODE,
  INITIAL_SORT,
} from 'src/views/trip-planning/constants'
import {
  isAtTaiwan,
  sortRoutes,
  getFromEncodedPolyline,
  getPolylineWithBorder,
  getTransitNotation,
  getInitialOption,
} from 'src/views/trip-planning/utils'

function Pharmacy(props) {
  const { env, mapRef, uiRef, mapCtrlContainerRef } = React.useContext(Context)
  const currentLocation = useCurrentLocationInstance(3)
  const isLocationValid = React.useMemo(() => Position.isValid(currentLocation) && isAtTaiwan(currentLocation), [currentLocation])

  const isMobile = useBreakpointValue({ base: true, md: false })
  const { isOpen: isListVisible, onOpen: openList, onToggle: toggleList } = useDisclosure({ defaultIsOpen: true })
  useChange(isMobile, openList)

  const [view, setView] = React.useState(VIEW.LIST)
  const [option] = React.useState(getInitialOption(env))
  const [sort, setSort] = React.useState(INITIAL_SORT)
  const [currentPharmacy, setCurrentPharmacy] = React.useState(null)
  const [checkedIndexes, setCheckedIndexes] = React.useState([])
  const [origin, setOrigin] = React.useState(isLocationValid ? currentLocation : null)
  const shouldDisplayRoutes = React.useMemo(() => currentPharmacy !== null, [currentPharmacy])
  useChange(isLocationValid && !Position.isValid(origin), () => setOrigin(currentLocation))
  const { isOpen: isDetailExtended, onToggle: toggleDetail } = useDisclosure({ defaultIsOpen: false })

  const addressFetcher = React.useCallback((index, keyword) => api.fetchPositionListByAddress({ address: keyword }), [])

  const {
    data: { data: pharmacies },
    isValidating: isPharmacyValidating,
  } = useSWR(Position.isValid(origin) ? [origin.lat, origin.lng, '/pharmacies'] : null, (lat, lng) => api.fetchPharmacyList({ lat, lng }), {
    revalidateOnFocus: false,
    fallbackData: { data: [] },
  })
  useChange(pharmacies, ([pharmacy = null]) => setCurrentPharmacy(pharmacy))

  const destination = React.useMemo(
    () => pharmacies.filter((pharmacy) => pharmacy.id === currentPharmacy?.id)[0]?.destination ?? null,
    [pharmacies, currentPharmacy?.id],
  )
  const {
    data: { data: routes },
    isValidating: isRouteValidating,
    error: routeError,
  } = useSWR(
    Position.isValid(origin) && Position.isValid(destination) && currentPharmacy ? [origin, destination, currentPharmacy, '/pharmacies'] : null,
    (origin, destination, pharmacy) =>
      api.fetchDirectionRoutesByMetropia({
        distance: pharmacy.distance,
        duration: pharmacy.duration,

        ...option,
        origin: Position.stringify(origin),
        originName: origin.name,
        originAddress: origin.address,
        destination: Position.stringify(destination),
        destinationName: destination.name,
        destinationAddress: destination.address,
        weekday: getDay(option.leaveType === metropia.LeaveType.LEAVE_NOW ? new Date() : option.date),
        departureDate: option.leaveType === metropia.LeaveType.LEAVE_NOW ? new Date() : option.date,
        departureTime: { [metropia.LeaveType.LEAVE_NOW]: new Date(), [metropia.LeaveType.DEPART_AT]: option.time }[option.leaveType] ?? null,
        arrivalTime: option.leaveType === metropia.LeaveType.ARRIVE_AT ? option.time : null,
        transitModes: option.preferredTransitModes.flatMap((mode) => TRANSIT_MODE_TO_METROPIA_API[mode]).filter(Boolean),
        minTransferSeconds: option.transferMinutesRange[0] * 60,
        maxTransferSeconds: option.transferMinutesRange[1] * 60,
      }),
    { revalidateOnFocus: false, shouldRetryOnError: false, fallbackData: { data: [] } },
  )

  const highlightedIndex = React.useMemo(() => checkedIndexes.slice(-1)[0] ?? null, [checkedIndexes])
  const sortedRoutes = React.useMemo(() => sortRoutes(sortRoutes(routes, sort.then), sort.first), [routes, sort])

  const selectPharmacy = React.useCallback((pharmacy) => {
    setView(VIEW.LIST)
    setCurrentPharmacy(pharmacy)
  }, [])
  const onBack = React.useCallback(() => {
    switch (view) {
      case VIEW.DETAIL:
        setView(VIEW.LIST)
        break
    }
  }, [view])
  const onCheckboxChange = React.useCallback((stringIndexes) => setCheckedIndexes(stringIndexes.map(Number)), [])

  React.useEffect(() => {
    if (routeError?.status === 401) {
      toast({ status: 'warning', description: i18.t('warn.engine-busy') })
    }
    // NOTE: safari has error when click 'official-website' link
    // toast({ status: 'error', description: JSON.stringify(routeError) })
  }, [routeError])
  React.useEffect(() => {
    if (isPharmacyValidating || isRouteValidating) return
    if (routes.length === 0) {
      setCheckedIndexes([])
    } else {
      setCheckedIndexes([0])
    }
  }, [isPharmacyValidating, isRouteValidating, routes.length])

  React.useEffect(() => {
    const map = mapRef.current
    if (!map) return

    const viewPort = map?.getViewPort()
    if (!viewPort) return

    const isMobile = viewPort.width < MD_WIDTH
    if (isMobile) {
      viewPort.setPadding(...VIEWPORT_PADDING.MD)
    } else {
      viewPort.setPadding(...VIEWPORT_PADDING.LG)
    }
  }, [mapRef])

  React.useEffect(() => {
    if (origin === null || destination === null || sortedRoutes.length === 0 || checkedIndexes.length === 0) return

    const group = new H.map.Group()
    const bubbles = []

    const marksAndLines = checkedIndexes.flatMap((checkedIndex) => {
      const { sections, seconds, source } = sortedRoutes[checkedIndex]
      const isHighlighted = checkedIndex === highlightedIndex

      const { lineStringsBySection, directionsBySection, center } = getFromEncodedPolyline(sections, source)
      const timingBubble = new H.ui.InfoBubble(center, { content: FORMAT_OF_DATE.DURATION(convertSecondsToDuration(seconds)) })
      timingBubble.addClass(`timing${isHighlighted ? ' is-highlighted' : ''}`)
      bubbles.push(timingBubble)

      return sections
        .flatMap((section, index) => {
          const { icon } = getTransitNotation(section, isHighlighted)
          const [departure, arrival] = directionsBySection[index]

          if (isHighlighted && section.type === metropia.TransportMode.TRANSIT) {
            const departureBubble = new H.ui.InfoBubble(departure, { content: section.departure.place.name })
            const arrivalBubble = new H.ui.InfoBubble(arrival, { content: section.arrival.place.name })
            bubbles.push(departureBubble, arrivalBubble)
          }

          return [new H.map.Marker(departure, { icon }), new H.map.Marker(arrival, { icon })]
        })
        .concat(
          sections.flatMap((section, index) => {
            const { color } = getTransitNotation(section, isHighlighted)
            const isDashed = isHighlighted && [...TRANSPORT_MODE.CYCLING.split(','), ...TRANSPORT_MODE.WALKING.split(',')].includes(section.type)
            const opacity = isHighlighted ? 0.9 : 0.6

            return getPolylineWithBorder(lineStringsBySection[index], color, isDashed, opacity)
          }),
        )
        .map((object) => object.setData(checkedIndex))
    })
    const onTap = (event) => {
      const index = event.target.getData()
      if (highlightedIndex === index) return

      setCheckedIndexes([...checkedIndexes.filter((checkedIndex) => checkedIndex !== index), index]) // move new highlighted index to last to display on the map
    }

    const map = mapRef.current
    const ui = uiRef.current
    bubbles.forEach((bubble) => ui.addBubble(bubble))
    group.addObjects(marksAndLines)
    group.addEventListener('tap', onTap, false)
    map.addObject(group)
    map.getViewModel().setLookAtData({ bounds: group.getBoundingBox() })

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      group.removeObjects(marksAndLines)
      group.removeEventListener('tap', onTap, false)
      map?.removeObject(group)
    }
  }, [mapRef, uiRef, origin, destination, sortedRoutes, checkedIndexes, highlightedIndex])

  // render pharmacy location marks
  React.useEffect(() => {
    const map = mapRef.current
    if (map === null || pharmacies.length === 0) return

    const group = new H.map.Group()
    const markers = pharmacies
      .map(
        (pharmacy) =>
          new H.map.DomMarker(pharmacy.destination, {
            icon: MAP_ICON.MARK2,
            data: { position: pharmacy.destination, onClick: () => selectPharmacy(pharmacy), isChecked: pharmacy.id === currentPharmacy?.id },
          }),
      )
      .concat([new H.map.DomMarker(origin, { icon: MAP_ICON.DEPARTURE2, data: { position: origin } })])
    group.addObjects(markers)
    map.addObject(group)
    map.getViewModel().setLookAtData({ bounds: group.getBoundingBox() })

    return () => {
      group?.removeObjects(markers)
      map?.removeObject(group)
    }
  }, [mapRef, origin, pharmacies, currentPharmacy?.id, selectPharmacy])

  // render highlighted bike marks
  React.useEffect(() => {
    if (sortedRoutes.length === 0 || highlightedIndex === null) return

    const bubbles = []
    const bikeMarkers = sortedRoutes[highlightedIndex].sections
      .filter((section) => TRANSPORT_MODE.CYCLING.includes(section.transport.mode) && typeof section.transport.agency === 'object')
      .flatMap(({ departure, arrival }) => {
        const directionBubble = [
          new H.ui.InfoBubble(departure.place.location, { content: departure.place.name }),
          new H.ui.InfoBubble(arrival.place.location, { content: arrival.place.name }),
        ]
        directionBubble.forEach((bubble) => bubble.addClass('station'))
        bubbles.push(...directionBubble)
        return [
          new H.map.Marker(departure.place.location, { icon: MAP_ICON.BIKE }),
          new H.map.Marker(arrival.place.location, { icon: MAP_ICON.BIKE }),
        ]
      })
      .map((marker, index) => marker.setData(index))

    const onEnter = (event) => bubbles[event.target.getData()].open()
    const onLeave = (event) => bubbles[event.target.getData()].close()

    const map = mapRef.current
    const ui = uiRef.current
    bubbles.forEach((bubble) => {
      bubble.close()
      ui.addBubble(bubble)
    })
    bikeMarkers.map((marker) => marker.addEventListener('pointerenter', onEnter, false))
    bikeMarkers.map((marker) => marker.addEventListener('pointerleave', onLeave, false))
    map.addObjects(bikeMarkers)

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      bikeMarkers.map((marker) => marker.removeEventListener('pointerenter', onEnter, false))
      bikeMarkers.map((marker) => marker.removeEventListener('pointerleave', onLeave, false))
      map?.removeObjects(bikeMarkers)
    }
  }, [mapRef, uiRef, sortedRoutes, highlightedIndex])

  // --- list navigating ---
  const navigateList = React.useCallback((event) => {
    const elements = Array.from(document.querySelectorAll('[data-is-in-viewport]'))
    const visibles = Array.from(document.querySelectorAll('[data-is-in-viewport=true]'))
    if (event.currentTarget.dataset.direction === NAVIGATION_DIRECTION.PREV) {
      const firstVisibleIndex = elements.indexOf(visibles[0])
      const scrollToIndex = visibles.length > firstVisibleIndex ? 0 : firstVisibleIndex - visibles.length
      elements[scrollToIndex]?.scrollIntoView({ behavior: 'smooth' })
    } else if (event.currentTarget.dataset.direction === NAVIGATION_DIRECTION.NEXT) {
      const lastVisibleIndex = elements.indexOf(visibles.slice(-1)[0])
      const nextIndex = lastVisibleIndex + visibles.length
      const scrollToIndex = lastVisibleIndex < 0 || nextIndex > elements.length - 1 ? elements.length - 1 : nextIndex
      elements[scrollToIndex]?.scrollIntoView({ behavior: 'smooth', inline: 'end' })
    }
  }, [])
  const observerRef = React.useRef(
    // DOC OF OPTIONS: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
    new window.IntersectionObserver(
      (entries) => {
        // display or hide navigation
        const { parentElement: parent } = entries[0].target
        const navigations = document.querySelectorAll('[data-navigation]')
        if (navigations.length > 0 && parent !== null) {
          Object.assign(navigations[0].style, { display: parent.scrollLeft > 50 ? 'inline-flex' : 'none' })
          Object.assign(navigations[1].style, { display: parent.scrollWidth - parent.scrollLeft - parent.clientWidth > 50 ? 'inline-flex' : 'none' })
        }

        // navigation scrolling anchor
        entries.forEach((entry) => (entry.target.dataset.isInViewport = entry.intersectionRatio === 1))
      },
      { threshold: 1 },
    ),
  )
  React.useEffect(() => {
    if (pharmacies.length === 0) return
    document.querySelectorAll('[data-scroll-target]').forEach((element) => observerRef.current.observe(element))
  }, [pharmacies.length])
  React.useEffect(() => {
    const targetElement = document.querySelector(`[data-scroll-target="${currentPharmacy?.id}"]`)
    if (pharmacies.length === 0 || !targetElement) return
    targetElement.scrollIntoView({ behavior: 'smooth' })
  }, [pharmacies.length, currentPharmacy?.id])
  // --- list navigating ---

  return (
    <Flex
      pointerEvents='none'
      zIndex='1'
      position='fixed'
      top={shouldDisplayRoutes ? { base: '1rem', md: '1.5rem' } : { base: 'auto', md: '1.5rem' }}
      left={{ base: '0.5rem', md: '1rem' }}
      right={{ base: '0.5rem', md: 'auto' }}
      // NOTE: full height on list and detail view
      bottom={shouldDisplayRoutes ? '1rem' : { base: '1rem', md: 'auto' }}
      // parent-100% - top-distance - bottom-distance
      maxHeight={{ base: 'calc(100% - 1rem * 2)', md: 'calc(100% - 1.5rem - 1rem)' }}
    >
      <Stack flex='1' direction={{ base: 'column', md: 'row' }} align='flex-start'>
        {view === VIEW.DETAIL && <NavigationArrow direction={NAVIGATION_DIRECTION.PREV} onClick={onBack} />}

        <Flex
          width={{ base: 'var(--width-base)', md: 'var(--width-md)' }}
          // NOTE: base: 100% for half down on list view, md: 100% for full height on list and detail view
          height='100%'
          // NOTE: base: (parent-100% - icon-height - margin-top(spacing of Stack parent)), md: (same as parent)
          maxHeight={{ base: view === VIEW.LIST ? '100%' : 'calc(100% - 2rem - 0.5rem)', md: '100%' }}
          sx={{ '--width-base': `calc(${window.innerWidth}px - 1rem)`, '--width-md': '25.5rem' }}
        >
          {view === VIEW.LIST ? (
            <Stack
              pointerEvents='auto'
              spacing='0'
              divider={<Divider borderColor='gray.300' />}
              width='inherit'
              mt={{ base: 'auto', md: '0' }}
              height={shouldDisplayRoutes ? { base: '16rem', md: 'auto' } : null}
              transform={{ base: 'translateY(1rem)', md: 'none' }}
              borderTopRadius='xl'
              borderBottomRadius={{ base: 'none', md: 'xl' }}
              bgColor='white'
              shadow='bold'
            >
              <Stack spacing='0' paddingX='1rem' paddingY='0.75rem' bgColor='gray.100' borderTopRadius='inherit' borderBottomRadius='inherit'>
                <Flex align='center' width={{ base: 'calc(var(--width-base) - 1.5rem * 2)', md: 'calc(var(--width-md) - 1.5rem * 2)' }}>
                  <Text flexShrink='0' width='2.5rem' color='gray.500' fontSize='sm'>
                    {i18.t('common.from')}
                  </Text>

                  <QueryCombobox
                    element={<Input size='xs' fontSize='sm' fontWeight='600' placeholder={i18.t('common.empty-origin')} bgColor='white' />}
                    fetcher={addressFetcher}
                    propsOf={{ downshift: { itemToString: (item) => item?.name ?? '' }, query: { fallbackData: [{ data: [currentLocation] }] } }}
                    value={origin}
                    onChange={setOrigin}
                    empty={<QueryCombobox.TextContent>{i18.t('common.empty-content')}</QueryCombobox.TextContent>}
                    loading={<QueryCombobox.TextContent>{i18.t('common.searching')}</QueryCombobox.TextContent>}
                  >
                    {({ getItemProps, item, index, ...rest }) => (
                      <QueryCombobox.Item key={index} px='1rem' py='0.75rem' {...getItemProps({ item, index })}>
                        <Flex align='center' lineHeight='1' isTruncated>
                          <Icon as={Position.CategoryIcon[item.category]} mr='1.125rem' flexShrink='0' boxSize='1.25rem' color='gray.600' />
                          <Text as='span' fontSize='sm' isTruncated>
                            {item.name}
                          </Text>
                        </Flex>
                      </QueryCombobox.Item>
                    )}
                  </QueryCombobox>
                </Flex>

                <Flex>
                  <Text flexShrink='0' width='2.5rem' color='gray.500' fontSize='sm'>
                    {i18.t('common.to')}
                  </Text>
                  <Text fontSize='sm' fontWeight='600'>
                    {destination?.name}
                  </Text>
                </Flex>

                <Stack direction='row' spacing='1rem' align='center'>
                  <Text flexShrink='0' fontSize='sm' fontWeight='600'>
                    {i18.t('trip-planning.label.sort-preference')}
                  </Text>

                  <Flex flexDirection='row' flexWrap='wrap' align='center'>
                    <Stack mr='0.5rem' direction='row' spacing='0.25rem' align='center'>
                      <Text flexShrink='0' color='gray.500' fontSize='sm'>
                        {i18.t('trip-planning.label.sort-preference-first')}
                      </Text>
                      <Selector
                        options={SORT_OPTIONS}
                        value={sort.first}
                        onChange={(first) => {
                          setSort({ ...sort, first })
                          setCheckedIndexes([0])
                        }}
                      />
                    </Stack>

                    <Stack direction='row' spacing='0.25rem' align='center'>
                      <Text flexShrink='0' color='gray.500' fontSize='sm'>
                        {i18.t('trip-planning.label.sort-preference-then')}
                      </Text>
                      <Selector
                        options={SORT_OPTIONS}
                        value={sort.then}
                        onChange={(then) => {
                          setSort({ ...sort, then })
                          setCheckedIndexes([0])
                        }}
                      />
                    </Stack>
                  </Flex>
                </Stack>

                <Stack direction='row' spacing='0.25rem' align='center'>
                  <Icon as={Icons.Clock} color='gray.500' boxSize='1.25rem' />
                  <Text color='gray.500' fontSize='sm'>
                    {[LEAVE_TYPE_TEXT[option.leaveType]]
                      .concat(
                        option.leaveType !== metropia.LeaveType.LEAVE_NOW && isValid(option.date) && isValid(option.time)
                          ? [
                              format(option.date, i18.t('format-pattern.date-picker.input'), { locale: window.datefns_locale }),
                              format(option.time, i18.t('format-pattern.time-picker.input'), { locale: window.datefns_locale }),
                            ]
                          : [],
                      )
                      .join(' ')}
                  </Text>
                </Stack>
              </Stack>

              {shouldDisplayRoutes && (
                <Flex paddingY='0.5rem' direction='column' align='flex-start' overflow='auto'>
                  <CheckboxGroup colorScheme='green' value={checkedIndexes.map(String)} onChange={onCheckboxChange}>
                    <Stack spacing='0' divider={<Divider />} width='100%'>
                      {sortedRoutes.map((route, index) => (
                        <Stack
                          key={index}
                          direction='row'
                          spacing='1rem'
                          paddingX='1rem'
                          align='center'
                          bgColor={checkedIndexes.includes(index) ? 'brand.50' : 'transparent'}
                          aria-selected={index === highlightedIndex}
                          _hover={{ bgColor: 'gray.50' }}
                          _selected={{ bgColor: 'gray.300' }}
                        >
                          <Checkbox value={String(index)} isDisabled={checkedIndexes.length === 3 && !checkedIndexes.includes(index)} />

                          <RouteCard
                            useGoogle={option.useGoogle}
                            route={route}
                            flex='1'
                            paddingY='1rem'
                            onClick={() => {
                              setCheckedIndexes([index])
                              setView(VIEW.DETAIL)
                            }}
                          />
                        </Stack>
                      ))}
                    </Stack>
                  </CheckboxGroup>

                  {routes.length === 0 && (
                    <Text paddingY='1rem' paddingX='1.5rem' fontSize='sm' color='gray.700'>
                      {isPharmacyValidating || isRouteValidating ? i18.t('common.searching') : i18.t('trip-planning.empty-content')}
                    </Text>
                  )}
                </Flex>
              )}

              {(isPharmacyValidating || (isRouteValidating && routes.length === 0)) && <Loading pos='absolute' borderRadius='inherit' />}
            </Stack>
          ) : (
            view === VIEW.DETAIL && (
              <Flex
                pointerEvents='auto'
                pos='relative'
                mt={{ base: 'auto', md: '0' }}
                w='inherit'
                h={{ base: isDetailExtended ? '100%' : '8rem', md: 'auto' }}
                transform={{ base: isDetailExtended ? 'none' : 'translateY(1rem)', md: 'none' }}
                transitionDuration='400ms'
                transitionTimingFunction='ease-in-out'
                borderTopRadius='xl'
                borderBottomRadius={{ base: isDetailExtended ? 'xl' : 'none', md: 'xl' }}
                bgColor='white'
                shadow='bold'
              >
                <NavigationArrow
                  display={{ md: 'none' }}
                  pos='absolute'
                  top='0'
                  left='50%'
                  transform='translateX(-50%)'
                  shadow='none'
                  borderColor='transparent'
                  size='xs'
                  onClick={toggleDetail}
                  direction={isDetailExtended ? NAVIGATION_DIRECTION.DOWN : NAVIGATION_DIRECTION.UP}
                />
                <RouteDetail option={option} route={sortedRoutes[highlightedIndex]} directions={[origin, destination]} />
              </Flex>
            )
          )}
        </Flex>
      </Stack>

      <Portal containerRef={mapCtrlContainerRef}>
        <Flex
          direction='column'
          ml={{ base: 'auto', md: shouldDisplayRoutes ? (view === VIEW.LIST ? '26rem' : '28.5rem') : '0' }}
          mb={{ base: shouldDisplayRoutes && view === VIEW.DETAIL ? '8rem' : 'auto', md: 'auto' }}
          bgColor={{ base: isListVisible ? 'whiteAlpha.500' : 'transparent', md: 'transparent' }}
          backdropFilter={{ base: isListVisible ? 'blur(3px)' : 'none', md: 'none' }}
        >
          <Flex display={{ base: 'flex', md: 'none' }} pt='0.5rem' pb={isListVisible ? '0' : '0.5rem'} px='0.5rem'>
            <NavigationArrow onClick={toggleList} direction={isListVisible ? NAVIGATION_DIRECTION.DOWN : NAVIGATION_DIRECTION.UP} />
          </Flex>

          <Collapse in={isListVisible}>
            <Flex pointerEvents='auto' position='relative'>
              <NavigationArrow
                hidden={isMobile}
                onClick={navigateList}
                data-navigation
                direction={NAVIGATION_DIRECTION.PREV}
                zIndex='1'
                pos='absolute'
                left='1rem'
                top='50%'
                transform='translateY(-50%)'
              />
              <NavigationArrow
                hidden={isMobile}
                onClick={navigateList}
                data-navigation
                direction={NAVIGATION_DIRECTION.NEXT}
                zIndex='1'
                pos='absolute'
                right='1rem'
                top='50%'
                transform='translateY(-50%)'
              />

              <Stack
                direction='row'
                spacing='0.75rem'
                w={{ base: '100%', md: 'auto' }}
                pl={{ base: '0.5rem', md: '1.25rem' }}
                pr={{ base: '0.5rem', md: '1rem' }}
                py='1rem'
                overflow='auto'
              >
                {pharmacies.length === 0 ? (
                  <Flex px={{ md: '5rem' }} py='2rem' w='100%' justify='center' bgColor='white' borderRadius='xl' shadow='bold'>
                    {i18.t('common.empty-origin')}
                  </Flex>
                ) : (
                  pharmacies.map((pharmacy, index) => (
                    <Flex key={index} shrink='0' data-scroll-target={pharmacy.id}>
                      <PharmacyCard pharmacy={pharmacy} isChecked={pharmacy.id === currentPharmacy?.id} onClick={() => selectPharmacy(pharmacy)} />
                    </Flex>
                  ))
                )}
              </Stack>
            </Flex>
          </Collapse>
        </Flex>
      </Portal>
    </Flex>
  )
}

export default Pharmacy
