import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Field } from 'formik'
import {
  Flex,
  Stack,
  Text,
  Divider,
  FormControl,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Switch,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { FormLabelInput, FormLabelControl, DatePicker, TimePicker, Select, formikHelper } from '@metropia/react-tools'
import i18 from 'i18next'

import { LOCATED_UNIT, FORMAT } from 'src/configs'
import { Context, PREFERRED_TRANSIT_TEXT, FIRST_AND_LAST_MILE_MODE_TEXT, BIKE_TYPE_TEXT, LEAVE_TYPE_TEXT } from 'src/views/trip-planning/constants'

const FormLabelNumberInput = React.forwardRef(({ label, ...rest }, ref) => {
  const hasValue = React.useMemo(() => Number.isFinite(rest.value), [rest.value])

  return (
    <FormLabelControl label={label} hasValue={hasValue}>
      {({ field }) => (
        <NumberInput ref={ref} {...rest}>
          <NumberInputField
            id={field.name}
            pt={field?.isFocused || hasValue ? '1.625rem' : '1.125rem'}
            pb={field?.isFocused || hasValue ? '0.625rem' : '1.125rem'}
            bgColor='white'
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      )}
    </FormLabelControl>
  )
})

FormLabelNumberInput.propTypes = {
  label: PropTypes.node.isRequired,
}

export const TripPlanningOption = ({ formik }) => {
  const { env } = React.useContext(Context)

  const dateAdapter = React.useMemo(
    () => ({
      input: (date) => format(date, i18.t('format-pattern.date-picker.input'), { locale: window.datefns_locale }),
      month: (date) => format(date, i18.t('format-pattern.date-picker.month'), { locale: window.datefns_locale }),
      weekday: (date) => format(date, i18.t('format-pattern.date-picker.weekday'), { locale: window.datefns_locale }),
      day: (date) => format(date, i18.t('format-pattern.date-picker.day'), { locale: window.datefns_locale }),
    }),
    [],
  )
  const timeAdapter = React.useMemo(
    () => ({
      input: (time) => format(time, i18.t('format-pattern.time-picker.input'), { locale: window.datefns_locale }),
      label: (time) => format(time, i18.t('format-pattern.time-picker.label'), { locale: window.datefns_locale }),
    }),
    [],
  )
  const tabs = React.useMemo(() => [i18.t('trip-planning.tab.normal-option'), i18.t('trip-planning.tab.advanced-option')], [])
  const topOptions = React.useMemo(
    () =>
      Array(env.OPTION_EXTREMUM.TOP[1])
        .fill(0)
        .map((_, index) => index + 1)
        .filter((value) => value >= env.OPTION_EXTREMUM.TOP[0])
        .map((value) => String(value))
        .map((value) => ({ label: value, value })),
    [env],
  )
  const dimensionOptions = React.useMemo(
    () =>
      Array(env.OPTION_EXTREMUM.DIMENSION[1])
        .fill(0)
        .map((_, index) => index + 1)
        .filter((value) => value >= env.OPTION_EXTREMUM.DIMENSION[0])
        .map((value) => String(value))
        .map((value) => ({ label: value, value })),
    [env],
  )

  const normal = React.useMemo(() => {
    const shouldInterlockWithLast =
      formik.submitCount === 0 &&
      !(formik.touched?.lastMileModes?.some((touched) => touched) || formik.touched?.lastMileMaxMinutes?.some((touched) => touched))

    return (
      <Stack spacing='0' divider={<Divider borderColor='gray.300' />} height='100%' overflowX='hidden' overflowY='auto'>
        {env.VISIBLE_OPTION.LEAVE_TYPE.length > 0 && (
          <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
            <Text fontSize='sm' fontWeight='600'>
              {i18.t('trip-planning.label.date-time-preference')}
            </Text>

            <Field name='leaveType'>
              {({ field }) => (
                // NOTE: radio error issue - https://github.com/chakra-ui/chakra-ui/issues/4891
                <RadioGroup
                  {...field}
                  as={Stack}
                  direction='row'
                  spacing='0.75rem'
                  colorScheme='green'
                  onChange={(value) => formik.setFieldValue(field.name, value)}
                >
                  {env.VISIBLE_OPTION.LEAVE_TYPE.map((type, index) => (
                    <Radio key={index} spacing='0.25rem' value={type} isChecked={field.value === type}>
                      {LEAVE_TYPE_TEXT[type]}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            </Field>

            <Stack direction='row'>
              <Field name='date'>
                {({ field }) => (
                  <DatePicker
                    {...field}
                    min={new Date()}
                    isDisabled={formik.values.leaveType === metropia.LeaveType.LEAVE_NOW}
                    bgColor='white'
                    onChange={(value) => formik.setFieldValue(field.name, value)}
                    todayText={i18.t('common.today')}
                    formatAdapter={dateAdapter}
                    _hover={{ borderColor: 'gray.500' }}
                    _disabled={{ cursor: 'not-allowed', bgColor: 'gray.200', opacity: 0.6 }}
                  />
                )}
              </Field>

              <Field name='time'>
                {({ field }) => (
                  <TimePicker
                    {...field}
                    minWidth='auto'
                    listProps={{ minWidth: 'auto' }}
                    height='2.5rem'
                    isDisabled={formik.values.leaveType === metropia.LeaveType.LEAVE_NOW}
                    onChange={(value) => formik.setFieldValue(field.name, value)}
                    formatAdapter={timeAdapter}
                    buttonProps={{
                      _hover: { borderColor: 'gray.500' },
                      _active: { borderColor: 'gray.500' },
                      _disabled: { cursor: 'not-allowed', bgColor: 'gray.200', opacity: 0.6 },
                    }}
                  />
                )}
              </Field>
            </Stack>
          </Stack>
        )}

        {env.VISIBLE_OPTION.PREFFERED_TRANSITS.length > 0 && (
          <Flex direction='column' paddingX='1.25rem' paddingY='1rem'>
            <Text fontSize='sm' fontWeight='600'>
              {i18.t('trip-planning.label.preferred-modes')}
            </Text>

            <Field name='preferredTransitModes'>
              {({ field, meta }) => (
                <CheckboxGroup {...field} colorScheme='green' onChange={(value) => formik.setFieldValue(field.name, value)}>
                  <Flex margin='-0.5rem' marginTop='0.25rem' direction='row' flexWrap='wrap'>
                    {env.VISIBLE_OPTION.PREFFERED_TRANSITS.map((mode, index) => (
                      <Checkbox key={index} value={mode} margin='0.5rem' isInvalid={formikHelper.checkIsInvalid(meta)}>
                        {PREFERRED_TRANSIT_TEXT[mode]}
                      </Checkbox>
                    ))}
                  </Flex>
                </CheckboxGroup>
              )}
            </Field>
          </Flex>
        )}

        {env.VISIBLE_OPTION.TRANSFER_MINUTES_RANGE && (
          <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
            <Stack spacing='0.25rem'>
              <Text fontSize='sm' fontWeight='600'>
                {i18.t('trip-planning.label.transfer-range')}
              </Text>

              <Text color='gray.700' fontSize='xs'>
                {i18.t('trip-planning.description.transfer-range')}
              </Text>
            </Stack>

            <Stack spacing='0.25rem'>
              <Flex paddingX='0.625rem'>
                <Field name='transferMinutesRange'>
                  {({ field }) => (
                    <RangeSlider
                      {...field}
                      min={env.OPTION_EXTREMUM.TRANSFER_MINUTES_RANGE[0]}
                      max={env.OPTION_EXTREMUM.TRANSFER_MINUTES_RANGE[1]}
                      step={5}
                      onChange={(value) => formik.setFieldValue(field.name, value)}
                    >
                      <RangeSliderTrack>
                        <RangeSliderFilledTrack bg='green.300' />
                      </RangeSliderTrack>
                      <RangeSliderThumb boxSize='1.25rem' bg='green.500' index={0} />
                      <RangeSliderThumb boxSize='1.25rem' bg='green.500' index={1} />
                    </RangeSlider>
                  )}
                </Field>
              </Flex>

              <Flex justify='space-between'>
                <Text fontSize='xs'>
                  {formik.values.transferMinutesRange[0]} {i18.t('common.unit.minutes-short')}
                </Text>
                <Text fontSize='xs'>
                  {formik.values.transferMinutesRange[1]} {i18.t('common.unit.minutes-short')}
                </Text>
              </Flex>
            </Stack>
          </Stack>
        )}

        {env.VISIBLE_OPTION.TIME_COST_PREFERENCE && (
          <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
            <Stack spacing='0.25rem'>
              <Text fontSize='sm' fontWeight='600'>
                {i18.t('trip-planning.label.time-cost-sensitivity')}
              </Text>
              <Text color='gray.700' fontSize='xs'>
                {i18.t('trip-planning.description.time-cost-sensitivity')}
              </Text>
            </Stack>

            <Stack spacing='0.25rem'>
              <Flex paddingX='0.625rem'>
                <Field name='timeCostPreference'>
                  {({ field }) => (
                    <Slider
                      {...field}
                      min={env.OPTION_EXTREMUM.TIME_COST_PREFERENCE[0]}
                      max={env.OPTION_EXTREMUM.TIME_COST_PREFERENCE[1]}
                      step={0.1}
                      onChange={(value) => formik.setFieldValue(field.name, value)}
                    >
                      <SliderTrack>
                        <SliderFilledTrack bg='green.300' />
                      </SliderTrack>
                      <SliderThumb boxSize='1.25rem' bg='green.500' />
                    </Slider>
                  )}
                </Field>
              </Flex>

              <Flex justify='space-between'>
                <Text fontSize='xs'>{i18.t('trip-planning.label.less-time')}</Text>
                <Text fontSize='xs'>{i18.t('trip-planning.label.less-cost')}</Text>
              </Flex>
            </Stack>
          </Stack>
        )}

        {env.VISIBLE_OPTION.FIRST_MILE_MODES.length > 0 && (
          <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
            <Text fontSize='sm' fontWeight='600'>
              {i18.t('trip-planning.label.first-mile-mode-and-transfer-time')}
            </Text>

            <Field name='firstMileModes'>
              {({ field: fieldOfMode, meta: metaOfMode }) => (
                <CheckboxGroup
                  {...fieldOfMode}
                  as={Stack}
                  spacing='1.5rem'
                  colorScheme='green'
                  value={fieldOfMode.value.map((mode) => String(mode))}
                  onChange={(modes) => {
                    const value = modes.map((mode) => Number(mode))
                    formik.setFieldValue(fieldOfMode.name, value)
                    shouldInterlockWithLast && formik.setFieldValue('lastMileModes', value)
                  }}
                >
                  {env.VISIBLE_OPTION.FIRST_MILE_MODES.map((mode, index) => (
                    <Flex key={index} direction='column' align='flex-start'>
                      <Checkbox value={String(mode)} isInvalid={formikHelper.checkIsInvalid(metaOfMode)}>
                        <Stack direction='row' spacing='1rem' align='center'>
                          <Text fontSize='sm'>
                            {FIRST_AND_LAST_MILE_MODE_TEXT[mode]} ({i18.t('trip-planning.label.mode-and-transfer-max-time')})
                          </Text>

                          {env.VISIBLE_OPTION.USE_FIRST_MILE_RENTAL_SCOOTER &&
                            env.LOCATION === window.LOCATION.TAIWAN &&
                            mode === metropia.FirstAndLastMileMode.DRIVING &&
                            fieldOfMode.value.includes(metropia.FirstAndLastMileMode.DRIVING) && (
                              <Field name='useFirstMileRentalScooter'>
                                {({ field: fieldOfRentalScooter, meta: metaOfRentalScooter }) => (
                                  <CheckboxGroup size='sm' direction='row' colorScheme='green'>
                                    <Checkbox
                                      spacing='0.25rem'
                                      isChecked={fieldOfRentalScooter.value}
                                      onChange={(event) => formik.setFieldValue(fieldOfRentalScooter.name, event.currentTarget.checked)}
                                      isInvalid={formikHelper.checkIsInvalid(metaOfRentalScooter)}
                                    >
                                      <Text fontSize='xs'>{i18.t('trip-planning.label.rental-scooter')}</Text>
                                    </Checkbox>
                                  </CheckboxGroup>
                                )}
                              </Field>
                            )}

                          {mode === metropia.FirstAndLastMileMode.CYCLING && fieldOfMode.value.includes(metropia.FirstAndLastMileMode.CYCLING) && (
                            <Field name='firstMileBikeTypes'>
                              {({ field: fieldOfBikeType, meta: metaOfBikeType }) =>
                                env.LOCATION === window.LOCATION.TAIWAN ? (
                                  // NOTE: use radio currently
                                  <RadioGroup
                                    {...fieldOfBikeType}
                                    as={Stack}
                                    size='sm'
                                    direction='row'
                                    colorScheme='green'
                                    value={String(fieldOfBikeType.value)}
                                    onChange={(value) => formik.setFieldValue(fieldOfBikeType.name, Number(value))}
                                  >
                                    {env.VISIBLE_OPTION.FIRST_MILE_BIKE_TYPES.map((type, index) => (
                                      <Radio
                                        key={index}
                                        spacing='0.25rem'
                                        value={String(type)}
                                        isChecked={fieldOfBikeType.value === type}
                                        isInvalid={formikHelper.checkIsInvalid(metaOfBikeType)}
                                      >
                                        <Text fontSize='xs'>{BIKE_TYPE_TEXT[type]}</Text>
                                      </Radio>
                                    ))}
                                  </RadioGroup>
                                ) : (
                                  <CheckboxGroup
                                    {...fieldOfBikeType}
                                    as={Stack}
                                    size='sm'
                                    direction='row'
                                    colorScheme='green'
                                    value={fieldOfBikeType.value.map((type) => String(type))}
                                    onChange={(types) =>
                                      formik.setFieldValue(
                                        fieldOfBikeType.name,
                                        types.map((type) => Number(type)),
                                      )
                                    }
                                  >
                                    {env.VISIBLE_OPTION.FIRST_MILE_BIKE_TYPES.map((type, index) => (
                                      <Checkbox
                                        key={index}
                                        spacing='0.25rem'
                                        value={String(type)}
                                        isChecked={fieldOfBikeType.value[index] === type}
                                        isInvalid={formikHelper.checkIsInvalid(metaOfBikeType)}
                                      >
                                        <Text fontSize='xs'>{BIKE_TYPE_TEXT[type]}</Text>
                                      </Checkbox>
                                    ))}
                                  </CheckboxGroup>
                                )
                              }
                            </Field>
                          )}
                        </Stack>
                      </Checkbox>

                      <Field name={`firstMileMaxMinutes.${mode}`}>
                        {({ field: fieldOfMinutes }) => (
                          <Stack marginLeft='1.875rem' marginTop='0.75rem' spacing='0.25rem' alignSelf='stretch'>
                            <Flex paddingX='0.5rem'>
                              <Slider
                                {...fieldOfMinutes}
                                height='1.25rem'
                                min={env.OPTION_EXTREMUM.FIRST_MILE_MAX_MINUTES[index][0]}
                                max={env.OPTION_EXTREMUM.FIRST_MILE_MAX_MINUTES[index][1]}
                                step={5}
                                onChange={(value) => {
                                  formik.setFieldValue(fieldOfMinutes.name, value)
                                  shouldInterlockWithLast && formik.setFieldValue(`lastMileMaxMinutes.${mode}`, value)
                                }}
                                isDisabled={!fieldOfMode.value.includes(mode)}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack bg='green.300' />
                                </SliderTrack>
                                <SliderThumb boxSize='1.25rem' bg='green.500' />
                              </Slider>
                            </Flex>

                            <Text fontSize='xs' textAlign='center'>
                              {fieldOfMinutes.value} {i18.t('common.unit.minutes-short')}
                            </Text>
                          </Stack>
                        )}
                      </Field>
                    </Flex>
                  ))}
                </CheckboxGroup>
              )}
            </Field>
          </Stack>
        )}

        {env.VISIBLE_OPTION.LAST_MILE_MODES.length > 0 && (
          <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
            <Text fontSize='sm' fontWeight='600'>
              {i18.t('trip-planning.label.last-mile-mode-and-transfer-time')}
            </Text>

            <Field name='lastMileModes'>
              {({ field: fieldOfMode, meta: metaOfMode }) => (
                <CheckboxGroup
                  {...fieldOfMode}
                  as={Stack}
                  spacing='1.5rem'
                  colorScheme='green'
                  value={fieldOfMode.value.map((mode) => String(mode))}
                  onChange={(modes) => {
                    formik.setFieldValue(
                      fieldOfMode.name,
                      modes.map((mode) => Number(mode)),
                    )
                  }}
                >
                  {env.VISIBLE_OPTION.LAST_MILE_MODES.map((mode, index) => (
                    <Flex key={index} direction='column' align='flex-start'>
                      <Checkbox
                        value={String(mode)}
                        isInvalid={formikHelper.checkIsInvalid(metaOfMode)}
                        onChange={() => formik.setFieldTouched(`${fieldOfMode.name}.${mode}`, true)}
                      >
                        <Stack direction='row' spacing='1rem' align='center'>
                          <Text fontSize='sm'>
                            {FIRST_AND_LAST_MILE_MODE_TEXT[mode]} ({i18.t('trip-planning.label.mode-and-transfer-max-time')})
                          </Text>

                          {env.VISIBLE_OPTION.USE_LAST_MILE_RENTAL_CAR &&
                            env.LOCATION === window.LOCATION.TAIWAN &&
                            mode === metropia.FirstAndLastMileMode.DRIVING &&
                            fieldOfMode.value.includes(metropia.FirstAndLastMileMode.DRIVING) && (
                              <Field name='useLastMileRentalCar'>
                                {({ field: fieldOfRentalCar, meta: metaOfRentalCar }) => (
                                  <CheckboxGroup size='sm' direction='row' colorScheme='green'>
                                    <Checkbox
                                      spacing='0.25rem'
                                      isChecked={fieldOfRentalCar.value}
                                      onChange={(event) => formik.setFieldValue(fieldOfRentalCar.name, event.currentTarget.checked)}
                                      isInvalid={formikHelper.checkIsInvalid(metaOfRentalCar)}
                                    >
                                      <Text fontSize='xs'>{i18.t('trip-planning.label.rental-car')}</Text>
                                    </Checkbox>
                                  </CheckboxGroup>
                                )}
                              </Field>
                            )}

                          {mode === metropia.FirstAndLastMileMode.CYCLING && fieldOfMode.value.includes(metropia.FirstAndLastMileMode.CYCLING) && (
                            <Field name='lastMileBikeTypes'>
                              {({ field: fieldOfBikeType, meta: metaOfBikeType }) =>
                                env.LOCATION === window.LOCATION.TAIWAN ? (
                                  // NOTE: use radio currently
                                  <RadioGroup
                                    {...fieldOfBikeType}
                                    as={Stack}
                                    size='sm'
                                    direction='row'
                                    colorScheme='green'
                                    value={String(fieldOfBikeType.value)}
                                    onChange={(value) => formik.setFieldValue(fieldOfBikeType.name, Number(value))}
                                  >
                                    {env.VISIBLE_OPTION.LAST_MILE_BIKE_TYPES.map((type, index) => (
                                      <Radio
                                        key={index}
                                        spacing='0.25rem'
                                        value={String(type)}
                                        isChecked={fieldOfBikeType.value === type}
                                        isInvalid={formikHelper.checkIsInvalid(metaOfBikeType)}
                                      >
                                        <Text fontSize='xs'>{BIKE_TYPE_TEXT[type]}</Text>
                                      </Radio>
                                    ))}
                                  </RadioGroup>
                                ) : (
                                  <CheckboxGroup
                                    {...fieldOfBikeType}
                                    as={Stack}
                                    size='sm'
                                    direction='row'
                                    colorScheme='green'
                                    value={fieldOfBikeType.value.map((type) => String(type))}
                                    onChange={(types) =>
                                      formik.setFieldValue(
                                        fieldOfBikeType.name,
                                        types.map((type) => Number(type)),
                                      )
                                    }
                                  >
                                    {env.VISIBLE_OPTION.LAST_MILE_BIKE_TYPES.map((type, index) => (
                                      <Checkbox
                                        key={index}
                                        spacing='0.25rem'
                                        value={String(type)}
                                        isChecked={fieldOfBikeType.value[index] === type}
                                        isInvalid={formikHelper.checkIsInvalid(metaOfBikeType)}
                                      >
                                        <Text fontSize='xs'>{BIKE_TYPE_TEXT[type]}</Text>
                                      </Checkbox>
                                    ))}
                                  </CheckboxGroup>
                                )
                              }
                            </Field>
                          )}
                        </Stack>
                      </Checkbox>

                      <Field name={`lastMileMaxMinutes.${mode}`}>
                        {({ field: fieldOfMinutes }) => (
                          <Stack marginLeft='1.875rem' marginTop='0.75rem' spacing='0.25rem' alignSelf='stretch'>
                            <Flex paddingX='0.5rem'>
                              <Slider
                                {...fieldOfMinutes}
                                height='1.25rem'
                                min={env.OPTION_EXTREMUM.LAST_MILE_MAX_MINUTES[index][0]}
                                max={env.OPTION_EXTREMUM.LAST_MILE_MAX_MINUTES[index][1]}
                                step={5}
                                onChange={(value) => {
                                  formik.setFieldValue(fieldOfMinutes.name, value)
                                  formik.setFieldTouched(fieldOfMinutes.name, true)
                                }}
                                isDisabled={!fieldOfMode.value.includes(mode)}
                              >
                                <SliderTrack>
                                  <SliderFilledTrack bg='green.300' />
                                </SliderTrack>
                                <SliderThumb boxSize='1.25rem' bg='green.500' />
                              </Slider>
                            </Flex>

                            <Text fontSize='xs' textAlign='center'>
                              {fieldOfMinutes.value} {i18.t('common.unit.minutes-short')}
                            </Text>
                          </Stack>
                        )}
                      </Field>
                    </Flex>
                  ))}
                </CheckboxGroup>
              )}
            </Field>
          </Stack>
        )}
      </Stack>
    )
  }, [
    env.LOCATION,
    env.OPTION_EXTREMUM.FIRST_MILE_MAX_MINUTES,
    env.OPTION_EXTREMUM.LAST_MILE_MAX_MINUTES,
    env.OPTION_EXTREMUM.TIME_COST_PREFERENCE,
    env.OPTION_EXTREMUM.TRANSFER_MINUTES_RANGE,
    env.VISIBLE_OPTION.FIRST_MILE_BIKE_TYPES,
    env.VISIBLE_OPTION.FIRST_MILE_MODES,
    env.VISIBLE_OPTION.USE_FIRST_MILE_RENTAL_SCOOTER,
    env.VISIBLE_OPTION.LAST_MILE_BIKE_TYPES,
    env.VISIBLE_OPTION.LAST_MILE_MODES,
    env.VISIBLE_OPTION.USE_LAST_MILE_RENTAL_CAR,
    env.VISIBLE_OPTION.LEAVE_TYPE,
    env.VISIBLE_OPTION.PREFFERED_TRANSITS,
    env.VISIBLE_OPTION.TIME_COST_PREFERENCE,
    env.VISIBLE_OPTION.TRANSFER_MINUTES_RANGE,
    formik,
    dateAdapter,
    timeAdapter,
  ])

  const advanced = React.useMemo(
    () => (
      <Stack spacing='0' divider={<Divider borderColor='gray.300' />} height='100%' overflowX='hidden' overflowY='auto'>
        <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
          <Stack spacing='0.25rem'>
            <Text fontSize='sm' fontWeight='600'>
              {i18.t('trip-planning.label.time-cost')}
            </Text>

            <Text color='gray.700' fontSize='xs'>
              {i18.t('trip-planning.description.time-cost', {
                dollar: LOCATED_UNIT[env.LOCATION].DOLLAR,
                value: FORMAT.GROUP(env.OPTION_EXTREMUM.TIME_COST_OF_NORMAL_TRAVEL[1]),
              })}
            </Text>
          </Stack>

          <Stack direction='row'>
            <Field name='timeCostOfNormalTravel'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <NumberInput
                    {...field}
                    min={env.OPTION_EXTREMUM.TIME_COST_OF_NORMAL_TRAVEL[0]}
                    max={env.OPTION_EXTREMUM.TIME_COST_OF_NORMAL_TRAVEL[1]}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isSafeInteger(number) ? number : '')}
                  >
                    <FormLabelInput
                      {...field}
                      id={field.name}
                      label={i18.t('trip-planning.label.normal-travel', { 'time-cost': LOCATED_UNIT[env.LOCATION].TIME_COST })}
                      component={NumberInputField}
                      bgColor='white'
                    />
                  </NumberInput>
                </FormControl>
              )}
            </Field>

            <Field name='timeCostOfBusinessTravel'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <NumberInput
                    {...field}
                    min={env.OPTION_EXTREMUM.TIME_COST_OF_BUSINESS_TRAVEL[0]}
                    max={env.OPTION_EXTREMUM.TIME_COST_OF_BUSINESS_TRAVEL[1]}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isSafeInteger(number) ? number : '')}
                  >
                    <FormLabelInput
                      {...field}
                      id={field.name}
                      label={i18.t('trip-planning.label.business-travel', { 'time-cost': LOCATED_UNIT[env.LOCATION].TIME_COST })}
                      component={NumberInputField}
                      bgColor='white'
                    />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
          </Stack>

          <Field name='timeCostOfLastMile'>
            {({ field, meta }) => (
              <FormControl isInvalid={formikHelper.checkIsInvalid(meta)} width='calc(50% - 0.25rem)'>
                <NumberInput
                  {...field}
                  min={env.OPTION_EXTREMUM.TIME_COST_OF_LAST_MILE[0]}
                  max={env.OPTION_EXTREMUM.TIME_COST_OF_LAST_MILE[1]}
                  onChange={(_, number) => formik.setFieldValue(field.name, Number.isSafeInteger(number) ? number : '')}
                >
                  <FormLabelInput
                    {...field}
                    id={field.name}
                    label={i18.t('trip-planning.label.last-mile', { 'time-cost': LOCATED_UNIT[env.LOCATION].TIME_COST })}
                    component={NumberInputField}
                    bgColor='white'
                  />
                </NumberInput>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
          <Stack spacing='0.25rem'>
            <Text fontSize='sm' fontWeight='600'>
              {i18.t('trip-planning.label.transit-parameter')}
            </Text>

            <Text color='gray.700' fontSize='xs'>
              {i18.t('trip-planning.description.transit-parameter', {
                'time-cost': LOCATED_UNIT[env.LOCATION].TIME_COST,
                'max-time-cost': FORMAT.GROUP(env.OPTION_EXTREMUM.TRANSIT_OF_TIME_COST[1]),
                dollar: LOCATED_UNIT[env.LOCATION].DOLLAR,
                'max-fare': FORMAT.GROUP(env.OPTION_EXTREMUM.TRANSIT_OF_FARE[1]),
              })}
            </Text>
          </Stack>

          <Stack direction='row'>
            <Field name='transitOfTimeCost'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <NumberInput
                    {...field}
                    min={env.OPTION_EXTREMUM.TRANSIT_OF_TIME_COST[0]}
                    max={env.OPTION_EXTREMUM.TRANSIT_OF_TIME_COST[1]}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isSafeInteger(number) ? number : '')}
                  >
                    <FormLabelInput
                      {...field}
                      id={field.name}
                      label={i18.t('trip-planning.label.transit-time-cost', { 'time-cost': LOCATED_UNIT[env.LOCATION].TIME_COST })}
                      component={NumberInputField}
                      bgColor='white'
                    />
                  </NumberInput>
                </FormControl>
              )}
            </Field>

            <Field name='transitOfFare'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <NumberInput
                    {...field}
                    min={env.OPTION_EXTREMUM.TRANSIT_OF_FARE[0]}
                    max={env.OPTION_EXTREMUM.TRANSIT_OF_FARE[1]}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isSafeInteger(number) ? number : '')}
                  >
                    <FormLabelInput
                      {...field}
                      id={field.name}
                      label={i18.t('trip-planning.label.transit-fare', { dollar: LOCATED_UNIT[env.LOCATION].DOLLAR })}
                      component={NumberInputField}
                      bgColor='white'
                    />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
          </Stack>
        </Stack>

        <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
          <Stack spacing='0.25rem'>
            <Text fontSize='sm' fontWeight='600'>
              {i18.t('trip-planning.label.speed-parameter')}
            </Text>

            <Text color='gray.700' fontSize='xs'>
              {i18.t('trip-planning.description.speed-parameter', {
                'max-walking': env.OPTION_EXTREMUM.SPEED_OF_WALKING[1],
                'max-cycling': env.OPTION_EXTREMUM.SPEED_OF_CYCLING[1],
                'max-driving': env.OPTION_EXTREMUM.SPEED_OF_DRIVING[1],
                'time-speed': LOCATED_UNIT[env.LOCATION].TIME_SPEED,
              })}
            </Text>
          </Stack>

          <Stack direction='row'>
            <Field name='speedOfWalking'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <NumberInput
                    {...field}
                    min={env.OPTION_EXTREMUM.SPEED_OF_WALKING[0]}
                    max={env.OPTION_EXTREMUM.SPEED_OF_WALKING[1]}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isSafeInteger(number) ? number : '')}
                  >
                    <FormLabelInput
                      {...field}
                      id={field.name}
                      label={i18.t('trip-planning.label.speed-of-walking', { 'time-speed': LOCATED_UNIT[env.LOCATION].TIME_SPEED })}
                      component={NumberInputField}
                      bgColor='white'
                    />
                  </NumberInput>
                </FormControl>
              )}
            </Field>

            <Field name='speedOfCycling'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <NumberInput
                    {...field}
                    min={env.OPTION_EXTREMUM.SPEED_OF_CYCLING[0]}
                    max={env.OPTION_EXTREMUM.SPEED_OF_CYCLING[1]}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isSafeInteger(number) ? number : '')}
                  >
                    <FormLabelInput
                      {...field}
                      id={field.name}
                      label={i18.t('trip-planning.label.speed-of-cycling', { 'time-speed': LOCATED_UNIT[env.LOCATION].TIME_SPEED })}
                      component={NumberInputField}
                      bgColor='white'
                    />
                  </NumberInput>
                </FormControl>
              )}
            </Field>

            <Field name='speedOfDriving'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <NumberInput
                    {...field}
                    min={env.OPTION_EXTREMUM.SPEED_OF_DRIVING[0]}
                    max={env.OPTION_EXTREMUM.SPEED_OF_DRIVING[1]}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isSafeInteger(number) ? number : '')}
                  >
                    <FormLabelInput
                      {...field}
                      id={field.name}
                      label={i18.t('trip-planning.label.speed-of-driving', { 'time-speed': LOCATED_UNIT[env.LOCATION].TIME_SPEED })}
                      component={NumberInputField}
                      bgColor='white'
                    />
                  </NumberInput>
                </FormControl>
              )}
            </Field>
          </Stack>
        </Stack>

        <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
          <Stack spacing='0.25rem'>
            <Text fontSize='sm' fontWeight='600'>
              {i18.t('trip-planning.label.labeling-range')}
            </Text>

            <Text color='gray.700' fontSize='xs'>
              {i18.t('trip-planning.description.labeling-range', {
                min: FORMAT.GROUP(env.OPTION_EXTREMUM.LABELING[0]),
                max: FORMAT.GROUP(env.OPTION_EXTREMUM.LABELING[1]),
              })}
            </Text>
          </Stack>

          <Field name='labeling'>
            {({ field, meta }) => (
              <FormControl isInvalid={formikHelper.checkIsInvalid(meta)} width='8.75rem' bgColor='white'>
                <NumberInput
                  {...field}
                  min={env.OPTION_EXTREMUM.LABELING[0]}
                  max={env.OPTION_EXTREMUM.LABELING[1]}
                  onChange={(_, number) => formik.setFieldValue(field.name, Number.isSafeInteger(number) ? number : '')}
                >
                  <FormLabelInput {...field} id={field.name} label={i18.t('trip-planning.label.labeling')} component={NumberInputField} />
                </NumberInput>
              </FormControl>
            )}
          </Field>
        </Stack>

        <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
          <Text fontSize='sm' fontWeight='600'>
            {i18.t('trip-planning.label.algorithm')}
          </Text>

          <Stack direction='row'>
            <Field name='top'>
              {({ field }) => (
                <Stack>
                  <Text fontSize='xs'>{i18.t('trip-planning.label.top')}</Text>

                  <Select
                    options={topOptions}
                    propsOf={{ button: { height: '2rem', pt: '0', pb: '0' } }}
                    minWidth='5rem'
                    value={String(field.value)}
                    onChange={(value) => formik.setFieldValue(field.name, Number(value))}
                  />
                </Stack>
              )}
            </Field>

            <Field name='dimension'>
              {({ field }) => (
                <Stack>
                  <Text fontSize='xs'>{i18.t('trip-planning.label.dimension')}</Text>
                  <Select
                    options={dimensionOptions}
                    propsOf={{ button: { height: '2rem', pt: '0', pb: '0' } }}
                    minWidth='5rem'
                    value={String(field.value)}
                    onChange={(value) => formik.setFieldValue(field.name, Number(value))}
                  />
                </Stack>
              )}
            </Field>
          </Stack>
        </Stack>

        <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
          <Text fontSize='sm' fontWeight='600'>
            {i18.t('trip-planning.label.use-google')}
          </Text>

          <Stack direction='row'>
            <Field name='useGoogle'>{({ field }) => <Switch {...field} colorScheme='green' isChecked={field.value} />}</Field>
          </Stack>
        </Stack>

        <Stack spacing='0.75rem' paddingX='1.25rem' paddingY='1rem'>
          <Text fontSize='sm' fontWeight='600'>
            {i18.t('trip-planning.label.unit-fare')}
          </Text>

          <Stack direction='row'>
            <Field name='unitFare.thsr'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <FormLabelNumberInput
                    {...field}
                    label={i18.t('common.transit-mode.thsr')}
                    min={0}
                    precision={3}
                    step={0.1}
                    value={field.value}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isFinite(number) ? number : 0)}
                  />
                </FormControl>
              )}
            </Field>

            <Field name='unitFare.tra'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <FormLabelNumberInput
                    {...field}
                    label={i18.t('common.transit-mode.tra')}
                    min={0}
                    precision={3}
                    step={0.1}
                    value={field.value}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isFinite(number) ? number : 0)}
                  />
                </FormControl>
              )}
            </Field>
          </Stack>

          <Stack direction='row'>
            <Field name='unitFare.bus'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <FormLabelNumberInput
                    {...field}
                    label={i18.t('common.transit-mode.bus')}
                    min={0}
                    precision={3}
                    step={0.1}
                    value={field.value}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isFinite(number) ? number : 0)}
                  />
                </FormControl>
              )}
            </Field>

            <Field name='unitFare.mrt'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <FormLabelNumberInput
                    {...field}
                    label={i18.t('common.transit-mode.mrt')}
                    min={0}
                    precision={3}
                    step={0.1}
                    value={field.value}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isFinite(number) ? number : 0)}
                  />
                </FormControl>
              )}
            </Field>
          </Stack>

          <Stack direction='row'>
            <Field name='unitFare.lrt'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <FormLabelNumberInput
                    {...field}
                    label={i18.t('common.transit-mode.lrt')}
                    min={0}
                    precision={3}
                    step={0.1}
                    value={field.value}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isFinite(number) ? number : 0)}
                  />
                </FormControl>
              )}
            </Field>

            <Field name='unitFare.ferry'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <FormLabelNumberInput
                    {...field}
                    label={i18.t('common.transit-mode.ferry')}
                    min={0}
                    precision={3}
                    step={0.1}
                    value={field.value}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isFinite(number) ? number : 0)}
                  />
                </FormControl>
              )}
            </Field>
          </Stack>

          <Stack direction='row'>
            <Field name='unitFare.gondola'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <FormLabelNumberInput
                    {...field}
                    label={i18.t('common.transit-mode.gondola')}
                    min={0}
                    precision={3}
                    step={0.1}
                    value={field.value}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isFinite(number) ? number : 0)}
                  />
                </FormControl>
              )}
            </Field>

            <Field name='unitFare.airplane'>
              {({ field, meta }) => (
                <FormControl isInvalid={formikHelper.checkIsInvalid(meta)}>
                  <FormLabelNumberInput
                    {...field}
                    label={i18.t('common.transit-mode.airplane')}
                    min={0}
                    precision={3}
                    step={0.1}
                    value={field.value}
                    onChange={(_, number) => formik.setFieldValue(field.name, Number.isFinite(number) ? number : 0)}
                  />
                </FormControl>
              )}
            </Field>
          </Stack>
        </Stack>
      </Stack>
    ),
    [
      env.LOCATION,
      env.OPTION_EXTREMUM.TIME_COST_OF_NORMAL_TRAVEL,
      env.OPTION_EXTREMUM.TRANSIT_OF_TIME_COST,
      env.OPTION_EXTREMUM.TRANSIT_OF_FARE,
      env.OPTION_EXTREMUM.SPEED_OF_WALKING,
      env.OPTION_EXTREMUM.SPEED_OF_CYCLING,
      env.OPTION_EXTREMUM.SPEED_OF_DRIVING,
      env.OPTION_EXTREMUM.LABELING,
      env.OPTION_EXTREMUM.TIME_COST_OF_BUSINESS_TRAVEL,
      env.OPTION_EXTREMUM.TIME_COST_OF_LAST_MILE,
      formik,
      topOptions,
      dimensionOptions,
    ],
  )

  return env.VISIBLE_OPTION.ADVANCED ? (
    <Tabs isFitted overflow='hidden'>
      <TabList>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            paddingTop='1.25rem'
            lineHeight='1'
            fontSize='sm'
            _selected={{ fontWeight: '500', color: 'brand.500', borderColor: 'currentColor' }}
            _focus={{}}
          >
            {tab}
          </Tab>
        ))}
      </TabList>

      {/* height: 100% - tab-list-height */}
      <TabPanels height='calc(100% - 2.75rem)' overflow='hidden'>
        <TabPanel padding='0' height='100%' overflow='hidden'>
          {normal}
        </TabPanel>

        <TabPanel padding='0' height='100%' overflow='hidden'>
          {advanced}
        </TabPanel>
      </TabPanels>
    </Tabs>
  ) : (
    normal
  )
}

TripPlanningOption.propTypes = {
  formik: PropTypes.object,
}
