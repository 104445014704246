export const getEnv = () => {
  let pathname = window.location.pathname.slice(1)
  switch (pathname) {
    case '':
    case 'trip-planning/directions':
      pathname = 'taiwan'
      break
    case 'embedded/directions':
      pathname = 'embedded'
      break
    default:
      break
  }

  return window.env[pathname] ?? window.env.config
}
