import i18 from 'i18next'

import { FORMAT, LANG } from 'src/configs'
import { convertMeterToFeet, convertMeterToMile, convertMeterToKilometer } from 'src/libs/utils'

export const getDistanceWithUnit = (meter, decimalPlace = { feet: 0, mile: 1, meter: 0, km: 1 }) => {
  switch (i18.language) {
    case LANG.enUS: {
      const feet = convertMeterToFeet(meter)
      const mile = convertMeterToMile(meter)
      return feet > 1000 ? FORMAT.MILE(mile, decimalPlace.mile) : FORMAT.FEET(feet, decimalPlace.feet)
    }

    case LANG.zhTW:
    default: {
      const km = convertMeterToKilometer(meter)
      return meter > 1000 ? FORMAT.KILOMETER(km, decimalPlace.km) : FORMAT.METER(meter, decimalPlace.meter)
    }
  }
}
