import BigNumber from 'bignumber.js'
import i18 from 'i18next'

export const convertMeterToFeet = (meter) => meter * 3.2808398950131
export const convertMeterToMile = (meter) => meter * 0.000621371192
export const convertMeterToKilometer = (meter) => meter * 0.001

export const convertSecondsToUnits = (seconds) => {
  const moduloedSeconds = new BigNumber(seconds).modulo(60).toNumber()

  const dividedMinutes = new BigNumber(seconds).idiv(60).toNumber()
  const moduloedMinutes = new BigNumber(dividedMinutes).modulo(60).toNumber()

  const dividedHours = new BigNumber(dividedMinutes).idiv(60).toNumber()
  const moduloedHours = new BigNumber(dividedHours).modulo(24).toNumber()

  const dividedDays = new BigNumber(dividedHours).idiv(24).toNumber()

  const hours = dividedDays > 0 ? moduloedHours : dividedHours
  const minutes = dividedHours > 0 ? moduloedMinutes : dividedMinutes
  const roundUpMinutes = minutes === 0 && moduloedSeconds > 0 ? 1 : 0
  const roundedMinutes = minutes + roundUpMinutes
  const roundedDividedMinutes = dividedMinutes + roundUpMinutes

  return {
    moduloedSeconds,
    dividedMinutes,
    moduloedMinutes,
    dividedHours,
    moduloedHours,
    dividedDays,
    hours,
    minutes,
    roundUpMinutes,
    roundedMinutes,
    roundedDividedMinutes,
  }
}
export const convertSecondsToDuration = (
  seconds,
  {
    keys = {
      day: i18.t('common.unit.days-short'),
      hour: i18.t('common.unit.hours-short'),
      minutes: i18.t('common.unit.minutes-short'),
      seconds: i18.t('common.unit.seconds-short'),
    },
  } = {},
) => {
  const { dividedDays, hours, roundedMinutes } = convertSecondsToUnits(seconds)

  return {
    [keys.day]: dividedDays > 0 ? dividedDays : null,
    [keys.hour]: hours > 0 ? hours : null,
    // NOTE: it'd not display seconds in UI, so even there is 1~59 pruned seconds left, it should be added to 1 min
    [keys.minutes]: roundedMinutes > 0 ? roundedMinutes : null,
    // NOTE: dosn't display seconds in UI
    [keys.seconds]: null,
  }
}
