import React from 'react'
import { useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { isValid, getDay, format } from 'date-fns'
import { Flex, Text, Stack, CheckboxGroup, Checkbox, Divider, Collapse, Icon, Portal, useDisclosure, useBreakpointValue } from '@chakra-ui/react'
import { Loading, Icons, toast } from '@metropia/react-tools'
import useChange from '@react-hook/change'
import { useTimeout } from '@react-hook/timeout'
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp'
import qs from 'query-string'
import i18 from 'i18next'

import { FORMAT_OF_DATE } from 'src/configs'
import * as api from 'src/libs/api'
import { Position } from 'src/libs/models'
import { useCurrentLocation, useCurrentLocationInstance } from 'src/libs/hooks'
import { convertSecondsToDuration } from 'src/libs/utils'
import { Selector, RouteCard, RouteDetail, ProductCard, NavigationArrow, NAVIGATION_DIRECTION } from 'src/components'

import {
  Context,
  MAP_ICON,
  VIEW,
  MD_WIDTH,
  VIEWPORT_PADDING,
  LEAVE_TYPE_TEXT,
  SORT_OPTIONS,
  TRANSIT_MODE_TO_METROPIA_API,
  TRANSPORT_MODE,
  INITIAL_SORT,
} from 'src/views/trip-planning/constants'
import {
  isAtTaiwan,
  sortRoutes,
  getFromEncodedPolyline,
  getPolylineWithBorder,
  getTransitNotation,
  getInitialOption,
} from 'src/views/trip-planning/utils'

const now = new Date()
const then = new Date(now.setHours(5, 0, 0, 0))
then.setDate(then.getDate() - then.getDay() + 6)
const nearestSaturday = then < now ? new Date(then.setDate(then.getDate() + 7)) : then

// ?groupId=A002&productId=product001&vendor=ejwg9rgm12
function Marketing(props) {
  const [search, setSearch] = useSearchParams()
  const { env, mapRef, uiRef, mapCtrlContainerRef } = React.useContext(Context)

  const [isTimedOut, start] = useTimeout(env.TIMEOUT_MS)
  const { error: errorOfLocation } = useCurrentLocation()
  const currentLocation = useCurrentLocationInstance(3)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { isOpen: isProductsVisible, onOpen: openProducts, onToggle: toggleProducts } = useDisclosure({ defaultIsOpen: true })
  useChange(isMobile, openProducts)

  const parsedSearch = React.useMemo(() => qs.parse(search.toString()), [search])

  const [view, setView] = React.useState(VIEW.LIST)
  const [option] = React.useState(getInitialOption(env))
  const [sort, setSort] = React.useState(INITIAL_SORT)
  const [checkedIndexes, setCheckedIndexes] = React.useState([])
  const { isOpen: isDetailExtended, onToggle: toggleDetail } = useDisclosure({ defaultIsOpen: false })

  const {
    data: { data: station },
    error: errorOfStation,
  } = useSWR(
    // * 拒絕時 error 為 `User denied Geolocation` error
    // * 未決定時 error 為 null
    // - 使用者已經拒絕權限，使用IP定位
    // - 使用者還在考慮，但在 X 秒內拒絕權限，使用IP定位
    // - 使用者還在考慮，但在 X 秒內沒有決定，使用IP定位
    !Position.isValid(currentLocation) && typeof errorOfLocation !== 'undefined' && isTimedOut ? [currentLocation, '/station'] : null,
    (location) => api.fetchStation({ location }),
    { revalidateOnFocus: false, fallbackData: { data: undefined } },
  )

  const {
    data: { data: products },
    isValidating: isProductValidating,
  } = useSWR(
    parsedSearch.vendor && parsedSearch.groupId ? [parsedSearch.vendor, parsedSearch.groupId, '/products'] : null,
    (vendor, groupId) => api.fetchProductList({ vendor, groupId }),
    { revalidateOnFocus: false, fallbackData: { data: [] } },
  )

  const origin = React.useMemo(
    () =>
      // - valid: 已經給予座標權限，且使用者位置在台灣
      Position.isValid(currentLocation) && isAtTaiwan(currentLocation)
        ? currentLocation
        : // invalid: 使用GPS或IP定位尋找附近車站有內容，且使用者位置在台灣
        station && !errorOfStation && isAtTaiwan(currentLocation)
        ? new Position({ id: 'station', name: station.name, address: station.address, lat: station.latitude, lng: station.longitude })
        : // error: 拒絕提供坐標權限、GSP定位不在台灣、找不到車站（IP定位在國外）或錯誤發生時，使用地圖中心點作為起始點
          new Position(env.DEFAULT_MAP_OPTION.center),
    [env, currentLocation, station, errorOfStation],
  )
  const destination = React.useMemo(
    () => products.filter((product) => product.id === parsedSearch.productId)[0]?.destination ?? null,
    [products, parsedSearch.productId],
  )

  const {
    data: { data: routes },
    isValidating: isRouteValidating,
    error: routeError,
  } = useSWR(
    Position.isValid(origin) && Position.isValid(destination) ? [origin, destination, parsedSearch.productId, '/products'] : null,
    (origin, destination, productId) => {
      // NOTE: 最近的禮拜六早上五點出發 only for destination `太平山` and `小琉球`
      const isSpecial = parsedSearch.groupId === 'A002' && parsedSearch.vendor === 'ejwg9rgm12' && ['product001', 'product002'].includes(productId)
      const departureDate = isSpecial ? nearestSaturday : option.leaveType === metropia.LeaveType.LEAVE_NOW ? now : option.date
      const departureTime = isSpecial
        ? nearestSaturday
        : { [metropia.LeaveType.LEAVE_NOW]: new Date(), [metropia.LeaveType.DEPART_AT]: option.time }[option.leaveType] ?? null

      return api.fetchDirectionRoutesByMetropia({
        ...option,
        vendor: parsedSearch.vendor,
        groupId: parsedSearch.groupId,
        origin: Position.stringify(origin),
        originName: origin.name,
        originAddress: origin.address,
        destination: Position.stringify(destination),
        destinationName: destination.name,
        destinationAddress: destination.address,
        weekday: getDay(departureDate),
        departureDate,
        departureTime,
        arrivalTime: option.leaveType === metropia.LeaveType.ARRIVE_AT ? option.time : null,
        transitModes: option.preferredTransitModes.flatMap((mode) => TRANSIT_MODE_TO_METROPIA_API[mode]).filter(Boolean),
        minTransferSeconds: option.transferMinutesRange[0] * 60,
        maxTransferSeconds: option.transferMinutesRange[1] * 60,
      })
    },
    { revalidateOnFocus: false, shouldRetryOnError: false, fallbackData: { data: [] } },
  )

  const highlightedIndex = React.useMemo(() => checkedIndexes.slice(-1)[0] ?? null, [checkedIndexes])
  const sortedRoutes = React.useMemo(() => sortRoutes(sortRoutes(routes, sort.then), sort.first), [routes, sort])

  const selectProduct = React.useCallback(
    (product) => {
      setView(VIEW.LIST)
      setSearch({ ...parsedSearch, productId: product.id })
    },
    [setSearch, parsedSearch],
  )
  const onBack = React.useCallback(() => {
    switch (view) {
      case VIEW.DETAIL:
        setView(VIEW.LIST)
        break
    }
  }, [view])
  const onCheckboxChange = React.useCallback((stringIndexes) => setCheckedIndexes(stringIndexes.map(Number)), [])

  React.useEffect(() => {
    if (!Position.isValid(origin)) return

    const fetchRoutes = async () => {
      await Promise.all(
        products.map(({ id, destination }) => {
          // NOTE: 最近的禮拜六早上五點出發 only for destination `太平山` and `小琉球`
          const isSpecial = parsedSearch.groupId === 'A002' && parsedSearch.vendor === 'ejwg9rgm12' && ['product001', 'product002'].includes(id)
          const departureDate = isSpecial ? nearestSaturday : option.leaveType === metropia.LeaveType.LEAVE_NOW ? now : option.date
          const departureTime = isSpecial
            ? nearestSaturday
            : { [metropia.LeaveType.LEAVE_NOW]: new Date(), [metropia.LeaveType.DEPART_AT]: option.time }[option.leaveType] ?? null

          return api.fetchDirectionRoutesByMetropia({
            ...option,
            isBackground: true,
            vendor: parsedSearch.vendor,
            groupId: parsedSearch.groupId,
            origin: Position.stringify(origin),
            originName: origin.name,
            originAddress: origin.address,
            destination: Position.stringify(destination),
            destinationName: destination.name,
            destinationAddress: destination.address,
            weekday: getDay(departureDate),
            departureDate,
            departureTime,
            arrivalTime: option.leaveType === metropia.LeaveType.ARRIVE_AT ? option.time : null,
            transitModes: option.preferredTransitModes.flatMap((mode) => TRANSIT_MODE_TO_METROPIA_API[mode]).filter(Boolean),
            minTransferSeconds: option.transferMinutesRange[0] * 60,
            maxTransferSeconds: option.transferMinutesRange[1] * 60,
          })
        }),
      )
    }
    fetchRoutes()
  }, [origin, products, parsedSearch.vendor, parsedSearch.groupId, option])

  React.useEffect(() => start(), [start]) // call timer when mounted
  React.useEffect(() => {
    if (routeError) {
      toast({ status: 'warning', description: i18.t('warn.engine-busy') })
    }
    // NOTE: safari has error when click 'official-website' link
    // toast({ status: 'error', description: JSON.stringify(routeError) })
  }, [routeError])
  React.useEffect(() => {
    if (isProductValidating || isRouteValidating) return
    if (routes.length === 0) {
      setCheckedIndexes([])
    } else {
      setCheckedIndexes([0])
    }
  }, [isProductValidating, isRouteValidating, routes.length])

  React.useEffect(() => {
    if (sortedRoutes.length === 0 || checkedIndexes.length === 0) return

    const group = new H.map.Group()
    const [departure, arrival] = [origin, destination]
    const departureBubble = new H.ui.InfoBubble(departure, { content: departure.name })
    const arrivalBubble = new H.ui.InfoBubble(arrival, { content: arrival.name })
    arrivalBubble.addClass('arrival')
    const bubbles = [departureBubble, arrivalBubble]

    const marksAndLines = checkedIndexes.flatMap((checkedIndex) => {
      const { sections, seconds, source } = sortedRoutes[checkedIndex]
      const isHighlighted = checkedIndex === highlightedIndex

      const { lineStringsBySection, directionsBySection, center } = getFromEncodedPolyline(sections, source)
      const timingBubble = new H.ui.InfoBubble(center, { content: FORMAT_OF_DATE.DURATION(convertSecondsToDuration(seconds)) })
      timingBubble.addClass(`timing${isHighlighted ? ' is-highlighted' : ''}`)
      bubbles.push(timingBubble)

      return sections
        .flatMap((section, index) => {
          const { icon } = getTransitNotation(section, isHighlighted)
          const [departure, arrival] = directionsBySection[index]

          if (isHighlighted && section.type === metropia.TransportMode.TRANSIT) {
            const departureBubble = new H.ui.InfoBubble(departure, { content: section.departure.place.name })
            const arrivalBubble = new H.ui.InfoBubble(arrival, { content: section.arrival.place.name })
            bubbles.push(departureBubble, arrivalBubble)
          }

          return [new H.map.Marker(departure, { icon }), new H.map.Marker(arrival, { icon })]
        })
        .concat(
          sections.flatMap((section, index) => {
            const { color } = getTransitNotation(section, isHighlighted)
            const isDashed = isHighlighted && [...TRANSPORT_MODE.CYCLING.split(','), ...TRANSPORT_MODE.WALKING.split(',')].includes(section.type)
            const opacity = isHighlighted ? 0.9 : 0.6

            // 可能會遇到公車/台鐵/高鐵的 polyline 無法正確畫出中間站點路線圖的問題，所以直接將中間站點連起來(包含起訖點)
            return getPolylineWithBorder(lineStringsBySection[index], color, isDashed, opacity)
          }),
        )
        .concat(
          isHighlighted ? [new H.map.Marker(departure, { icon: MAP_ICON.DEPARTURE }), new H.map.Marker(arrival, { icon: MAP_ICON.ARRIVAL })] : [],
        )
        .map((object) => object.setData(checkedIndex))
    })
    const onTap = (event) => {
      const index = event.target.getData()
      if (highlightedIndex === index) return

      setCheckedIndexes([...checkedIndexes.filter((checkedIndex) => checkedIndex !== index), index]) // move new highlighted index to last to display on the map
    }

    const map = mapRef.current
    const ui = uiRef.current
    const viewPort = map.getViewPort()
    const isMobile = viewPort.width < MD_WIDTH
    if (isMobile) {
      viewPort.setPadding(...VIEWPORT_PADDING.MD)
    } else {
      viewPort.setPadding(...VIEWPORT_PADDING.LG)
    }

    bubbles.forEach((bubble) => ui.addBubble(bubble))
    group.addObjects(marksAndLines)
    group.addEventListener('tap', onTap, false)
    map.addObject(group)
    map.getViewModel().setLookAtData({ bounds: group.getBoundingBox() })

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      group.removeObjects(marksAndLines)
      group.removeEventListener('tap', onTap, false)
      map?.removeObject(group)
    }
  }, [mapRef, uiRef, origin, destination, sortedRoutes, checkedIndexes, highlightedIndex])

  // render highlighted bike marks
  React.useEffect(() => {
    if (sortedRoutes.length === 0 || highlightedIndex === null) return

    const bubbles = []
    const bikeMarkers = sortedRoutes[highlightedIndex].sections
      .filter((section) => TRANSPORT_MODE.CYCLING.includes(section.transport.mode) && typeof section.transport.agency === 'object')
      .flatMap(({ departure, arrival }) => {
        const directionBubble = [
          new H.ui.InfoBubble(departure.place.location, { content: departure.place.name }),
          new H.ui.InfoBubble(arrival.place.location, { content: arrival.place.name }),
        ]
        directionBubble.forEach((bubble) => bubble.addClass('station'))
        bubbles.push(...directionBubble)
        return [
          new H.map.Marker(departure.place.location, { icon: MAP_ICON.BIKE }),
          new H.map.Marker(arrival.place.location, { icon: MAP_ICON.BIKE }),
        ]
      })
      .map((marker, index) => marker.setData(index))

    const onEnter = (event) => bubbles[event.target.getData()].open()
    const onLeave = (event) => bubbles[event.target.getData()].close()

    const map = mapRef.current
    const ui = uiRef.current
    bubbles.forEach((bubble) => {
      bubble.close()
      ui.addBubble(bubble)
    })
    bikeMarkers.map((marker) => marker.addEventListener('pointerenter', onEnter, false))
    bikeMarkers.map((marker) => marker.addEventListener('pointerleave', onLeave, false))
    map.addObjects(bikeMarkers)

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      bikeMarkers.map((marker) => marker.removeEventListener('pointerenter', onEnter, false))
      bikeMarkers.map((marker) => marker.removeEventListener('pointerleave', onLeave, false))
      map?.removeObjects(bikeMarkers)
    }
  }, [mapRef, uiRef, sortedRoutes, highlightedIndex])

  // --- Product navigating ---
  const navigateProduct = React.useCallback((event) => {
    const elements = Array.from(document.querySelectorAll('[data-is-in-viewport]'))
    const visibles = Array.from(document.querySelectorAll('[data-is-in-viewport=true]'))
    if (event.currentTarget.dataset.direction === NAVIGATION_DIRECTION.PREV) {
      const firstVisibleIndex = elements.indexOf(visibles[0])
      const scrollToIndex = visibles.length > firstVisibleIndex ? 0 : firstVisibleIndex - visibles.length
      elements[scrollToIndex]?.scrollIntoView({ behavior: 'smooth' })
    } else if (event.currentTarget.dataset.direction === NAVIGATION_DIRECTION.NEXT) {
      const lastVisibleIndex = elements.indexOf(visibles.slice(-1)[0])
      const nextIndex = lastVisibleIndex + visibles.length
      const scrollToIndex = lastVisibleIndex < 0 || nextIndex > elements.length - 1 ? elements.length - 1 : nextIndex
      elements[scrollToIndex]?.scrollIntoView({ behavior: 'smooth', inline: 'end' })
    }
  }, [])
  const observerRef = React.useRef(
    // DOC OF OPTIONS: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
    new window.IntersectionObserver(
      (entries) => {
        // display or hide navigation
        const { parentElement: parent } = entries[0].target
        const navigations = document.querySelectorAll('[data-navigation]')
        if (navigations.length > 0 && parent !== null) {
          Object.assign(navigations[0].style, { display: parent.scrollLeft > 50 ? 'inline-flex' : 'none' })
          Object.assign(navigations[1].style, { display: parent.scrollWidth - parent.scrollLeft - parent.clientWidth > 50 ? 'inline-flex' : 'none' })
        }

        // navigation scrolling anchor
        entries.forEach((entry) => (entry.target.dataset.isInViewport = entry.intersectionRatio === 1))
      },
      { threshold: 1 },
    ),
  )
  React.useEffect(() => {
    if (products.length === 0) return
    document.querySelectorAll('[data-scroll-target]').forEach((element) => observerRef.current.observe(element))
  }, [products.length])
  React.useEffect(() => {
    const targetElement = document.querySelector(`[data-scroll-target=${parsedSearch.productId}`)
    if (products.length === 0 || !targetElement) return
    targetElement.scrollIntoView({ behavior: 'smooth' })
  }, [products.length, parsedSearch.productId])
  // --- Product navigating ---

  return (
    <Flex
      pointerEvents='none'
      zIndex='1'
      position='fixed'
      top={{ base: '1rem', md: '1.5rem' }}
      left={{ base: '0.5rem', md: '1rem' }}
      right={{ base: '0.5rem', md: 'auto' }}
      // NOTE: full height on list and detail view
      bottom='1rem'
      // parent-100% - top-distance - bottom-distance
      maxHeight={{ base: 'calc(100% - 1rem * 2)', md: 'calc(100% - 1.5rem - 1rem)' }}
    >
      <Stack flex='1' direction={{ base: 'column', md: 'row' }} align='flex-start'>
        {view === VIEW.DETAIL && <NavigationArrow direction={NAVIGATION_DIRECTION.PREV} onClick={onBack} />}

        <Flex
          width={{ base: `calc(${window.innerWidth}px - 1rem)`, md: '25.5rem' }}
          // NOTE: base: 100% for half down on list view, md: 100% for full height on list and detail view
          height='100%'
          // NOTE: base: (parent-100% - icon-height - margin-top(spacing of Stack parent)), md: (same as parent)
          maxHeight={{ base: view === VIEW.LIST ? '100%' : 'calc(100% - 2rem - 0.5rem)', md: '100%' }}
        >
          {view === VIEW.LIST ? (
            <Stack
              pointerEvents='auto'
              spacing='0'
              divider={<Divider borderColor='gray.300' />}
              width='inherit'
              mt={{ base: 'auto', md: '0' }}
              height={{ base: '16rem', md: 'auto' }}
              transform={{ base: 'translateY(1rem)', md: 'none' }}
              borderTopRadius='xl'
              borderBottomRadius={{ base: 'none', md: 'xl' }}
              bgColor='white'
              shadow='bold'
            >
              <Stack spacing='0' paddingX='1rem' paddingY='0.75rem' bgColor='gray.100' borderTopRadius='inherit'>
                <Flex>
                  <Text flexShrink='0' width='2.5rem' color='gray.500' fontSize='sm'>
                    {i18.t('common.from')}
                  </Text>
                  <Text fontSize='sm' fontWeight='600'>
                    {origin?.name}
                  </Text>
                </Flex>
                <Flex>
                  <Text flexShrink='0' width='2.5rem' color='gray.500' fontSize='sm'>
                    {i18.t('common.to')}
                  </Text>
                  <Text fontSize='sm' fontWeight='600'>
                    {destination?.name}
                  </Text>
                </Flex>

                <Stack direction='row' spacing='1rem' align='center'>
                  <Text flexShrink='0' fontSize='sm' fontWeight='600'>
                    {i18.t('trip-planning.label.sort-preference')}
                  </Text>

                  <Flex flexDirection='row' flexWrap='wrap' align='center'>
                    <Stack mr='0.5rem' direction='row' spacing='0.25rem' align='center'>
                      <Text flexShrink='0' color='gray.500' fontSize='sm'>
                        {i18.t('trip-planning.label.sort-preference-first')}
                      </Text>
                      <Selector
                        options={SORT_OPTIONS}
                        value={sort.first}
                        onChange={(first) => {
                          setSort({ ...sort, first })
                          setCheckedIndexes([0])
                        }}
                      />
                    </Stack>

                    <Stack direction='row' spacing='0.25rem' align='center'>
                      <Text flexShrink='0' color='gray.500' fontSize='sm'>
                        {i18.t('trip-planning.label.sort-preference-then')}
                      </Text>
                      <Selector
                        options={SORT_OPTIONS}
                        value={sort.then}
                        onChange={(then) => {
                          setSort({ ...sort, then })
                          setCheckedIndexes([0])
                        }}
                      />
                    </Stack>
                  </Flex>
                </Stack>

                <Stack direction='row' spacing='0.25rem' align='center'>
                  <Icon as={Icons.Clock} color='gray.500' boxSize='1.25rem' />
                  <Text color='gray.500' fontSize='sm'>
                    {[LEAVE_TYPE_TEXT[option.leaveType]]
                      .concat(
                        option.leaveType !== metropia.LeaveType.LEAVE_NOW && isValid(option.date) && isValid(option.time)
                          ? [
                              format(option.date, i18.t('format-pattern.date-picker.input'), { locale: window.datefns_locale }),
                              format(option.time, i18.t('format-pattern.time-picker.input'), { locale: window.datefns_locale }),
                            ]
                          : [],
                      )
                      .join(' ')}
                  </Text>
                </Stack>
              </Stack>

              <Flex paddingY='0.5rem' direction='column' align='flex-start' overflow='auto'>
                <CheckboxGroup colorScheme='green' value={checkedIndexes.map(String)} onChange={onCheckboxChange}>
                  <Stack spacing='0' divider={<Divider />} width='100%'>
                    {sortedRoutes.map((route, index) => (
                      <Stack
                        key={index}
                        direction='row'
                        spacing='1rem'
                        paddingX='1rem'
                        align='center'
                        bgColor={checkedIndexes.includes(index) ? 'brand.50' : 'transparent'}
                        aria-selected={index === highlightedIndex}
                        _hover={{ bgColor: 'gray.50' }}
                        _selected={{ bgColor: 'gray.300' }}
                      >
                        <Checkbox value={String(index)} isDisabled={checkedIndexes.length === 3 && !checkedIndexes.includes(index)} />

                        <RouteCard
                          useGoogle={option.useGoogle}
                          route={route}
                          flex='1'
                          paddingY='1rem'
                          onClick={() => {
                            setCheckedIndexes([index])
                            setView(VIEW.DETAIL)
                          }}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </CheckboxGroup>

                {routes.length === 0 && (
                  <Text paddingY='1rem' paddingX='1.5rem' fontSize='sm' color='gray.700'>
                    {isProductValidating || isRouteValidating ? i18.t('common.searching') : i18.t('trip-planning.empty-content')}
                  </Text>
                )}
              </Flex>

              {(isProductValidating || (isRouteValidating && routes.length === 0)) && <Loading pos='absolute' borderRadius='inherit' />}
            </Stack>
          ) : (
            view === VIEW.DETAIL && (
              <Flex
                pointerEvents='auto'
                pos='relative'
                mt={{ base: 'auto', md: '0' }}
                w='inherit'
                h={{ base: isDetailExtended ? '100%' : '8rem', md: 'auto' }}
                transform={{ base: isDetailExtended ? 'none' : 'translateY(1rem)', md: 'none' }}
                transitionDuration='400ms'
                transitionTimingFunction='ease-in-out'
                borderTopRadius='xl'
                borderBottomRadius={{ base: isDetailExtended ? 'xl' : 'none', md: 'xl' }}
                bgColor='white'
                shadow='bold'
              >
                <NavigationArrow
                  display={{ md: 'none' }}
                  pos='absolute'
                  top='0'
                  left='50%'
                  transform='translateX(-50%)'
                  shadow='none'
                  borderColor='transparent'
                  size='xs'
                  onClick={toggleDetail}
                  direction={isDetailExtended ? NAVIGATION_DIRECTION.DOWN : NAVIGATION_DIRECTION.UP}
                />
                <RouteDetail option={option} route={sortedRoutes[highlightedIndex]} directions={[origin, destination]} />
              </Flex>
            )
          )}
        </Flex>
      </Stack>

      <Portal containerRef={mapCtrlContainerRef}>
        <Flex
          direction='column'
          ml={{ base: 'auto', md: view === VIEW.LIST ? '26rem' : '28.5rem' }}
          mb={{ base: view === VIEW.DETAIL ? '8rem' : '16rem', md: 'auto' }}
          bgColor={{ base: isProductsVisible ? 'whiteAlpha.500' : 'transparent', md: 'transparent' }}
          backdropFilter={{ base: isProductsVisible ? 'blur(3px)' : 'none', md: 'none' }}
        >
          <Flex display={{ base: 'flex', md: 'none' }} pt='0.5rem' pb={isProductsVisible ? '0' : '0.5rem'} px='0.5rem'>
            <NavigationArrow onClick={toggleProducts} direction={isProductsVisible ? NAVIGATION_DIRECTION.DOWN : NAVIGATION_DIRECTION.UP} />
          </Flex>

          <Collapse in={isProductsVisible}>
            <Flex pointerEvents='auto' position='relative'>
              <NavigationArrow
                hidden={isMobile}
                onClick={navigateProduct}
                data-navigation
                direction={NAVIGATION_DIRECTION.PREV}
                zIndex='1'
                pos='absolute'
                left='1rem'
                top='50%'
                transform='translateY(-50%)'
              />
              <NavigationArrow
                hidden={isMobile}
                onClick={navigateProduct}
                data-navigation
                direction={NAVIGATION_DIRECTION.NEXT}
                zIndex='1'
                pos='absolute'
                right='1rem'
                top='50%'
                transform='translateY(-50%)'
              />

              <Stack
                direction='row'
                spacing='0.75rem'
                pl={{ base: '0.5rem', md: '1.25rem' }}
                pr={{ base: '0.5rem', md: '1rem' }}
                py='1rem'
                overflow='auto'
              >
                {products.length === 0 ? (
                  <Flex padding='2rem 3.75rem' bgColor='white' borderRadius='xl' shadow='bold'>
                    {i18.t('common.empty-content')}
                  </Flex>
                ) : (
                  products.map((product, index) => (
                    <Flex key={index} shrink='0' data-scroll-target={product.id}>
                      <ProductCard product={product} isChecked={product.id === parsedSearch.productId} onClick={() => selectProduct(product)} />
                    </Flex>
                  ))
                )}
              </Stack>
            </Flex>
          </Collapse>
        </Flex>
      </Portal>
    </Flex>
  )
}

export default Marketing
