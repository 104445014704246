import axios from 'axios'
import SHA from 'jssha'
import qs from 'query-string'
import { getEnv } from 'src/libs/utils'

export const routingService = axios.create({ baseURL: window.env.config.ROUTING_API_URL })
routingService.interceptors.response.use((response) => ({ ...response, data: response.data.data }))

const responseInterceptorOfFullfilled = (response) => {
  // first `data` is from axios, second `data` is from back-end
  const { error } = response?.data

  // network fine and status is 200 but response content error from back-end
  if (error) {
    return Promise.reject(error)
  }

  return response
}
const responseInterceptorOfRejected = (error) => {
  // network error
  return Promise.reject(error?.response?.data?.error ?? error)
}
export const tripService = axios.create()
tripService.interceptors.request.use((config) => {
  const env = getEnv()

  return {
    ...config,
    baseURL: env.TRIP_API_URL,
    // NOTE: apikey in params
    params: { ...config.params, apikey: env.TRIP_API_KEY },
    paramsSerializer: (params) => qs.stringify(params, { skipEmptyString: true, skipNull: true }),
  }
})
tripService.interceptors.response.use(responseInterceptorOfFullfilled, responseInterceptorOfRejected)

export const deeplinkService = axios.create()
deeplinkService.interceptors.request.use((config) => {
  const env = getEnv()

  return {
    ...config,
    baseURL: env.TRIP_API_URL,
    // NOTE: apikey in headers
    headers: { ...config.headers, apikey: env.TRIP_API_KEY },
    paramsSerializer: (params) => qs.stringify(params, { skipEmptyString: true, skipNull: true }),
  }
})
deeplinkService.interceptors.response.use(responseInterceptorOfFullfilled, responseInterceptorOfRejected)

export const tdxService = axios.create({ baseURL: window.env.config.TDX_API_URL })
tdxService.interceptors.request.use((config) => {
  const GMTString = new Date().toGMTString()
  const sha1 = new SHA('SHA-1', 'TEXT', { hmacKey: { value: window.env.config.TDX_API_KEY, format: 'TEXT' } })
  sha1.update(`x-date: ${GMTString}`)

  return {
    ...config,
    headers: {
      Authorization: `hmac username="${window.env.config.TDX_API_ID}", algorithm="hmac-sha1", headers="x-date", signature="${sha1.getHMAC('B64')}"`,
      'X-Date': GMTString,
    },
  }
})

// https://developers.google.com/maps/documentation/javascript/reference/places-service
export const placeService = () => new google.maps.places.PlacesService(window.googleMapInstanceRef)

// https://developers.google.com/maps/documentation/javascript/reference/directions
export const directionsService = () => new google.maps.DirectionsService()
