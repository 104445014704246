import React from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import { Flex, Icon } from '@chakra-ui/react'
import { Icons } from '@metropia/react-tools'
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp'

import { ReactComponent as MapMarkerPinRestaurant } from 'src/assets/images/map-marker-pin-restaurant.svg'
import { ReactComponent as MapMarkerPinHotel } from 'src/assets/images/map-marker-pin-hotel.svg'
import { ReactComponent as MapMarkerPinScenicSpot } from 'src/assets/images/map-marker-pin-scenic-spot.svg'
import { ReactComponent as MapMarkerPinCarpool } from 'src/assets/images/map-marker-pin-carpool.svg'
import { ReactComponent as MapMarkerPinYoubike } from 'src/assets/images/map-marker-pin-youbike.svg'
import { Position } from 'src/libs/models'

export { ReactComponent as TransportBikingSVG } from 'src/assets/images/transport-biking.svg'
export { ReactComponent as TransportRentalCarSVG } from 'src/assets/images/transport-rental-car.svg'
export { ReactComponent as TransportRentalScooterSVG } from 'src/assets/images/transport-rental-scooter.svg'

const MAP_MARKER_BY_TYPE = {
  [Position.Type.Restaurant]: MapMarkerPinRestaurant,
  [Position.Type.Hotel]: MapMarkerPinHotel,
  [Position.Type.ScenicSpot]: MapMarkerPinScenicSpot,
  [Position.Type.Place]: Icons.MapMarkerPin2,
  [Position.Type.Bike]: MapMarkerPinYoubike,
  [Position.Type.Carpool]: MapMarkerPinCarpool,
}
const COLOR_BY_TYPE = {
  [Position.Type.Restaurant]: '#1266c7',
  [Position.Type.Hotel]: '#1266c7',
  [Position.Type.ScenicSpot]: '#1266c7',
  [Position.Type.Place]: '#72809e',
  [Position.Type.Bike]: '#72809e',
  [Position.Type.Carpool]: '#72809e',
}

export const MAP_ICON = {
  MARK: new H.map.DomIcon(ReactDOMServer.renderToStaticMarkup(<Flex />), {
    onAttach: (clonedElement, domIcon, domMarker) => {
      ReactDOM.render(
        <Icon
          as={MAP_MARKER_BY_TYPE[domMarker.data.position.type]}
          cursor='pointer'
          onClick={domMarker.data.onClick}
          color={domMarker.data.isChecked ? COLOR_BY_TYPE[domMarker.data.position.type] : '#747F8A'}
          boxSize={domMarker.data.isChecked ? '3.75rem' : '2.5rem'}
          opacity={domMarker.data.isChecked ? 1 : 0.8}
          transform='translate(-50%, -100%)'
          style={{ filter: 'drop-shadow(0px 2px 2px #aaa)' }}
        />,
        clonedElement,
      )
    },
  }),

  MARK2: new H.map.DomIcon(ReactDOMServer.renderToStaticMarkup(<Flex />), {
    onAttach: (clonedElement, domIcon, domMarker) => {
      ReactDOM.render(
        <Flex
          pos='relative'
          direction='column'
          align='center'
          textAlign='center'
          onClick={domMarker.data.onClick}
          color='#ff3e1d'
          opacity={domMarker.data.isChecked ? '1' : '0.75'}
          transform={domMarker.data.isChecked ? 'scale(1.2) translate(-50%, -100%)' : 'translate(-50%, -100%)'}
        >
          <Flex
            cursor='pointer'
            lineHeight='1.25'
            fontSize='0.875rem'
            fontWeight={domMarker.data.isChecked ? '700' : '500'}
            maxWidth='8.75rem'
            noOfLines={2}
            textShadow='0 0 1px white, 0 0 2px white'
            style={{ filter: 'drop-shadow(0 0 1px white)' }}
          >
            {domMarker.data.position.name}
          </Flex>
          <Icon cursor='pointer' as={Icons.MapMarkerPin} boxSize='2rem' />
        </Flex>,
        clonedElement,
      )
    },
  }),

  DOT: new H.map.Icon(
    `<svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="4" fill="white" stroke="black" stroke-width="1" />
    </svg>`,
    { anchor: { x: 4, y: 4 } },
  ),

  DOT2: new H.map.Icon(
    `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="6" fill="white" stroke="black" stroke-width="1" />
    </svg>`,
    { anchor: { x: 6, y: 6 } },
  ),

  CURRENT: new H.map.Icon(
    ReactDOMServer.renderToStaticMarkup(
      <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
        {Icons.MapMarkerCircleBase2}
      </svg>,
    ),
    { anchor: { x: 16, y: 16 } },
  ),

  DEPARTURE: new H.map.Icon(
    ReactDOMServer.renderToStaticMarkup(
      <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' color='#0c9f2c'>
        {Icons.MapMarkerCircleBase}
      </svg>,
    ),
    { anchor: { x: 16, y: 16 } },
  ),

  DEPARTURE2: new H.map.DomIcon(ReactDOMServer.renderToStaticMarkup(<Flex />), {
    onAttach: (clonedElement, domIcon, domMarker) => {
      ReactDOM.render(
        <Flex pos='relative' direction='column' align='center' textAlign='center' color='#0c9f2c' transform='scale(1.2) translate(-50%, -100%)'>
          <Flex
            cursor='pointer'
            lineHeight='1.25'
            fontSize='0.875rem'
            fontWeight={domMarker.data.isChecked ? '700' : '500'}
            maxWidth='8.75rem'
            noOfLines={2}
            textShadow='0 0 1px white, 0 0 2px white'
            style={{ filter: 'drop-shadow(0 0 1px white)' }}
          >
            {domMarker.data.position.name}
          </Flex>
          <Icon cursor='pointer' as={Icons.MapMarkerCircle} boxSize='2rem' />
        </Flex>,
        clonedElement,
      )
    },
  }),

  ARRIVAL: new H.map.Icon(
    ReactDOMServer.renderToStaticMarkup(
      <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' color='#fd670a'>
        {Icons.MapMarkerPinBase2}
      </svg>,
    ),
  ),

  BUS: new H.map.Icon(
    `<svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <circle cx="20" cy="20" r="15.5" fill="#fff"/>
        <circle cx="20" cy="20" r="13.5" fill="#0C9F2C"/>
        <circle cx="20" cy="20" r="10" fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="m15.214 16.545.151-1.48c.056-.546.544-1.048 1.096-1.121 0 0 2.532-.36 3.54-.36 1.007 0 3.539.36 3.539.36.549.073 1.039.571 1.095 1.12l.151 1.481h.502c.14 0 .254.106.254.242v1.49a.245.245 0 0 1-.254.242h-.3l.025.25c.014.134.025.357.025.49v5.182a.5.5 0 0 1-.5.495h-9.076a.5.5 0 0 1-.5-.495V19.26c0-.136.012-.36.025-.492l.026-.249h-.3a.247.247 0 0 1-.254-.242v-1.49c0-.134.107-.242.254-.242h.501zm9.756 8.637a.48.48 0 0 1 .068.247v.495a.498.498 0 0 1-.509.493h-.998a.5.5 0 0 1-.508-.493v-.494h1.515c.182 0 .345-.1.432-.248zm-9.94 0a.483.483 0 0 0-.068.247v.495a.5.5 0 0 0 .509.493h.998c.285 0 .508-.22.508-.493v-.494h-1.515a.501.501 0 0 1-.433-.247zm.94-2.714c0-.273.216-.44.488-.374l1.04.255c.269.066.487.338.487.613a.498.498 0 0 1-.508.493h-.999a.5.5 0 0 1-.508-.493v-.494zm6.045.494c0-.273.216-.547.488-.613l1.04-.255c.27-.066.488.099.488.374v.494a.498.498 0 0 1-.509.493h-.998a.5.5 0 0 1-.509-.493zm-5.821-5.925a.535.535 0 0 1 .535-.492h6.542c.28 0 .52.22.535.492l.21 3.705a.233.233 0 0 1-.236.245h-7.56a.231.231 0 0 1-.236-.245l.21-3.705zm1.287-1.48a.5.5 0 0 1 .502-.493h4.035c.277 0 .501.22.501.494a.5.5 0 0 1-.501.493h-4.035a.496.496 0 0 1-.502-.493z" fill="#323848"/>
      </g>
      <defs>
        <filter id="a" x="-3" y="-1" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>`,
    { anchor: { x: 20, y: 20 } },
  ),

  TRAIN: new H.map.Icon(
    `<svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <circle cx="20" cy="20" r="15.5" fill="#fff"/>
        <circle cx="20" cy="20" r="13.5" fill="#0C9F2C"/>
        <circle cx="20" cy="20" r="10" fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.396 13.817a3 3 0 0 0-3 3v4.236a3 3 0 0 0 1.624 2.667l-.06.062-2.041 2.174c-.236.25-.223.453.027.453h10.109c.249 0 .262-.203.027-.453l-2.017-2.148a3 3 0 0 0 1.81-2.755v-4.236a3 3 0 0 0-3-3h-3.479zm-.743 8.606a.723.723 0 0 0 .722-.725.723.723 0 1 0-1.444 0c0 .4.323.725.722.725zm5.597-.725c0 .4-.323.725-.722.725a.723.723 0 0 1-.722-.725.723.723 0 1 1 1.444 0zm-4.331-7.156a.453.453 0 1 0 0 .905h2.343a.453.453 0 1 0 0-.905H18.92zm-2.89 3.118c0-.922.747-1.669 1.668-1.669h4.787c.922 0 1.669.747 1.669 1.669v.467c0 .922-.747 1.669-1.67 1.669h-4.786a1.669 1.669 0 0 1-1.669-1.67v-.466zm.747 7.662h6.63c.13 0 .35.122.491.271.141.15.135.272-.014.272h-7.584c-.15 0-.156-.122-.015-.272.141-.15.361-.271.492-.271zm5.703-.997h-4.687c-.124 0-.346.122-.497.272-.151.15-.152.272-.002.272h5.685c.15 0 .15-.122-.002-.272-.15-.15-.373-.272-.497-.272z" fill="#323848"/>
      </g>
      <defs>
        <filter id="a" x="-3" y="-1" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>`,
    { anchor: { x: 20, y: 20 } },
  ),

  SUBWAY: new H.map.Icon(
    `<svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <circle cx="20" cy="20" r="15.5" fill="#fff"/>
        <circle cx="20" cy="20" r="13.5" fill="#0C9F2C"/>
        <circle cx="20" cy="20" r="10" fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.396 13.817a3 3 0 0 0-3 3v4.236a3 3 0 0 0 1.624 2.667l-.06.062-2.041 2.174c-.236.25-.223.453.027.453h10.109c.249 0 .262-.203.027-.453l-2.017-2.148a3 3 0 0 0 1.81-2.755v-4.236a3 3 0 0 0-3-3h-3.479zm-1.368 1.59a1 1 0 0 0-1 1v2.667a1 1 0 0 0 1 1h2.292a.5.5 0 0 0 .5-.5v-3.666a.5.5 0 0 0-.5-.5h-2.292zm3.439.5a.5.5 0 0 1 .5-.5h2.291a1 1 0 0 1 1 1v2.667a1 1 0 0 1-1 1h-2.291a.5.5 0 0 1-.5-.5v-3.666zm-3.691 9.415h6.63c.13 0 .35.122.491.271.141.15.135.272-.014.272h-7.584c-.15 0-.156-.122-.015-.272.141-.15.361-.271.492-.271zm5.703-.997h-4.687c-.124 0-.346.122-.497.272-.151.15-.152.272-.002.272h5.685c.15 0 .15-.122-.002-.272-.15-.15-.373-.272-.497-.272zM16.5 21.896c0-.08.065-.146.146-.146h1.987c.11 0 .2.09.2.2v.475a.2.2 0 0 1-.2.2H17.23a.73.73 0 0 1-.729-.729zm5.158-.146a.2.2 0 0 0-.2.2v.475c0 .11.09.2.2.2h1.404a.73.73 0 0 0 .73-.729.146.146 0 0 0-.146-.146h-1.988z" fill="#323848"/>
      </g>
      <defs>
        <filter id="a" x="-3" y="-1" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>`,
    { anchor: { x: 20, y: 20 } },
  ),

  BIKE: new H.map.Icon(
    `<svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <circle cx="20" cy="20" r="15.5" fill="#fff"/>
        <circle cx="20" cy="20" r="13.5" fill="#0C9F2C"/>
        <circle cx="20" cy="20" r="10" fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="m21.289 16.69 3.052 5.798v.001a.292.292 0 0 1-.515.273v-.001l-1.542-2.91-2.347 2.955a.292.292 0 0 1-.229.11h-3.791a.292.292 0 0 1-.22-.483l2.234-2.553.439.384-1.81 2.07h3.008l2.425-3.055-1.22-2.318.516-.272z" fill="#323848"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.042 18.25H23.5v.583h-1.458v-.583zM19.708 16.792c0-.161.13-.292.292-.292h2.333a.292.292 0 0 1 0 .583H20a.292.292 0 0 1-.292-.291zM17.958 19.708h-3.5a.292.292 0 1 1 0-.583h3.5v.583zM16.208 18.25c0-.161.13-.292.292-.292h1.458a.292.292 0 0 1 0 .584H16.5a.292.292 0 0 1-.292-.292zM18.644 22.468c0-1.376-1.132-2.491-2.53-2.491s-2.53 1.114-2.53 2.491c0 1.376 1.132 2.491 2.53 2.491s2.53-1.114 2.53-2.491zm-4.338 0c0-.983.81-1.78 1.808-1.78s1.808.797 1.808 1.78-.81 1.78-1.808 1.78-1.808-.797-1.808-1.78z" fill="#323848"/>
        <path d="M25.25 19.7c0-.13.137-.215.253-.157l.817.409c.06.03.097.09.097.156v4.967a.175.175 0 0 1-.175.175h-.817a.175.175 0 0 1-.175-.175V19.7zM24.667 20.183a.113.113 0 0 0-.082-.11 2.57 2.57 0 0 0-.699-.096c-1.398 0-2.53 1.114-2.53 2.491 0 1.376 1.132 2.491 2.53 2.491.243 0 .477-.033.699-.096a.113.113 0 0 0 .082-.11v-.495c0-.086-.09-.143-.17-.114a1.83 1.83 0 0 1-.61.104c-.999 0-1.809-.797-1.809-1.78s.81-1.78 1.808-1.78c.214 0 .42.037.61.104.081.029.17-.028.17-.114v-.495zM23.02 17.746a.292.292 0 0 1 .282-.371h1.855c.193 0 .333.185.28.37l-.492 1.75a.292.292 0 0 1-.28.213h-.871a.292.292 0 0 1-.28-.212l-.493-1.75z" fill="#323848"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="m17.483 18.144 2.479 4.336a.292.292 0 0 1-.507.29l-2.478-4.337.506-.29z" fill="#323848"/>
      </g>
      <defs>
        <filter id="a" x="-3" y="-1" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>`,
    { anchor: { x: 20, y: 20 } },
  ),

  RENTAL_SCOOTER: new H.map.Icon(
    `<svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <circle cx="20" cy="20" r="15.5" fill="#fff"/>
        <circle cx="20" cy="20" r="13.5" fill="#0C9F2C"/>
        <circle cx="20" cy="20" r="10" fill="#fff"/>
        <path fill="#323848" d="M16.51 15.31h2.174a1.32 1.32 0 0 1 1.278-1.018c.617 0 1.14.432 1.28 1.018h2.172c.17 0 .308.138.308.308a.31.31 0 0 1-.308.308h-2.173c-.061.262-.2.493-.385.662l1.972.555v4.977s.124 1.68-1.648 1.695v-1.11a1.21 1.21 0 0 0-1.218-1.217 1.21 1.21 0 0 0-1.217 1.218v1.11c-1.772-.016-1.649-1.696-1.649-1.696v-4.977l1.973-.54c-.185-.169-.324-.4-.385-.662H16.51a.309.309 0 0 1-.308-.308.304.304 0 0 1 .308-.324zm3.452-.432a.74.74 0 0 0-.74.74c0 .4.324.755.74.755a.74.74 0 0 0 .74-.74c0-.4-.324-.755-.74-.755z" />
        <path fill="#323848" d="M19.963 21.75a.96.96 0 0 1 .955.956v2.172a.96.96 0 0 1-.955.956.96.96 0 0 1-.956-.956v-2.172a.96.96 0 0 1 .956-.956z" />
      </g>
      <defs>
        <filter id="a" x="-3" y="-1" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>`,
    { anchor: { x: 20, y: 20 } },
  ),

  RENTAL_CAR: new H.map.Icon(
    `<svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#a)">
        <circle cx="20" cy="20" r="15.5" fill="#fff"/>
        <circle cx="20" cy="20" r="13.5" fill="#0C9F2C"/>
        <circle cx="20" cy="20" r="10" fill="#fff"/>
        <path fill="#323848" fill-rule="evenodd" clip-rule="evenodd" d="M22.39 17.084c.747 0 1.527.597 1.74 1.33l.288.99h.9c.124 0 .224.099.224.234v.46c0 .129-.097.234-.223.234h-.632l.018.062c.026.088.042.174.05.258.475.334.787.898.787 1.54v3.71c0 .255-.2.462-.454.462h-.891a.46.46 0 0 1-.454-.462v-.466h1.124a.684.684 0 0 0 .675-.695v-1.274.81c0 .384-.3.695-.675.695h-9.442a.686.686 0 0 1-.675-.695v-.81 1.274c0 .383.302.695.675.695h1.124v.466c0 .255-.2.462-.454.462h-.891a.46.46 0 0 1-.454-.462v-3.71c0-.64.312-1.205.787-1.54a1.36 1.36 0 0 1 .05-.258l.017-.062h-.631c-.123 0-.223-.1-.223-.235v-.46c0-.129.097-.233.223-.233h.901l.288-.99c.213-.735.988-1.33 1.74-1.33H22.39zm-6.291 5.567c0-.256.2-.463.454-.463h.89c.251 0 .455.205.455.463v.465c0 .256-.2.464-.454.464h-.891a.457.457 0 0 1-.454-.464v-.465zm6.295 0c0-.256.2-.463.454-.463h.89c.252 0 .455.205.455.463v.465c0 .256-.2.464-.454.464h-.891a.457.457 0 0 1-.454-.464v-.465zm-5.334-3.965c.09-.373.461-.674.834-.674h4.504c.37 0 .743.297.834.674l.511 2.11H16.55l.51-2.11z" />
        <path fill="#323848" d="M17.561 13.584c.758 0 1.407.519 1.602 1.233l4.337.014c.54 0 .54.808 0 .808l-1.167-.014v.512c0 .563-.875.52-.875 0v-.512l-2.295.014a1.644 1.644 0 0 1-1.602 1.234 1.644 1.644 0 1 1 0-3.29zm0 .822a.822.822 0 1 0 0 1.644.822.822 0 0 0 0-1.644z" />
      </g>
      <defs>
        <filter id="a" x="-3" y="-1" width="46" height="46" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>`,
    { anchor: { x: 20, y: 20 } },
  ),
}
