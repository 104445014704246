/* eslint-disable camelcase */
import { Position } from './position'

export class Product {
  constructor(props) {
    const { id = null, name = '', description = '', logoSrc = '', imageSrc = '', href = '', destination = null } = props ?? {}

    Object.assign(this, {
      id,
      name,
      description,
      logoSrc,
      imageSrc,
      href,
      destination: new Position(destination),
    })
  }

  static mapKeysFromApi(props) {
    const { d_name, d_address, d_latitude, d_longitude, vendor_img, product_img, link, product_id, sub_title, title } = props || {}

    return {
      id: product_id,
      name: title,
      description: sub_title,
      logoSrc: vendor_img,
      imageSrc: product_img,
      href: link,
      destination: {
        id: d_name,
        name: d_name,
        address: d_address,
        lat: d_latitude,
        lng: d_longitude,
      },
    }
  }
}

export class Pharmacy {
  constructor(props) {
    const {
      id = null,
      name = '',
      description = '',
      products = [],
      phoneNumber = '',
      distance = null,
      duration = null,
      isNearby = null,
      destination = null,
      remark = '',
    } = props ?? {}

    Object.assign(this, {
      id,
      name,
      description,
      products,
      phoneNumber,
      distance,
      duration,
      isNearby,
      destination: new Position(destination),
      remark,
    })
  }

  static mapKeysFromApi(props) {
    const {
      pharmacy_id,
      name,
      address,
      products,
      lat,
      lng,
      tel,
      distance,
      duration,
      is_close,
      remark,
      // created_at,
      // updated_at,
    } = props || {}

    return {
      id: pharmacy_id,
      name,
      description: address,
      products: products?.map((product) => ({ name: product.name, quantity: product.quantity })),
      phoneNumber: tel,
      distance,
      duration,
      isNearby: is_close,
      destination: {
        id: pharmacy_id,
        name,
        address,
        lat,
        lng,
      },
      remark,
    }
  }
}
