import { Position } from './position'

/* eslint-disable camelcase */
export class Group {
  constructor(props) {
    const { id = null, name = '', positions = [] } = props ?? {}

    Object.assign(this, {
      id,
      name,
      positions: positions.map((position) => new Position(position)),
    })
  }

  static mapKeysFromApi(props) {
    const { group_detail, products } = props ?? {}

    return {
      id: group_detail.group_id,
      name: group_detail.title,
      positions: products?.map((product) => Position.mapKeysFromApi(product)),
    }
  }

  static mapKeysFromApiByKeyword(props) {
    const { group_id, title } = props ?? {}

    return {
      id: group_id,
      name: title,
    }
  }
}
