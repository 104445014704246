import i18 from 'i18next'
import axios from 'axios'
import { toast } from '@metropia/react-tools'
import { tripService, placeService, tdxService } from 'src/libs/api/_service'
import {
  Position,
  BikePosition,
  CarpoolPosition,
  ScenicSpotPosition,
  HotelPosition,
  RestaurantPosition,
  Group,
  Product,
  Pharmacy,
} from 'src/libs/models'
import { checkIsImageAvailable } from 'src/libs/utils'

export const fetchPositionListByAddress = ({ address }) => {
  return new Promise((resolve, reject) => {
    // https://developers.google.com/maps/documentation/javascript/reference/geocoder
    new google.maps.Geocoder().geocode({ address, region: 'TW' }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK || status === google.maps.GeocoderStatus.ZERO_RESULTS) {
        resolve(results)
      } else {
        reject(status)
      }
    })
  }).then((response) => ({
    data:
      response?.map(
        (data) =>
          new Position({
            id: data.place_id,
            name: data.formatted_address,
            address: data.formatted_address,
            lat: data.geometry.location.lat(),
            lng: data.geometry.location.lng(),
          }),
      ) ?? [],
    total: response?.length ?? 0,
  }))
}

export const fetchPositionListByQuery = ({ query, location }) => {
  return new Promise((resolve, reject) => {
    placeService().textSearch({ query, radius: 50000, location }, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK || status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve(results)
      } else {
        reject(status)
      }
    })
  }).then((response) => ({
    data: (
      response?.map(
        (data) =>
          new Position({
            id: data.place_id,
            name: data.name,
            address: data.formatted_address,
            lat: data.geometry.location.lat(),
            lng: data.geometry.location.lng(),
            googleTypes: data.types,
          }),
      ) ?? []
    )
      // DOC: https://developers.google.com/maps/documentation/places/web-service/supported_types?hl=zh-tw
      // NOTE: make these types to front of list
      .sort((position) => (position.googleTypes.some((type) => Position.GoogleStationTypes.includes(type)) ? -1 : 1)),
    total: response?.length ?? 0,
  }))
}

export const fetchPositionListByKeyword = ({ keyword, radius = 50000, location }) => {
  return new Promise((resolve, reject) => {
    if (!Position.isValid(location)) {
      toast({ status: 'error', description: i18.t('error.unauthenticated-location') })
      return reject(new Error(i18.t('error.unauthenticated-location')))
    }
    placeService().nearbySearch({ keyword, radius, location, rankBy: google.maps.places.RankBy.PROMINENCE }, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK || status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve(results)
      } else {
        reject(status)
      }
    })
  }).then((response) => ({
    data: response.map(
      (data) =>
        new Position({
          id: data.place_id,
          name: data.name,
          address: data.vicinity,
          lat: data.geometry.location.lat(),
          lng: data.geometry.location.lng(),
        }),
    ),
  }))
}

export const fetchPositionListByType = ({ lang = i18.language, radius = 50000, location, type }) => {
  return tripService({
    method: 'GET',
    url: '/lbs',
    params: {
      location: Position.stringify(location),
      range: radius,
      type,
    },
  }).then((response) => {
    const [{ list = [] } = {}] = response.data.data
    let data = []

    switch (type) {
      case Position.Type.Bike:
        data = list.map((data) => new BikePosition(BikePosition.mapKeysFromApi({ lang, ...data })))
        break
      case Position.Type.Carpool:
        data = list.flatMap((data) => data.Drivers.map((driver) => new CarpoolPosition(CarpoolPosition.mapKeysFromApi({ lang, ...data, ...driver }))))
        break
    }

    return { ...response, data }
  })
}

export const fetchScenicSpots = async ({ location, radius } = {}) => {
  const response = await tdxService({
    method: 'GET',
    url: '/v2/Tourism/ScenicSpot',
    params: {
      $format: 'JSON',
      $top: 30,
      $spatialFilter: `nearby(${location.lat},${location.lng},${radius})`,
    },
  })
  const spots = await (response.data?.map((data) => new ScenicSpotPosition(ScenicSpotPosition.mapKeysFromApi({ center: location, ...data }))) ?? [])
  const availables = await Promise.all(spots.map(async (spot) => await Promise.all(spot.pictures.map((url) => checkIsImageAvailable(url)))))

  return {
    ...response,
    // only render with pictures
    data: spots.filter((_, index) => availables[index].length > 0 && availables[index].every((available) => available)),
  }
}

export const fetchHotels = async ({ location, radius } = {}) => {
  const response = await tdxService({
    method: 'GET',
    url: '/v2/Tourism/Hotel',
    params: {
      $format: 'JSON',
      $top: 30,
      $spatialFilter: `nearby(${location.lat},${location.lng},${radius})`,
    },
  })
  const spots = await (response.data?.map((data) => new HotelPosition(HotelPosition.mapKeysFromApi({ center: location, ...data }))) ?? [])
  const availables = await Promise.all(spots.map(async (spot) => await Promise.all(spot.pictures.map((url) => checkIsImageAvailable(url)))))

  return {
    ...response,
    // only render with pictures
    data: spots.filter((_, index) => availables[index].length > 0 && availables[index].every((available) => available)),
  }
}

export const fetchRestaurants = async ({ location, radius } = {}) => {
  const response = await tdxService({
    method: 'GET',
    url: '/v2/Tourism/Restaurant',
    params: {
      $format: 'JSON',
      $top: 30,
      $spatialFilter: `nearby(${location.lat},${location.lng},${radius})`,
    },
  })
  const spots = await (response.data?.map((data) => new RestaurantPosition(RestaurantPosition.mapKeysFromApi({ center: location, ...data }))) ?? [])
  const availables = await Promise.all(spots.map(async (spot) => await Promise.all(spot.pictures.map((url) => checkIsImageAvailable(url)))))

  return {
    ...response,
    // only render with pictures
    data: spots.filter((_, index) => availables[index].length > 0 && availables[index].every((available) => available)),
  }
}

export const fetchStation = async ({ location }) => {
  const {
    data: { ip },
  } = await axios.get('https://api.ipify.org?format=json')

  return await tripService({
    method: 'GET',
    url: '/station',
    params: {
      ip,
      lat: location.lat,
      lng: location.lng,
    },
  }).then((response) => ({
    ...response,
    data: response.data.data?.station,
  }))
}

export const fetchProductList = ({ vendor, groupId, productId }) => {
  return tripService({
    method: 'GET',
    url: '/travel/products',
    params: {
      vendor,
      group_id: groupId,
      // product_id: productId,
    },
  }).then((response) => ({
    ...response,
    data: response.data?.products?.map((data) => new Product(Product.mapKeysFromApi(data))) ?? [],
  }))
}

export const fetchPharmacyList = ({ lat, lng }) => {
  return tripService({
    method: 'GET',
    url: '/pharmacys',
    params: {
      lat,
      lng,
    },
  }).then((response) => ({
    ...response,
    data: response.data?.data?.map((data) => new Pharmacy(Pharmacy.mapKeysFromApi(data))) ?? [],
  }))
}

export const fetchGroup = async ({ id } = {}) => {
  return tripService({
    method: 'GET',
    url: `/travel/groups/${id}`,
  }).then((response) => {
    return response.data.data?.group_detail
      ? { ...response, data: new Group(Group.mapKeysFromApi(response.data.data)) }
      : Promise.reject(new Error(`Can't find group by id: ${id}`))
  })
}

export const fetchGroupsByKeyword = async ({ keyword, vendor, page, size } = {}) => {
  return tripService({
    method: 'GET',
    url: `/travel/groups`,
    params: {
      vendor_code: vendor,
      title: keyword,
      page_number: page,
      page_limit: size,
    },
  }).then((response) => ({
    ...response,
    data: response.data.data?.results?.map((data) => new Group(Group.mapKeysFromApiByKeyword(data))) ?? [],
    total: response.data.data?.total ?? 0,
  }))
}

export const createGroup = async ({ name, vendor } = {}) => {
  return tripService({
    method: 'POST',
    url: `/travel/groups`,
    params: {
      vendor_code: vendor,
    },
    data: {
      title: name,
    },
  }).then((response) => ({ ...response, data: response.data.data?.group_id }))
}

export const updateGroup = async ({ id, name, vendor } = {}) => {
  return tripService({
    method: 'PUT',
    url: `/travel/groups/${id}`,
    params: {
      vendor_code: vendor,
    },
    data: {
      title: name,
    },
  })
}

export const deleteGroup = async ({ id, vendor } = {}) => {
  return tripService({
    method: 'DELETE',
    url: `/travel/groups/${id}`,
    params: {
      vendor_code: vendor,
    },
  })
}

const mapPositionKeysToApi = (positions, index) => ({
  ...(positions.id ? { product_id: positions.id } : null),
  destination_name: positions.name,
  destination_latitude: positions.lat,
  destination_longitude: positions.lng,
  destination_address: positions.address,
  sequence_index: index,
})
export const createPositions = async ({ vendor, groupId, positions } = {}) => {
  return tripService({
    method: 'POST',
    url: `/travel/batch/products`,
    params: {
      vendor_code: vendor,
    },
    data: {
      group_id: groupId,
      // NOTE: remove id to avoid back-end error when create
      products: positions.map((position) => ({ ...position, id: null })).map(mapPositionKeysToApi),
    },
  })
}

export const updatePositions = async ({ vendor, groupId, positions } = {}) => {
  return tripService({
    method: 'PUT',
    url: `/travel/batch/products`,
    params: {
      vendor_code: vendor,
    },
    data: {
      group_id: groupId,
      products: positions.map(mapPositionKeysToApi),
    },
  })
}

export const deletePositions = async ({ vendor, groupId, positions } = {}) => {
  return tripService({
    method: 'DELETE',
    url: `/travel/batch/products`,
    params: {
      group_id: groupId,
      vendor_code: vendor,
      product_id: positions.map((position) => position.id),
    },
  })
}
