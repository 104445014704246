import React from 'react'
import PropTypes from 'prop-types'
import { Icon, IconButton } from '@chakra-ui/react'

import FiChevronLeft from '@meronex/icons/fi/FiChevronLeft'
import FiChevronRight from '@meronex/icons/fi/FiChevronRight'
import FiChevronUp from '@meronex/icons/fi/FiChevronUp'
import FiChevronDown from '@meronex/icons/fi/FiChevronDown'

export const NAVIGATION_DIRECTION = {
  PREV: 'prev',
  NEXT: 'next',
  UP: 'up',
  DOWN: 'down',
}

const DIRECTION_ICON = {
  [NAVIGATION_DIRECTION.PREV]: FiChevronLeft,
  [NAVIGATION_DIRECTION.NEXT]: FiChevronRight,
  [NAVIGATION_DIRECTION.UP]: FiChevronUp,
  [NAVIGATION_DIRECTION.DOWN]: FiChevronDown,
}

export const NavigationArrow = React.forwardRef(({ direction, ...rest }, ref) => {
  return (
    <IconButton
      ref={ref}
      pointerEvents='auto'
      flexShrink='0'
      icon={<Icon as={DIRECTION_ICON[direction]} boxSize='1.25rem' />}
      variant='outline'
      size='sm'
      borderRadius='full'
      bgColor='white'
      shadow='bold'
      data-direction={direction}
      {...rest}
    />
  )
})

NavigationArrow.propTypes = {
  direction: PropTypes.oneOf(Object.values(NAVIGATION_DIRECTION)),
}
