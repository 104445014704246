// https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/components/radio.ts
export const Radio = {
  baseStyle: (props) => ({
    label: {
      lineHeight: 1,
      color: 'gray.700',
      _checked: { color: 'gray.900' },
    },
    control: {
      cursor: 'pointer',
      bgColor: 'white',
    },
  }),
}
