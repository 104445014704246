import * as Yup from 'yup'

export default Yup.object({
  name: Yup.string()
    .max(255, ('embedded.error.invalid-length-position-name', { max: 255 }))
    .required('embedded.group-name-placeholder'),
  positions: Yup.array(
    Yup.object({
      name: Yup.string()
        .max(255, ('embedded.error.invalid-length-position-name', { max: 255 }))
        .required('embedded.position-name-placeholder'),
      address: Yup.string().required('embedded.position-address-placeholder'),
      lat: Yup.number().nullable().required(),
      lng: Yup.number().nullable().required(),
    }),
  ).min(1),
})
