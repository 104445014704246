import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Flex, Button, Link, ButtonGroup, Text, Tooltip, Divider, Image, useDisclosure } from '@chakra-ui/react'
import i18 from 'i18next'

import { Product, Pharmacy } from 'src/libs/models'

export const ProductCard = React.forwardRef(({ product, isChecked, ...rest }, ref) => {
  return (
    <Stack
      ref={ref}
      direction='row'
      p='0.5rem'
      w={{ base: '100%', md: '22.5rem' }}
      h='8.5rem'
      bgColor='white'
      borderRadius='xl'
      shadow='bold'
      border='none'
      role='group'
      _checked={{ shadow: '0 0 0 4px #1266c7' }}
      {...(isChecked ? { 'data-checked': true } : null)}
      {...rest}
    >
      <Image src={product.imageSrc} alt={product.name} w='7.5rem' borderRadius='inherit' fit='cover' />

      <Flex direction='column' flex='1'>
        <Stack h='100%' spacing='0.25rem' overflow='auto'>
          {product.logoSrc && <Image src={product.logoSrc} alt={product.logoSrc} w='4.375rem' borderRadius='inherit' fit='cover' />}

          <Stack spacing='0.25rem' lineHeight='1.2'>
            <Text fontSize='lg' fontWeight='700'>
              {product.name}
            </Text>
            <Text color='gray.500' fontSize='xs' fontWeight='400'>
              {product.description}
            </Text>
          </Stack>
        </Stack>

        <ButtonGroup as={Stack} direction='row' mt='auto' size='sm' colorScheme='green'>
          <Button isFullWidth>{i18.t('common.product.how-to-go')}</Button>

          <Button as={Link} variant='ghost' isFullWidth href={product.href} target='_blank' rel='noreferrer'>
            {i18.t('common.product.official-website')}
          </Button>
        </ButtonGroup>
      </Flex>
    </Stack>
  )
})

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Product),
  isChecked: PropTypes.bool,
}

export const PharmacyCard = React.forwardRef(({ pharmacy, isChecked, ...rest }, ref) => {
  const { isOpen: isMoreOpen, onOpen: openMore, onClose: closeMore } = useDisclosure()
  const { isOpen: isRemarkOpen, onOpen: openRemark, onClose: closeRemark } = useDisclosure()

  return (
    <Stack
      ref={ref}
      px='1.5rem'
      py='1rem'
      spacing='0.625rem'
      w={{ base: '100%', md: '22.5rem' }}
      h='8.5rem'
      bgColor='white'
      borderRadius='xl'
      shadow='bold'
      border='none'
      role='group'
      _checked={{ shadow: '0 0 0 4px #1266c7' }}
      {...(isChecked ? { 'data-checked': true } : null)}
      {...rest}
    >
      <Stack spacing='0.25rem' lineHeight='1'>
        <Text fontSize='lg' fontWeight='700'>
          {pharmacy.name}
        </Text>
        <Text color='gray.500' fontSize='xs' fontWeight='400'>
          {pharmacy.description}
        </Text>
      </Stack>

      <Flex alignItems='center' lineHeight='1'>
        <Text mr='auto' maxW={pharmacy.products.length > 1 ? '12rem' : '15rem'} fontSize='sm' isTruncated>
          {pharmacy.products[0]?.name}
        </Text>

        <Stack direction='row' spacing='0.25rem' ml='0.75rem' align='center'>
          <Text fontSize='lg' fontWeight='700'>
            {pharmacy.products[0]?.quantity}
          </Text>
          <Text flexShrink='0' color='gray.500' fontSize='xs'>
            {i18.t('common.pharmacy.remain')}
          </Text>
        </Stack>

        {pharmacy.products.length > 1 && (
          <Tooltip
            isOpen={isMoreOpen}
            onOpen={openMore}
            onClose={closeMore}
            closeOnClick={false}
            hasArrow
            label={
              <Stack color='gray.900' fontWeight='400' divider={<Divider />}>
                {pharmacy.products.map((product, index) => (
                  <Flex key={index} alignItems='center' lineHeight='1'>
                    <Text mr='auto' maxW='12rem' fontSize='xs' isTruncated>
                      {product.name}
                    </Text>

                    <Stack direction='row' spacing='0.25rem' ml='0.75rem' align='center'>
                      <Text fontSize='md' fontWeight='700'>
                        {product.quantity}
                      </Text>
                      <Text flexShrink='0' color='gray.500' fontSize='0.625rem'>
                        {i18.t('common.pharmacy.remain')}
                      </Text>
                    </Stack>
                  </Flex>
                ))}
              </Stack>
            }
            bg='white'
            placement='top-end'
          >
            <Button flexShrink='0' ml='0.75rem' variant='ghost' color='green.500' size='xs' onClick={openRemark}>
              +{pharmacy.products.length}
              {i18.t('common.pharmacy.brand')}
            </Button>
          </Tooltip>
        )}
      </Flex>

      <Flex direction='row' align='center'>
        <Stack direction='row' align='center'>
          <Button size='sm' w='6.25rem' colorScheme='green'>
            {i18.t('common.product.how-to-go')}
          </Button>

          <Text fontSize='sm'>{pharmacy.phoneNumber}</Text>
        </Stack>

        <Tooltip
          isOpen={isRemarkOpen}
          onOpen={openRemark}
          onClose={closeRemark}
          closeOnClick={false}
          hasArrow
          label={
            <Text color='gray.900' fontSize='xs' fontWeight='400'>
              {pharmacy.remark}
            </Text>
          }
          bg='white'
          placement='top-end'
        >
          <Button ml='auto' variant='ghost' color='green.500' size='xs' onClick={openRemark}>
            {i18.t('common.pharmacy.read-remark')}
          </Button>
        </Tooltip>
      </Flex>
    </Stack>
  )
})

PharmacyCard.propTypes = {
  pharmacy: PropTypes.instanceOf(Pharmacy),
  isChecked: PropTypes.bool,
}
