import React from 'react'
import { createStandaloneToast } from '@chakra-ui/react'
import { THEME } from 'src/configs'

const defaultOptions = { position: 'top-right', duration: 5000, variant: 'solid' }
export const useToast = (options) => {
  return React.useMemo(() => {
    return createStandaloneToast({
      theme: THEME,
      defaultOptions,
      ...options,
    })
  }, [options])
}
