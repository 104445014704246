import React from 'react'
import i18 from 'i18next'
import { usePosition } from 'use-position'

import { Position } from 'src/libs/models'
import { parseJSON } from 'src/libs/utils'

const GEOMETRY = 'GEOMETRY'
export const useCurrentLocation = (watch = false, settings) => {
  const { latitude, longitude, ...rest } = usePosition(watch, settings)
  const saved = parseJSON(window.localStorage.getItem(GEOMETRY), {})
  const geometry = React.useMemo(() => ({ lat: latitude, lng: longitude }), [latitude, longitude])

  React.useEffect(() => {
    if (!Position.isValid(geometry)) return
    if (saved?.lat !== geometry.lat || saved?.lng !== geometry.lng) {
      window.localStorage.setItem(GEOMETRY, JSON.stringify(geometry))
    }
  }, [saved, geometry])

  return React.useMemo(() => ({ ...saved, ...rest }), [saved, rest])
}

export const useCurrentLocationInstance = (fraction = null, watch, settings) => {
  const { lat, lng } = useCurrentLocation(watch, settings)
  // lat/lng 算到小數點第x位，位置差距不大就不更新 routes
  const fractionedLat = React.useMemo(
    () => (typeof lat === 'number' ? (Number.isSafeInteger(fraction) ? Number(lat.toFixed(fraction)) : lat) : null),
    [fraction, lat],
  )
  const fractionedLng = React.useMemo(
    () => (typeof lng === 'number' ? (Number.isSafeInteger(fraction) ? Number(lng.toFixed(fraction)) : lng) : null),
    [fraction, lng],
  )

  return React.useMemo(
    () =>
      new Position({
        id: 'current-location',
        name: i18.t('common.current-location'),
        category: Position.Category.Current,
        lat: fractionedLat,
        lng: fractionedLng,
      }),
    [fractionedLat, fractionedLng],
  )
}
