import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Stack, Text, Icon, IconButton, Divider, Tag, Fade, useControllableState } from '@chakra-ui/react'
import { EnhancedInput, Icons, Toast, ENHANCED_INPUT_STYLE, arrayHelper } from '@metropia/react-tools'
import i18 from 'i18next'

import * as api from 'src/libs/api'
import { Position } from 'src/libs/models'
import { useToast, useCurrentLocationInstance } from 'src/libs/hooks'
import { QueryCombobox } from 'src/components'

const ERROR_TOAST_ID = 'error.unauthenticated-location'
export const DirectionsSearchboxes = ({
  placeholder = [],
  focusedIndex: _focusedIndex,
  onFocus,
  defaultValue = new Array(2).fill(null),
  value: _value,
  onChange,
}) => {
  const toast = useToast()
  const currentLocation = useCurrentLocationInstance()
  const positionFetcher = React.useCallback(
    async (index, keyword) => {
      try {
        if (!Position.isValid(currentLocation)) {
          if (!toast.isActive(ERROR_TOAST_ID)) {
            toast({
              id: ERROR_TOAST_ID,
              render: (props) => <Toast {...props} variant='solid' status='error' description={i18.t('error.unauthenticated-location')} />,
            })
          }

          throw new Error(i18.t('error.unauthenticated-location'))
        }

        return await api.fetchPositionListByQuery({ query: keyword, location: currentLocation })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [currentLocation, toast],
  )

  const [focusedIndex, setFocusedIndex] = useControllableState({ defaultValue: null, value: _focusedIndex, onChange: onFocus })
  const [value, setValue] = useControllableState({ defaultValue, value: _value, onChange })
  const indexes = React.useMemo(() => value.map((_, index) => index), [value])

  const onBlur = React.useCallback(() => setFocusedIndex(null), [setFocusedIndex])
  const onSwap = React.useCallback(() => setValue(arrayHelper.swap(value)), [value, setValue])

  return (
    <Flex
      position='relative'
      flex='1'
      direction='column'
      width='inherit'
      borderRadius='inherit'
      bgColor={Number.isSafeInteger(focusedIndex) ? 'gray.50' : 'transparent'}
    >
      {indexes.map((index) => (
        <React.Fragment key={index}>
          <QueryCombobox
            fetcher={positionFetcher}
            propsOf={{ downshift: { itemToString: (item) => item?.name ?? '' }, query: { fallbackData: [{ data: [currentLocation] }] } }}
            value={value[index]}
            onChange={(option) => {
              if (value.map((value) => value?.id ?? null).includes(option?.id)) {
                return toast({ status: 'warning', description: i18.t('warn.duplicated-position') })
              }

              setValue(arrayHelper.replace(value, index, option))
            }}
            onFocus={() => setFocusedIndex(index)}
            onBlur={onBlur}
            empty={<QueryCombobox.TextContent>{i18.t('common.empty-content')}</QueryCombobox.TextContent>}
            loading={<QueryCombobox.TextContent>{i18.t('common.searching')}</QueryCombobox.TextContent>}
            renderInput={(props, helpers) => (
              <EnhancedInput
                {...props}
                onClear={() => helpers.clearSelection()}
                leftElement={
                  index < indexes.length - 1 ? (
                    <Icon as={Icons.MapMarkerCircle} color='green.500' boxSize='1.25rem' />
                  ) : (
                    <Icon as={Icons.MapMarkerPin} color='orange.500' boxSize='1.25rem' />
                  )
                }
                bgColor={focusedIndex === index ? 'white' : 'transparent'}
                borderRadius='inherit'
                borderColor='transparent'
                placeholder={placeholder[index]}
                _hover={{ borderColor: 'transparent' }}
              />
            )}
          >
            {({ getItemProps, item, index, ...rest }) => (
              <QueryCombobox.Item key={index} px='1rem' py='0.75rem' {...getItemProps({ item, index })}>
                <Flex align='center' lineHeight='1' isTruncated>
                  <Icon as={Position.CategoryIcon[item.category]} mr='1.125rem' flexShrink='0' boxSize='1.25rem' color='gray.600' />
                  <Stack spacing='0.25rem' justifyContent='center' isTruncated>
                    <Stack direction='row'>
                      <Text flexShrink='0' fontSize='sm'>
                        {item.name}
                      </Text>
                      {item.googleTypes.some((type) => Position.GoogleStationTypes.includes(type)) && (
                        <Tag colorScheme='blue' color='blue.700' fontWeight='400' size='sm' minH='0.875rem' px='0.25rem'>
                          {i18.t('common.station')}
                        </Tag>
                      )}
                    </Stack>
                    {item.address?.length > 0 && (
                      <Text fontSize='xs' color='gray.700' isTruncated>
                        {item.address}
                      </Text>
                    )}
                  </Stack>
                </Flex>
              </QueryCombobox.Item>
            )}
          </QueryCombobox>

          {index !== indexes.length - 1 && (
            <>
              <Fade in={focusedIndex === null} unmountOnExit={false}>
                <Icon
                  as={Icons.DotsVertical}
                  pointerEvents='none'
                  position='absolute'
                  top='50%'
                  left='1.125rem'
                  transform='translateY(-50%)'
                  color='gray.600'
                  width='1.25rem'
                  height='2.25rem'
                />
              </Fade>

              <Divider
                position='absolute'
                top='50%'
                left='var(--left)'
                transform='translateY(-50%)'
                width={`calc(100% - var(--left) - ${ENHANCED_INPUT_STYLE.RIGHT_ELEMENT_WIDTH})`}
                borderColor='gray.400'
                // option-padding-x + icon-size + icon-margin-right
                sx={{ '--left': 'calc(1.125rem + 1.25rem + 1.125rem)' }}
              />
            </>
          )}
        </React.Fragment>
      ))}

      <Fade in={focusedIndex === null}>
        <IconButton
          position='absolute'
          top='50%'
          left={`calc(100% - ${ENHANCED_INPUT_STYLE.RIGHT_ELEMENT_WIDTH} + 0.5rem)`}
          transform='translateY(-50%)'
          size='sm'
          variant='ghost'
          boxSize='1.5rem'
          minWidth='initial'
          borderRadius='full'
          icon={<Icon as={Icons.SwapVertical} boxSize='1rem' color='gray.600' />}
          onClick={onSwap}
          _active={{ transform: 'translateY(-50%)' }}
        />
      </Fade>
    </Flex>
  )
}

DirectionsSearchboxes.propTypes = {
  placeholder: PropTypes.arrayOf(PropTypes.string),
  focusedIndex: PropTypes.number,
  onFocus: PropTypes.func,
  defaultValue: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
}
