import React from 'react'
import PropTypes from 'prop-types'
import Downshift from 'downshift'
import useEvent from '@react-hook/event'
import { useThrottle } from '@react-hook/throttle'
import { Stack, Flex, Text, Popover, PopoverTrigger, PopoverContent } from '@chakra-ui/react'
import { useCombinedRefs } from '@metropia/react-tools'

import { Query } from './query'

const useIsScrollingEnd = (ref) => {
  const [isScrollingEnd, setIsScrollingEnd] = useThrottle(false, 30, true)
  useEvent(ref, 'scroll', (event) => setIsScrollingEnd(event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight))

  return isScrollingEnd
}

export const QueryCombobox = React.forwardRef(
  ({ value, onChange, element, fetcher, children, renderInput, error: errorNode, loading, empty, propsOf, ...rest }, ref) => {
    const inputRef = useCombinedRefs(ref)
    const menuRef = React.useRef(null)
    const isScrollingEnd = useIsScrollingEnd(menuRef)

    return (
      <Downshift selectedItem={value} onChange={onChange} {...propsOf.downshift}>
        {(stateAndHelpers) => {
          const { inputValue, isOpen, openMenu, closeMenu, getRootProps, getInputProps, getMenuProps, getItemProps } = stateAndHelpers

          return (
            <Popover isOpen={isOpen} initialFocusRef={inputRef} onOpen={openMenu} onClose={closeMenu} gutter={8} {...propsOf.popover}>
              <PopoverTrigger {...propsOf.popoverTrigger}>
                {/* https://github.com/downshift-js/downshift/tree/v6.0.6#getrootprops */}
                <Flex flex='1' borderRadius='inherit' {...getRootProps({ refKey: 'ref', ...propsOf.root }, { suppressRefError: true })}>
                  {typeof renderInput === 'function'
                    ? renderInput(getInputProps({ ...rest, ref: inputRef }), stateAndHelpers)
                    : React.cloneElement(element, getInputProps({ ...rest, ref: inputRef }))}
                </Flex>
              </PopoverTrigger>

              <PopoverContent
                {...getMenuProps({ ref: menuRef, ...propsOf.popoverContent, ...propsOf.menu })}
                py='0.5rem'
                width='inherit'
                maxH='12rem'
                overflow='auto'
              >
                <Query isScrollingEnd={isScrollingEnd} keyword={inputValue.trim()} fetcher={fetcher} {...propsOf.query}>
                  {({ error, isValidating, isEmpty, items }) => {
                    return error ? (
                      errorNode
                    ) : (
                      <Stack spacing='0' w='100%' {...propsOf.list}>
                        {items.map((item, index) => children({ getItemProps, item, index, stateAndHelpers }))}

                        {isValidating ? loading : isEmpty && empty}
                      </Stack>
                    )
                  }}
                </Query>
              </PopoverContent>
            </Popover>
          )
        }}
      </Downshift>
    )
  },
)

QueryCombobox.TextContent = React.forwardRef((props, ref) => (
  <Text ref={ref} paddingY='0.75rem' lineHeight='1.75' textAlign='center' fontSize='sm' color='gray.700' {...props} />
))
QueryCombobox.Item = React.forwardRef((props, ref) => (
  <Flex ref={ref} cursor='pointer' w='100%' align='center' _selected={{ bgColor: 'brand.50' }} {...props} />
))

QueryCombobox.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  element: PropTypes.element,
  fetcher: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  renderInput: PropTypes.func,
  error: PropTypes.node,
  loading: PropTypes.node,
  empty: PropTypes.node,
  propsOf: PropTypes.shape({
    downshift: PropTypes.object,
    popover: PropTypes.object,
    popoverTrigger: PropTypes.object,
    popoverContent: PropTypes.object,
    list: PropTypes.object,
    item: PropTypes.object,
    root: PropTypes.object,
    menu: PropTypes.object,
    query: PropTypes.object,
  }),
}

QueryCombobox.defaultProps = {
  element: <input type='text' />,
  children: ({ getItemProps, item, index, ...rest }) => (
    <QueryCombobox.Item key={index} {...getItemProps({ item, index })} {...rest}>
      {item}
    </QueryCombobox.Item>
  ),
  error: <QueryCombobox.TextContent>Error</QueryCombobox.TextContent>,
  loading: <QueryCombobox.TextContent>Loading...</QueryCombobox.TextContent>,
  empty: <QueryCombobox.TextContent>Empty</QueryCombobox.TextContent>,
  propsOf: {},
}
