import BigNumber from 'bignumber.js'
import { set, getHours, addDays, addHours, startOfDay, startOfHour, compareAsc } from 'date-fns'
import { hexToRgba, colorToCssString } from 'colors-convert'
import { decode } from '@googlemaps/polyline-codec'
import { Icons } from '@metropia/react-tools'
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp'
import MdAirplanemodeActive from '@meronex/icons/md/MdAirplanemodeActive'

import { ROUTING_SOURCE } from 'src/configs'
import { getEnv } from 'src/libs/utils'
import { MAP_ICON, TRANSIT_MODE, TRANSPORT_MODE, TransportBikingSVG, TransportRentalCarSVG, TransportRentalScooterSVG } from './constants'

// https://latitudelongitude.org/tw/
// Taiwan are in range: Latitude from 22.00417 to 25.12825 and longitude from 118.31833 to 121.753.
// lng 118-120 is outlying islands
export const isAtTaiwan = ({ lat, lng }) => lat > 22 && lat < 25.15 && lng > 120 && lng < 122

export const calculateMeters = (sections) => BigNumber.sum(...sections.map((section) => section.travelSummary.length)).toNumber()

export const calculateMetersOfWalking = (sections) => calculateMeters(sections.filter((section) => TRANSPORT_MODE.WALKING.includes(section.type)))

export const getFirstDeparture = (sections) => sections[0].departure

export const getLastArrival = (sections) => sections.slice(-1)[0].arrival

export const getFirstDepartureTime = (sections) => getFirstDeparture(sections).time

export const getLastArrivalTime = (sections) => getLastArrival(sections).time

// NOTE: the key of object is a stringified array, and the value should be one of them, or return fallback
export const getInEntries = (entries, value, fallback = null) => {
  const [, item = fallback] = entries?.find(([key]) => key.includes(value)) ?? []
  return item
}

export const getIcon = (section = null) => {
  let icon = Icons.TransportTransit

  if (TRANSIT_MODE.BUS.includes(section?.transport?.mode)) {
    icon = Icons.TransitBus
  } else if (TRANSIT_MODE.SUBWAY.includes(section?.transport?.mode)) {
    icon = Icons.TransitSubway
  } else if (TRANSIT_MODE.TRAIN.includes(section?.transport?.mode)) {
    icon = Icons.TransitTrain
  } else if (TRANSIT_MODE.RAIL.includes(section?.transport?.mode)) {
    icon = Icons.TransitTramLightRail
  } else if (TRANSIT_MODE.TRAM.includes(section?.transport?.mode)) {
    icon = Icons.TransitTramLightRail
  } else if (TRANSIT_MODE.AIRPLANE.includes(section?.transport?.mode)) {
    icon = MdAirplanemodeActive
  }

  if (TRANSPORT_MODE.WAITING.includes(section?.transport?.mode)) {
    icon = Icons.Clock
  } else if (TRANSPORT_MODE.TRANSIT.includes(section?.transport?.mode)) {
    icon = Icons.TransportTransit
  } else if (TRANSPORT_MODE.WALKING.includes(section?.transport?.mode)) {
    icon = Icons.TransportWalking
  } else if (TRANSPORT_MODE.CYCLING.includes(section?.transport?.mode) && typeof section?.transport?.agency === 'object') {
    icon = TransportBikingSVG
  } else if (TRANSPORT_MODE.CYCLING.includes(section?.transport?.mode)) {
    icon = Icons.TransportBiking
  } else if (TRANSPORT_MODE.DRIVING.includes(section?.transport?.mode) && typeof section?.transport?.agency === 'object') {
    icon = TransportRentalCarSVG
  } else if (TRANSPORT_MODE.DRIVING.includes(section?.transport?.mode)) {
    icon = Icons.TransportDriving
  } else if (TRANSPORT_MODE.SCOOTER.includes(section?.transport?.mode) && typeof section?.transport?.agency === 'object') {
    icon = TransportRentalScooterSVG
  }

  return icon
}

export const getFromEncodedPolyline = (sections, source) => {
  // here LineString instance
  const lineStringsBySection = sections.map((section) => {
    return section.polyline?.length === 0 || (typeof section.transport.usePolyline === 'boolean' && !section.transport.usePolyline)
      ? H.geo.LineString.fromLatLngArray([
          section.departure.place.location.lat,
          section.departure.place.location.lng,
          ...(section.intermediateStops?.reduce((acc, { departure: { place } }) => [...acc, place.location.lat, place.location.lng], []) ?? []),
          section.arrival.place.location.lat,
          section.arrival.place.location.lng,
        ])
      : source === ROUTING_SOURCE.METROPIA
      ? H.geo.LineString.fromFlexiblePolyline(section.polyline)
      : H.geo.LineString.fromLatLngArray(decode(section.polyline).flat())
  })
  const coordsBySection = lineStringsBySection.map((lineString) => lineString.getLatLngAltArray().filter((_, index) => index % 3 !== 2)) // format is [lat, lng, alt, ...] should remove alt value
  const directionsBySection = coordsBySection.map((coords) => [coords.slice(0, 2), coords.slice(-2)].map(([lat, lng]) => ({ lat, lng })))
  const coords = coordsBySection.flat()
  const cneterIndex = coords.length / 2 - ((coords.length / 2) % 2)
  const [lat, lng] = coords.slice(cneterIndex, cneterIndex + 2)

  return {
    lineStringsBySection,
    directionsBySection,
    center: { lat, lng },
  }
}

export const getTransitNotation = (section = null, isHighlighted = true) => {
  const env = getEnv()
  let icon = MAP_ICON.DOT
  let color = '#2dc795'

  if (metropia.TransportMode.WALKING_STATION === section.type) {
    color = section?.transport.color ?? '#2dc795'
  } else if (metropia.TransportMode.CYCLING === section?.transport?.mode) {
    color = section?.transport.color ?? '#276749'
  } else if (metropia.TransportMode.DRIVING === section?.transport?.mode && typeof section?.transport?.agency === 'object') {
    color = section?.transport.color ?? '#0077b6'
  } else if (metropia.TransportMode.DRIVING === section?.transport?.mode) {
    color = section?.transport.color ?? '#323848'
  } else if (metropia.TransportMode.SCOOTER === section?.transport?.mode && typeof section?.transport?.agency === 'object') {
    color = section?.transport.color ?? '#fc70ba'
  } else if (TRANSIT_MODE.BUS.includes(section?.transport?.mode)) {
    icon = MAP_ICON.BUS
    color = section?.transport.color ?? '#0c9f2c'
  } else if (TRANSIT_MODE.SUBWAY.includes(section?.transport?.mode)) {
    icon = MAP_ICON.SUBWAY
    color = section?.transport.color ?? '#0c9f2c'
  } else if (TRANSIT_MODE.TRAIN.includes(section?.transport?.mode)) {
    icon = MAP_ICON.TRAIN
    color = section?.transport.color ?? '#0045a0'
  } else if (TRANSIT_MODE.RAIL.includes(section?.transport?.mode)) {
    icon = MAP_ICON.TRAIN
    color = section?.transport.color ?? { [window.LOCATION.TAIWAN]: '#f63d00', [window.LOCATION.HOUSTON]: '#004080' }[env.LOCATION]
  }

  return { icon: isHighlighted ? icon : MAP_ICON.DOT, color: isHighlighted ? color : '#72809E' }
}

export const getPolylineWithBorder = (lineString, strokeColor, isDashed, opacity = 1) => {
  const rgbaColor = colorToCssString(hexToRgba(strokeColor, opacity))
  // DOC of polyline options: https://www.here.com/docs/bundle/maps-api-for-javascript-api-reference/page/H.map.SpatialStyle.html#.Options
  // NOTE: fillColor is not working
  return lineString.getPointCount() > 1
    ? isDashed
      ? [
          // NOTE: dash polyline content
          new H.map.Polyline(lineString, {
            style: {
              lineWidth: 7,
              strokeColor: rgbaColor,
              lineDash: [1, 1],
              lineDashImage: H.map.SpatialStyle.DashImage.CIRCLE,
            },
          }),
        ]
      : [
          // NOTE: solid polyline border painted by bigger stroke (dash doesn't have border)
          new H.map.Polyline(lineString, { style: { lineWidth: 7, strokeColor: rgbaColor } }),
          // NOTE: solid polyline content
          new H.map.Polyline(lineString, { style: { lineWidth: 1, strokeColor } }),
        ]
    : []
}

export const sortRoutes = (routes, sort) => {
  switch (sort) {
    case metropia.Sort.EARLIEST_ARRIVAL:
      return routes.slice().sort((prev, next) => compareAsc(getLastArrivalTime(prev.sections), getLastArrivalTime(next.sections)))

    case metropia.Sort.EARLIEST_DEPARTURE:
      return routes.slice().sort((prev, next) => compareAsc(getFirstDepartureTime(prev.sections), getFirstDepartureTime(next.sections)))

    case metropia.Sort.SHORTEST_TIME:
      return routes.slice().sort((prev, next) => prev.seconds - next.seconds)

    case metropia.Sort.LOWEST_PRICE:
      return routes.slice().sort((prev, next) => prev.fare - next.fare)

    case metropia.Sort.FEWEST_WALKING:
      return routes.slice().sort((prev, next) => calculateMetersOfWalking(prev.sections) - calculateMetersOfWalking(next.sections))

    case metropia.Sort.FEWEST_TRANSFER:
      return routes.slice().sort((prev, next) => prev.transferTimes - next.transferTimes)

    default:
      return routes
  }
}

export const getInitialOption = (env) => ({
  leaveType: env.DEFAULT_OPTION.LEAVE_TYPE,
  ...(env.DEFAULT_OPTION.LEAVE_TYPE === metropia.LeaveType.LEAVE_NOW
    ? {
        date: getHours(Date.now()) === 23 ? addDays(startOfDay(Date.now()), 1) : startOfDay(Date.now()),
        time: getHours(Date.now()) === 23 ? startOfDay(Date.now()) : addHours(startOfHour(Date.now()), 1),
      }
    : {
        date: env.DEFAULT_OPTION.LEAVE_AT.DATE,
        time: set(startOfHour(Date.now()), { hours: env.DEFAULT_OPTION.LEAVE_AT.HOUR, minutes: env.DEFAULT_OPTION.LEAVE_AT.MINUTES }),
      }),
  preferredTransitModes: env.DEFAULT_OPTION.PREFFERED_TRANSITS,
  transferMinutesRange: env.DEFAULT_OPTION.TRANSFER_MINUTES_RANGE,
  timeCostPreference: env.DEFAULT_OPTION.TIME_COST_PREFERENCE,
  firstMileBikeTypes: {
    [window.LOCATION.TAIWAN]: env.DEFAULT_OPTION.FIRST_MILE_BIKE_TYPES[0],
    [window.LOCATION.HOUSTON]: env.DEFAULT_OPTION.FIRST_MILE_BIKE_TYPES,
  }[env.LOCATION],
  firstMileModes: env.DEFAULT_OPTION.FIRST_MILE_MODES,
  firstMileMaxMinutes: env.DEFAULT_OPTION.FIRST_MILE_MAX_MINUTES,
  useFirstMileRentalScooter: env.DEFAULT_OPTION.USE_FIRST_MILE_RENTAL_SCOOTER,
  lastMileBikeTypes: {
    [window.LOCATION.TAIWAN]: env.DEFAULT_OPTION.LAST_MILE_BIKE_TYPES[0],
    [window.LOCATION.HOUSTON]: env.DEFAULT_OPTION.LAST_MILE_BIKE_TYPES,
  }[env.LOCATION],
  lastMileModes: env.DEFAULT_OPTION.LAST_MILE_MODES,
  lastMileMaxMinutes: env.DEFAULT_OPTION.LAST_MILE_MAX_MINUTES,
  useLastMileRentalCar: env.DEFAULT_OPTION.USE_LAST_MILE_RENTAL_CAR,

  // advanced
  timeCostOfNormalTravel: env.DEFAULT_OPTION.TIME_COST_OF_NORMAL_TRAVEL,
  timeCostOfBusinessTravel: env.DEFAULT_OPTION.TIME_COST_OF_BUSINESS_TRAVEL,
  timeCostOfLastMile: env.DEFAULT_OPTION.TIME_COST_OF_LAST_MILE,
  transitOfTimeCost: env.DEFAULT_OPTION.TRANSIT_OF_TIME_COST,
  transitOfFare: env.DEFAULT_OPTION.TRANSIT_OF_FARE,
  speedOfWalking: env.DEFAULT_OPTION.SPEED_OF_WALKING,
  speedOfCycling: env.DEFAULT_OPTION.SPEED_OF_CYCLING,
  speedOfDriving: env.DEFAULT_OPTION.SPEED_OF_DRIVING,
  labeling: env.DEFAULT_OPTION.LABELING,
  top: env.DEFAULT_OPTION.TOP,
  dimension: env.DEFAULT_OPTION.DIMENSION,
  useGoogle: env.DEFAULT_OPTION.USE_GOOGLE,
  unitFare: {
    thsr: env.DEFAULT_OPTION.UNIT_FARE.THSR,
    tra: env.DEFAULT_OPTION.UNIT_FARE.TRA,
    bus: env.DEFAULT_OPTION.UNIT_FARE.BUS,
    mrt: env.DEFAULT_OPTION.UNIT_FARE.MRT,
    lrt: env.DEFAULT_OPTION.UNIT_FARE.LRT,
    ferry: env.DEFAULT_OPTION.UNIT_FARE.FERRY,
    gondola: env.DEFAULT_OPTION.UNIT_FARE.GONDOLA,
    airplane: env.DEFAULT_OPTION.UNIT_FARE.AIRPLANE,
  },
})
