import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { isValid, getDay, format } from 'date-fns'
import useSWR from 'swr'
import {
  Heading,
  Button,
  ButtonGroup,
  Input,
  Flex,
  Center,
  Text,
  Stack,
  CheckboxGroup,
  Checkbox,
  Divider,
  Icon,
  IconButton,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Loading, Icons, Confirmation, toast } from '@metropia/react-tools'
import useChange from '@react-hook/change'
import { useTimeout } from '@react-hook/timeout'
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp'
import qs from 'query-string'
import * as copy from 'copy-to-clipboard'
import i18 from 'i18next'
import FiChevronLeft from '@meronex/icons/fi/FiChevronLeft'
import FiArrowLeft from '@meronex/icons/fi/FiArrowLeft'
import FiChevronRight from '@meronex/icons/fi/FiChevronRight'
import FiList from '@meronex/icons/fi/FiList'
import FiX from '@meronex/icons/fi/FiX'

import { FORMAT_OF_DATE } from 'src/configs'
import * as api from 'src/libs/api'
import { Group, Position } from 'src/libs/models'
import { useCurrentLocationInstance } from 'src/libs/hooks'
import { convertSecondsToDuration } from 'src/libs/utils'
import { QueryCombobox, Selector, RouteCard, RouteDetail, GroupModifier } from 'src/components'

import {
  Context,
  MAP_ICON,
  VIEW,
  MD_WIDTH,
  LEAVE_TYPE_TEXT,
  SORT_OPTIONS,
  TRANSIT_MODE_TO_METROPIA_API,
  TRANSPORT_MODE,
  INITIAL_SORT,
} from 'src/views/trip-planning/constants'
import { sortRoutes, getFromEncodedPolyline, getPolylineWithBorder, getTransitNotation, getInitialOption } from 'src/views/trip-planning/utils'

const getGroupLink = (group) => `${window.location.origin}${window.location.pathname}?groupId=${group.id}`
const VIEWPORT_PADDING = {
  // top, right, bottom, left
  MD: [240, 24, 80, 24],
  LG: [160, 24, 80, 420],
  LG_WITH_SIDE: [160, 24, 80, 24],
}

// vendor=9f86d081884c7d&
function Embedded(props) {
  const [search, setSearch] = useSearchParams()
  const { env, mapRef, uiRef } = React.useContext(Context)
  const currentLocation = useCurrentLocationInstance(3)
  const isLocationValid = React.useMemo(() => Position.isValid(currentLocation), [currentLocation])

  const isMobile = useBreakpointValue({ base: true, md: false }, 'base')
  const { isOpen: isModifierOpen, onOpen: openModifier, onClose: closeModifier } = useDisclosure()
  const { isOpen: isSiderOpen, onOpen: openSider, onClose: closeSider, onToggle: onSiderToggle } = useDisclosure({ defaultIsOpen: !isMobile })
  useChange(isMobile, isMobile ? closeSider : openSider)

  const parsedSearch = React.useMemo(() => qs.parse(search.toString()), [search])
  const hasGroup = React.useMemo(() => typeof parsedSearch.groupId === 'string', [parsedSearch])
  const isVendor = React.useMemo(() => typeof parsedSearch.vendor === 'string', [parsedSearch])

  const [isTimedOut, start] = useTimeout(env.TIMEOUT_MS)
  const [view, setView] = React.useState(hasGroup ? VIEW.GROUP : null)
  const [option] = React.useState(getInitialOption(env))
  const [sort, setSort] = React.useState(INITIAL_SORT)
  const [checkedIndexes, setCheckedIndexes] = React.useState([])
  const [directions, setDirections] = React.useState([isLocationValid ? currentLocation : null, null])
  const [origin, destination] = directions
  const [selectedGroup, setSelectedGroup] = React.useState(null) // value for query combobox
  const [modifyingGroup, setModifyingGroup] = React.useState(new Group({ positions: [new Position()] })) // value for group modifier
  useChange(isLocationValid && !Position.isValid(origin), () => setDirections([currentLocation, destination]))

  const groupFetcher = React.useCallback(
    (index, keyword) => api.fetchGroupsByKeyword({ keyword, vendor: parsedSearch.vendor, page: index + 1, size: 5 }),
    [parsedSearch],
  )
  const addressFetcher = React.useCallback((index, keyword) => api.fetchPositionListByAddress({ address: keyword }), [])

  const {
    data: { data: group }, // value for displaying content when view === VIEW.GROUP
    error: groupError,
    mutate,
    isValidating: isGroupValidating,
  } = useSWR(hasGroup ? [parsedSearch.groupId] : null, (id) => api.fetchGroup({ id }), {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    fallbackData: { data: new Group() },
  })
  useChange(hasGroup && typeof groupError === 'undefined', (hasGroup) => setView(hasGroup ? VIEW.GROUP : null))

  const {
    data: routes,
    isValidating: isRouteValidating,
    error: routeError,
  } = useSWR(
    directions.every(Position.isValid) ? [directions, '/trip-planning'] : null,
    ([origin, destination]) => {
      const params = {
        ...option,
        origin: Position.stringify(origin),
        originName: origin.name,
        originAddress: origin.address,
        destination: Position.stringify(destination),
        destinationName: destination.name,
        destinationAddress: destination.address,
        weekday: getDay(option.leaveType === metropia.LeaveType.LEAVE_NOW ? new Date() : option.date),
        departureDate: option.leaveType === metropia.LeaveType.LEAVE_NOW ? new Date() : option.date,
        departureTime: { [metropia.LeaveType.LEAVE_NOW]: new Date(), [metropia.LeaveType.DEPART_AT]: option.time }[option.leaveType] ?? null,
        arrivalTime: option.leaveType === metropia.LeaveType.ARRIVE_AT ? option.time : null,
        transitModes: option.preferredTransitModes.flatMap((mode) => TRANSIT_MODE_TO_METROPIA_API[mode]).filter(Boolean),
        minTransferSeconds: option.transferMinutesRange[0] * 60,
        maxTransferSeconds: option.transferMinutesRange[1] * 60,
      }

      return Promise.all([
        api.fetchDirectionRoutesByMetropia({
          ...params,
          firstMileModes: [metropia.FirstAndLastMileMode.WALKING],
          lastMileModes: [metropia.FirstAndLastMileMode.WALKING],
        }),
        api.fetchDirectionRoutesByMetropia({
          ...params,
          firstMileModes: [metropia.FirstAndLastMileMode.CYCLING],
          lastMileModes: [metropia.FirstAndLastMileMode.CYCLING],
        }),
        api.fetchDirectionRoutesByMetropia({
          ...params,
          firstMileModes: [metropia.FirstAndLastMileMode.DRIVING],
          lastMileModes: [metropia.FirstAndLastMileMode.DRIVING],
        }),
      ]).then((responses) => responses.flatMap((response) => response.data))
    },
    { revalidateOnFocus: false, fallbackData: [] },
  )

  // NOTE: use first position to call route api (for KPI of API)
  // X 秒內沒開啟定位，起點改為台北車站
  useSWR(
    Position.isValid(group.positions[0])
      ? isLocationValid
        ? [origin, group.positions[0], '/trip-planning/first']
        : isTimedOut
        ? [env.DEFAULT_MAP_OPTION.center, group.positions[0], '/trip-planning/first']
        : null
      : null,
    (origin, destination) =>
      api.fetchDirectionRoutesByMetropia({
        ...option,
        isBackground: true,
        origin: Position.stringify(origin),
        originName: origin.name,
        originAddress: origin.address,
        destination: Position.stringify(destination),
        destinationName: destination.name,
        destinationAddress: destination.address,
        weekday: getDay(option.leaveType === metropia.LeaveType.LEAVE_NOW ? new Date() : option.date),
        departureDate: option.leaveType === metropia.LeaveType.LEAVE_NOW ? new Date() : option.date,
        departureTime: { [metropia.LeaveType.LEAVE_NOW]: new Date(), [metropia.LeaveType.DEPART_AT]: option.time }[option.leaveType] ?? null,
        arrivalTime: option.leaveType === metropia.LeaveType.ARRIVE_AT ? option.time : null,
        transitModes: option.preferredTransitModes.flatMap((mode) => TRANSIT_MODE_TO_METROPIA_API[mode]).filter(Boolean),
        minTransferSeconds: option.transferMinutesRange[0] * 60,
        maxTransferSeconds: option.transferMinutesRange[1] * 60,
      }),
    { revalidateOnFocus: false, fallbackData: { data: null } },
  )

  const highlightedIndex = React.useMemo(() => checkedIndexes.slice(-1)[0] ?? null, [checkedIndexes])
  const sortedRoutes = React.useMemo(() => sortRoutes(sortRoutes(routes, sort.then), sort.first), [routes, sort])

  const selectPosition = React.useCallback(
    (position) => {
      setView(VIEW.LIST)
      setDirections([origin, position])
    },
    [origin],
  )
  const onCreate = React.useCallback(() => {
    setModifyingGroup(new Group({ positions: [new Position()] }))
    openModifier()
  }, [openModifier])
  const onLinkCopy = React.useCallback(() => {
    copy(getGroupLink(group))
    toast({ status: 'success', title: i18.t('embedded.success-of-copy') })
  }, [group])
  const onUpdate = React.useCallback(() => {
    setModifyingGroup(group)
    openModifier()
  }, [group, openModifier])
  const onDeleteConfirm = React.useCallback(async () => {
    try {
      await api.deleteGroup({ ...parsedSearch, id: parsedSearch.groupId })
      setSearch({ vendor: parsedSearch.vendor })
      setSelectedGroup(null)
      toast({ status: 'success', title: i18.t('embedded.success-of-delete') })
    } catch (error) {
      toast({ status: 'error', description: i18.t('error.unspecific') })
    }
  }, [parsedSearch, setSearch])
  const onModifierSubmit = React.useCallback(
    async (values, formik) => {
      try {
        const params = { ...parsedSearch, ...values }

        if (values.id) {
          await api.updateGroup(params)

          const createdPositions = values.positions.filter((prev) => !modifyingGroup.positions.find((next) => prev.id === next.id))
          if (createdPositions.length > 0) {
            await api.createPositions({ ...params, positions: createdPositions })
          }

          const updatedPositions = values.positions.filter((prev) => modifyingGroup.positions.find((next) => prev.id === next.id))
          if (updatedPositions.length > 0) {
            await api.updatePositions({ ...params, positions: updatedPositions })
          }

          const deletedPositions = modifyingGroup.positions.filter((prev) => !values.positions.find((next) => prev.id === next.id))
          if (deletedPositions.length > 0) {
            await api.deletePositions({ ...params, positions: deletedPositions })
          }

          toast({ status: 'success', description: i18.t('embedded.success-of-update') })
        } else {
          const { data: groupId } = await api.createGroup(params)
          await api.createPositions({ ...params, groupId })

          setSearch({ ...parsedSearch, groupId })
          toast({ status: 'success', description: i18.t('embedded.success-of-create') })
        }

        closeModifier()
        mutate()
      } catch (error) {
        if (error.code === 20004) {
          toast({ status: 'error', description: i18.t('embedded.error.duplicated-group-name') })
        } else {
          toast({ status: 'error', description: i18.t('error.unspecific') })
        }
      }
    },
    [parsedSearch, setSearch, modifyingGroup.positions, closeModifier, mutate],
  )
  const onBack = React.useCallback(() => {
    switch (view) {
      case VIEW.LIST:
        setCheckedIndexes([])
        setDirections([null, null])
        setView(VIEW.GROUP)
        break
      case VIEW.DETAIL:
        setView(VIEW.LIST)
        break
    }
  }, [view])
  const onCheckboxChange = React.useCallback((stringIndexes) => setCheckedIndexes(stringIndexes.map(Number)), [])

  React.useEffect(() => start(), [start]) // call timer when mounted
  React.useEffect(() => {
    if (typeof groupError !== 'undefined') {
      toast({ status: 'warning', description: groupError.message })
    }
  }, [groupError])
  React.useEffect(() => {
    if (routeError?.status === 401) {
      toast({ status: 'warning', description: i18.t('warn.engine-busy') })
    }
  }, [routeError])
  React.useEffect(() => {
    if (isRouteValidating) return
    if (routes.length === 0) {
      setCheckedIndexes([])
    } else {
      setCheckedIndexes([0])
    }
  }, [isRouteValidating, routes.length])

  React.useEffect(() => {
    const map = mapRef.current
    if (!map) return

    const viewPort = map?.getViewPort()
    if (!viewPort) return

    const isMobile = viewPort.width < MD_WIDTH
    if (isMobile) {
      viewPort.setPadding(...VIEWPORT_PADDING.MD)
    } else if (!isMobile && !isSiderOpen) {
      viewPort.setPadding(...VIEWPORT_PADDING.LG_WITH_SIDE)
    } else if (!isMobile && isSiderOpen) {
      viewPort.setPadding(...VIEWPORT_PADDING.LG)
    }
  }, [isSiderOpen, mapRef])

  React.useEffect(() => {
    if (directions.some((position) => !Position.isValid(position)) || sortedRoutes.length === 0 || checkedIndexes.length === 0) return

    const group = new H.map.Group()
    const bubbles = []

    const marksAndLines = checkedIndexes.flatMap((checkedIndex) => {
      const { sections, seconds, source } = sortedRoutes[checkedIndex]
      const isHighlighted = checkedIndex === highlightedIndex

      const { lineStringsBySection, directionsBySection, center } = getFromEncodedPolyline(sections, source)
      const timingBubble = new H.ui.InfoBubble(center, { content: FORMAT_OF_DATE.DURATION(convertSecondsToDuration(seconds)) })
      timingBubble.addClass(`timing${isHighlighted ? ' is-highlighted' : ''}`)
      bubbles.push(timingBubble)

      return sections
        .flatMap((section, index) => {
          const { icon } = getTransitNotation(section, isHighlighted)
          const [departure, arrival] = directionsBySection[index]

          if (isHighlighted && section.type === metropia.TransportMode.TRANSIT) {
            const departureBubble = new H.ui.InfoBubble(departure, { content: section.departure.place.name })
            const arrivalBubble = new H.ui.InfoBubble(arrival, { content: section.arrival.place.name })
            bubbles.push(departureBubble, arrivalBubble)
          }

          return [new H.map.Marker(departure, { icon }), new H.map.Marker(arrival, { icon })]
        })
        .concat(
          sections.flatMap((section, index) => {
            const { color } = getTransitNotation(section, isHighlighted)
            const isDashed = isHighlighted && [...TRANSPORT_MODE.CYCLING.split(','), ...TRANSPORT_MODE.WALKING.split(',')].includes(section.type)
            const opacity = isHighlighted ? 0.9 : 0.6

            return getPolylineWithBorder(lineStringsBySection[index], color, isDashed, opacity)
          }),
        )
        .map((object) => object.setData(checkedIndex))
    })
    const onTap = (event) => {
      const index = event.target.getData()
      if (highlightedIndex === index) return

      setCheckedIndexes([...checkedIndexes.filter((checkedIndex) => checkedIndex !== index), index]) // move new highlighted index to last to display on the map
    }

    const map = mapRef.current
    const ui = uiRef.current
    bubbles.forEach((bubble) => ui.addBubble(bubble))
    group.addObjects(marksAndLines)
    group.addEventListener('tap', onTap, false)
    map.addObject(group)
    map.getViewModel().setLookAtData({ bounds: group.getBoundingBox() })

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      group.removeObjects(marksAndLines)
      group.removeEventListener('tap', onTap, false)
      map?.removeObject(group)
    }
  }, [mapRef, uiRef, directions, sortedRoutes, checkedIndexes, highlightedIndex])

  // render transit stops' dots
  React.useEffect(() => {
    if (sortedRoutes.length === 0 || checkedIndexes.length === 0) return

    const bubbles = []
    const dotMarkers = checkedIndexes
      .flatMap((checkedIndex) => sortedRoutes[checkedIndex].sections)
      .filter((section) => section.type === metropia.TransportMode.TRANSIT)
      .flatMap((section) => section.intermediateStops)
      .map(({ departure }) => {
        bubbles.push(new H.ui.InfoBubble(departure.place.location, { content: departure.place.name }))
        return new H.map.Marker(departure.place.location, { icon: MAP_ICON.DOT2 })
      })
      .map((marker, index) => marker.setData(index))

    const onEnter = (event) => bubbles[event.target.getData()].open()
    const onLeave = (event) => bubbles[event.target.getData()].close()

    const map = mapRef.current
    const ui = uiRef.current
    bubbles.forEach((bubble) => {
      bubble.close()
      ui.addBubble(bubble)
    })
    dotMarkers.map((marker) => marker.addEventListener('pointerenter', onEnter, false))
    dotMarkers.map((marker) => marker.addEventListener('pointerleave', onLeave, false))
    map.addObjects(dotMarkers)

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      dotMarkers.map((marker) => marker.removeEventListener('pointerenter', onEnter, false))
      dotMarkers.map((marker) => marker.removeEventListener('pointerleave', onLeave, false))
      map?.removeObjects(dotMarkers)
    }
  }, [mapRef, uiRef, sortedRoutes, checkedIndexes])

  // render highlighted bike marks
  React.useEffect(() => {
    if (sortedRoutes.length === 0 || highlightedIndex === null) return

    const bubbles = []
    const bikeMarkers = sortedRoutes[highlightedIndex].sections
      .filter((section) => TRANSPORT_MODE.CYCLING.includes(section.transport.mode) && typeof section.transport.agency === 'object')
      .flatMap(({ departure, arrival }) => {
        const directionBubble = [
          new H.ui.InfoBubble(departure.place.location, { content: departure.place.name }),
          new H.ui.InfoBubble(arrival.place.location, { content: arrival.place.name }),
        ]
        directionBubble.forEach((bubble) => bubble.addClass('station'))
        bubbles.push(...directionBubble)
        return [
          new H.map.Marker(departure.place.location, { icon: MAP_ICON.BIKE }),
          new H.map.Marker(arrival.place.location, { icon: MAP_ICON.BIKE }),
        ]
      })
      .map((marker, index) => marker.setData(index))

    const onEnter = (event) => bubbles[event.target.getData()].open()
    const onLeave = (event) => bubbles[event.target.getData()].close()

    const map = mapRef.current
    const ui = uiRef.current
    bubbles.forEach((bubble) => {
      bubble.close()
      ui.addBubble(bubble)
    })
    bikeMarkers.map((marker) => marker.addEventListener('pointerenter', onEnter, false))
    bikeMarkers.map((marker) => marker.addEventListener('pointerleave', onLeave, false))
    map.addObjects(bikeMarkers)

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      bikeMarkers.map((marker) => marker.removeEventListener('pointerenter', onEnter, false))
      bikeMarkers.map((marker) => marker.removeEventListener('pointerleave', onLeave, false))
      map?.removeObjects(bikeMarkers)
    }
  }, [mapRef, uiRef, sortedRoutes, highlightedIndex])

  // NOTE: render positions marks
  React.useEffect(() => {
    const map = mapRef.current
    if (map === null || group.positions.length === 0) return

    const hGroup = new H.map.Group()
    const markers = group.positions
      .map(
        (position) =>
          new H.map.DomMarker(position, {
            icon: MAP_ICON.MARK2,
            data: { position, onClick: () => selectPosition(position), isChecked: position.id === destination?.id },
          }),
      )
      .concat(origin !== null ? [new H.map.DomMarker(origin, { icon: MAP_ICON.DEPARTURE2, data: { position: origin } })] : [])
    hGroup.addObjects(markers)
    map.addObject(hGroup)
    map.getViewModel().setLookAtData({ bounds: hGroup.getBoundingBox() })

    return () => {
      hGroup?.removeObjects(markers)
      map?.removeObject(hGroup)
    }
  }, [mapRef, origin, group.positions, destination?.id, selectPosition])

  return (
    <Flex pointerEvents='none' zIndex='1' position='fixed' direction='column' w='100vw' h='100vh'>
      <GroupModifier group={modifyingGroup} isOpen={isModifierOpen} onClose={closeModifier} onSubmit={onModifierSubmit} />

      {hasGroup && (
        <Flex
          pointerEvents='none'
          pos='absolute'
          left='50%'
          top={{ base: '8.5rem', md: '5rem' }}
          transform='translateX(-50%)'
          p='0.5rem 1rem'
          color='white'
          whiteSpace='nowrap'
          borderRadius='md'
          bgColor='rgba(12, 159, 44, 0.8)'
          backdropFilter='blur(4px)'
          shadow='sm'
        >
          {i18.t('embedded.please-click-mark')}
        </Flex>
      )}

      <Flex pointerEvents='auto' flexWrap='wrap' px={{ base: '1rem', md: '2.5rem' }} py='1rem' w='100%' align='center' bgColor='blackAlpha.800'>
        <Stack direction='row' align='center'>
          <IconButton
            icon={<Icon as={isSiderOpen ? FiX : FiList} boxSize='1.25rem' color='white' />}
            variant='ghost'
            size='sm'
            onClick={onSiderToggle}
            _hover={{ bgColor: 'blackAlpha.500' }}
          />

          <Heading color='white' fontSize={{ base: 'md', md: 'xl' }}>
            {env.WEB_TITLE}
          </Heading>
        </Stack>

        {isVendor && (
          <Flex
            order={{ base: '3', md: 'initial' }}
            ml='auto'
            mt={{ base: '1rem', md: 'auto' }}
            w={{ base: `calc(${window.innerWidth}px - 1rem * 2)`, md: '20rem' }}
          >
            <QueryCombobox
              element={<Input size='sm' placeholder={i18.t('embedded.search-placeholder')} bgColor='white' />}
              fetcher={groupFetcher}
              propsOf={{ downshift: { itemToString: (item) => item?.name ?? '' }, query: { queryWhenEmpty: true } }}
              value={selectedGroup}
              onChange={(group) => {
                setSelectedGroup(group)
                setSearch({ ...parsedSearch, groupId: group.id })
              }}
              empty={<QueryCombobox.TextContent>{i18.t('common.empty-content')}</QueryCombobox.TextContent>}
              loading={<QueryCombobox.TextContent>{i18.t('common.searching')}</QueryCombobox.TextContent>}
            >
              {({ getItemProps, item, index, ...rest }) => (
                <QueryCombobox.Item key={index} px='1rem' py='0.75rem' {...getItemProps({ item, index })}>
                  <Text lineHeight='1' fontSize='sm' isTruncated>
                    {item.name}
                  </Text>
                </QueryCombobox.Item>
              )}
            </QueryCombobox>
          </Flex>
        )}

        {isVendor && (
          <Button order={{ base: '2', md: 'initial' }} ml={{ base: 'auto', md: '2.5rem' }} px='1rem' colorScheme='green' onClick={onCreate}>
            {i18.t('embedded.create-group')}
          </Button>
        )}
      </Flex>

      <Flex flex='1' overflow='hidden'>
        {view !== null && (
          <Flex
            zIndex='1'
            pos='relative'
            direction='column'
            width={{ base: 'var(--width-base)', md: 'var(--width-md)' }}
            sx={{ '--width-base': `calc(100vw - 2rem)`, '--width-md': '22.75rem' }}
            transform={isSiderOpen ? 'translateX(0%)' : 'translateX(-100%)'}
            transition='transform 100ms ease-in-out'
          >
            <Button
              pointerEvents='auto'
              zIndex='-1'
              pos='absolute'
              top='50%'
              left='100%'
              variant='unstyled'
              align='center'
              minWidth='1.5rem'
              h='4rem'
              bgColor='white'
              borderLeftRadius='0'
              borderRightRadius='lg'
              borderWidth='1px'
              transform='translateY(-50%)'
              shadow='bold'
              onClick={onSiderToggle}
            >
              <Icon as={isSiderOpen ? FiChevronLeft : FiChevronRight} color='gray.500' boxSize='1.25rem' />
            </Button>

            <Flex pointerEvents='auto' flex='1' direction='column' width='inherit' bgColor='white' shadow='bold' overflow='auto'>
              {view !== VIEW.GROUP && (
                <Flex px='1.5rem' py='0.75rem' bgColor='gray.50'>
                  <Button variant='outline' colorScheme='brand' size='xs' onClick={onBack} leftIcon={<Icon as={FiArrowLeft} />}>
                    {i18.t('common.back')}
                  </Button>
                </Flex>
              )}

              {view === VIEW.GROUP ? (
                <Flex direction='column' overflow='auto' h='100%'>
                  <Flex px='1.5rem' py='1rem' direction='column' borderBottomWidth='1px' bgColor='gray.50'>
                    <Stack>
                      <Heading fontSize='2xl'>{group.name}</Heading>
                      {isVendor && (
                        <Text color='gray.500' fontSize='xs'>
                          {getGroupLink(group)}
                        </Text>
                      )}
                    </Stack>

                    {isVendor && (
                      <ButtonGroup mt='0.5rem' size='sm' variant='outline' colorScheme='green'>
                        <Button onClick={onLinkCopy}>{i18.t('embedded.copy-link')}</Button>
                        <Button onClick={onUpdate}>{i18.t('embedded.update-group')}</Button>
                        <Confirmation
                          title={i18.t('embedded.delete-title')}
                          description={i18.t('embedded.delete-description')}
                          confirmText={i18.t('common.delete')}
                          cancelText={i18.t('common.cancel')}
                          onConfirm={onDeleteConfirm}
                        >
                          {({ open }) => (
                            <Button colorScheme='red' onClick={open}>
                              {i18.t('embedded.delete-group')}
                            </Button>
                          )}
                        </Confirmation>
                      </ButtonGroup>
                    )}
                  </Flex>

                  <Stack flex='1' px='1.5rem' py='1rem' spacing='1rem' overflowY='auto'>
                    {group.positions.map((position, index) => (
                      <Button
                        key={index}
                        px='0.75rem'
                        py='1rem'
                        variant='outline'
                        justifyContent='flex-start'
                        h='auto'
                        size='lg'
                        onClick={() => selectPosition(position)}
                      >
                        <Center flexShrink='0' boxSize='1.25rem' color='gray.300' fontSize='sm' borderWidth='1px' borderRadius='md'>
                          {index + 1}
                        </Center>
                        <Text ml='0.5rem' isTruncated>
                          {position.name}
                        </Text>
                      </Button>
                    ))}
                    {group.positions.length === 0 && <Text fontSize='sm'>{i18.t('embedded.empty-positions')}</Text>}
                  </Stack>

                  {isGroupValidating && <Loading pos='absolute' />}
                </Flex>
              ) : view === VIEW.LIST ? (
                <Stack spacing='0' divider={<Divider borderColor='gray.300' />} overflow='auto'>
                  <Stack spacing='0' paddingX='1rem' paddingY='0.5rem' bgColor='gray.100' borderTopRadius='inherit'>
                    <Flex align='center' width={{ base: 'calc(var(--width-base) - 1.5rem * 2)', md: 'calc(var(--width-md) - 1.5rem * 2)' }}>
                      <Text flexShrink='0' width='2.5rem' color='gray.500' fontSize='sm'>
                        {i18.t('common.from')}
                      </Text>

                      <QueryCombobox
                        element={<Input size='xs' fontSize='sm' fontWeight='600' placeholder={i18.t('common.empty-origin')} bgColor='white' />}
                        fetcher={addressFetcher}
                        propsOf={{ downshift: { itemToString: (item) => item?.name ?? '' }, query: { fallbackData: [{ data: [currentLocation] }] } }}
                        value={origin}
                        onChange={(position) => setDirections([position, destination])}
                        empty={<QueryCombobox.TextContent>{i18.t('common.empty-content')}</QueryCombobox.TextContent>}
                        loading={<QueryCombobox.TextContent>{i18.t('common.searching')}</QueryCombobox.TextContent>}
                      >
                        {({ getItemProps, item, index, ...rest }) => (
                          <QueryCombobox.Item key={index} px='1rem' py='0.75rem' {...getItemProps({ item, index })}>
                            <Flex align='center' lineHeight='1' isTruncated>
                              <Icon as={Position.CategoryIcon[item.category]} mr='1.125rem' flexShrink='0' boxSize='1.25rem' color='gray.600' />
                              <Text as='span' fontSize='sm' isTruncated>
                                {item.name}
                              </Text>
                            </Flex>
                          </QueryCombobox.Item>
                        )}
                      </QueryCombobox>
                    </Flex>

                    <Flex>
                      <Text flexShrink='0' width='2.5rem' color='gray.500' fontSize='sm'>
                        {i18.t('common.to')}
                      </Text>
                      <Text fontSize='sm' fontWeight='600'>
                        {destination?.name}
                      </Text>
                    </Flex>

                    <Stack direction='row' spacing='1rem' align='center'>
                      <Text flexShrink='0' fontSize='sm' fontWeight='600'>
                        {i18.t('trip-planning.label.sort-preference')}
                      </Text>

                      <Flex flexDirection='row' flexWrap='wrap' align='center'>
                        <Stack mr='0.5rem' direction='row' spacing='0.25rem' align='center'>
                          <Text flexShrink='0' color='gray.500' fontSize='sm'>
                            {i18.t('trip-planning.label.sort-preference-first')}
                          </Text>
                          <Selector
                            options={SORT_OPTIONS}
                            value={sort.first}
                            onChange={(first) => {
                              setSort({ ...sort, first })
                              setCheckedIndexes([0])
                            }}
                          />
                        </Stack>

                        <Stack direction='row' spacing='0.25rem' align='center'>
                          <Text flexShrink='0' color='gray.500' fontSize='sm'>
                            {i18.t('trip-planning.label.sort-preference-then')}
                          </Text>
                          <Selector
                            options={SORT_OPTIONS}
                            value={sort.then}
                            onChange={(then) => {
                              setSort({ ...sort, then })
                              setCheckedIndexes([0])
                            }}
                          />
                        </Stack>
                      </Flex>
                    </Stack>

                    <Stack direction='row' spacing='0.25rem' align='center'>
                      <Icon as={Icons.Clock} color='gray.500' boxSize='1.25rem' />
                      <Text color='gray.500' fontSize='sm'>
                        {[LEAVE_TYPE_TEXT[option.leaveType]]
                          .concat(
                            option.leaveType !== metropia.LeaveType.LEAVE_NOW && isValid(option.date) && isValid(option.time)
                              ? [
                                  format(option.date, i18.t('format-pattern.date-picker.input'), { locale: window.datefns_locale }),
                                  format(option.time, i18.t('format-pattern.time-picker.input'), { locale: window.datefns_locale }),
                                ]
                              : [],
                          )
                          .join(' ')}
                      </Text>
                    </Stack>
                  </Stack>

                  <Flex paddingY='0.5rem' direction='column' align='flex-start' overflow='auto'>
                    <CheckboxGroup colorScheme='green' value={checkedIndexes.map(String)} onChange={onCheckboxChange}>
                      <Stack spacing='0' divider={<Divider />} width='100%'>
                        {sortedRoutes.map((route, index) => (
                          <Stack
                            key={index}
                            direction='row'
                            spacing='1rem'
                            paddingX='1rem'
                            align='center'
                            bgColor={checkedIndexes.includes(index) ? 'brand.50' : 'transparent'}
                            aria-selected={index === highlightedIndex}
                            _hover={{ bgColor: 'gray.50' }}
                            _selected={{ bgColor: 'gray.300' }}
                          >
                            <Checkbox value={String(index)} isDisabled={checkedIndexes.length === 3 && !checkedIndexes.includes(index)} />

                            <RouteCard
                              useGoogle={option.useGoogle}
                              route={route}
                              flex='1'
                              paddingY='1rem'
                              onClick={() => {
                                setCheckedIndexes([index])
                                setView(VIEW.DETAIL)
                              }}
                            />
                          </Stack>
                        ))}
                      </Stack>
                    </CheckboxGroup>

                    {routes.length === 0 && (
                      <Text paddingY='1rem' paddingX='1.5rem' fontSize='sm' color='gray.700'>
                        {isRouteValidating ? i18.t('common.searching') : i18.t('trip-planning.empty-content')}
                      </Text>
                    )}
                  </Flex>

                  {isRouteValidating && routes.length === 0 && <Loading pos='absolute' borderRadius='inherit' />}
                </Stack>
              ) : (
                view === VIEW.DETAIL && <RouteDetail option={option} route={sortedRoutes[highlightedIndex]} directions={directions} />
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default Embedded
