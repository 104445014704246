import { parse } from 'date-fns'
import { Icons } from '@metropia/react-tools'
import BiTargetLock from '@meronex/icons/bi/BiTargetLock'
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp'

import { LANG } from 'src/configs'

/* eslint-disable camelcase */
export class Position {
  constructor(props) {
    const { id = null, name = '', address = '', category = Position.Category.Search, lat = null, lng = null, googleTypes = [] } = props ?? {}

    Object.assign(this, {
      id,
      name,
      address,
      category,
      lat,
      lng,
      // https://developers.google.com/maps/documentation/places/web-service/supported_types?hl=zh-tw
      googleTypes,
    })
  }

  get type() {
    return Position.Type.Place
  }

  static GoogleStationTypes = ['bus_station', 'light_rail_station', 'subway_station', 'train_station', 'transit_station']

  static Type = {
    Restaurant: -3, // front-end only
    Hotel: -2, // front-end only
    ScenicSpot: -1, // front-end only
    Place: 0, // front-end only
    Bike: 1,
    Carpool: 5,
  }

  static TypeText = {
    [Position.Type.Restaurant]: 'common.tourism.restaurant',
    [Position.Type.Hotel]: 'common.tourism.hotel',
    [Position.Type.ScenicSpot]: 'common.tourism.scenic-spot',
  }

  // front-end only
  static Category = {
    Search: -1,
    Current: 0,
  }

  static CategoryIcon = {
    [Position.Category.Search]: Icons.MapMarkerPin,
    [Position.Category.Current]: BiTargetLock,
  }

  static Separator = ','

  static stringify(position) {
    return Position.isValid(position) ? [position.lat, position.lng].join(Position.Separator) : ''
  }

  static parse(stringified) {
    const [lat = null, lng = null] = stringified?.split(Position.Separator).map((coordinate) => Number(coordinate)) ?? []

    return { lat, lng }
  }

  static isValid(position) {
    return (
      typeof position?.lat === 'number' &&
      typeof position?.lng === 'number' &&
      position?.lat > -90 &&
      position?.lat < 90 &&
      position?.lng > -180 &&
      position?.lng < 180
    )
  }

  static getPicturesFromTourism(pictureObject) {
    return Object.entries(pictureObject)
      .filter(([key]) => /pictureUrl/gi.test(key))
      .map(([, value]) => value)
  }

  static mapKeysFromApi(props) {
    const { product_id, destination_name, destination_latitude, destination_longitude, destination_address } = props ?? {}

    return {
      id: product_id,
      name: destination_name,
      address: destination_address,
      lat: destination_latitude,
      lng: destination_longitude,
    }
  }
}

export class BikePosition extends Position {
  constructor(props) {
    super(props)

    const { version = null, availableBikes = null, availableDocks = null, ...rest } = props ?? {}

    Object.assign(this, {
      ...rest,
      version,
      availableBikes,
      availableDocks,
    })
  }

  get type() {
    return Position.Type.Bike
  }

  static mapKeysFromApi(props) {
    const { lang, StationUID, StationName, StationAddress, StationPosition, ServiceType, AvailableRentBikes, AvailableReturnBikes } = props || {}

    return {
      id: StationUID,
      name: lang === LANG.zhTW ? StationName.Zh_tw : StationName.En,
      address: lang === LANG.zhTW ? StationAddress.Zh_tw : StationAddress.En,
      lat: StationPosition.PositionLat,
      lng: StationPosition.PositionLon,
      version: ServiceType,
      availableBikes: AvailableRentBikes,
      availableDocks: AvailableReturnBikes,
    }
  }
}

export class CarpoolPosition extends Position {
  constructor(props) {
    super(props)

    const { plateNumber = null, pickupTime = null, destination = null, ...rest } = props ?? {}

    Object.assign(this, {
      ...rest,
      plateNumber,
      pickupTime: pickupTime === null ? null : parse(pickupTime, 'HH:mm:ss', new Date()),
      destination,
    })
  }

  get type() {
    return Position.Type.Carpool
  }

  get stationId() {
    return this.id.split('_')[0]
  }

  static mapKeysFromApi(props) {
    const { lang, StationUID, StationName, StationAddress, StationPosition, PlateNumber, PickupTime, Destination } = props || {}

    return {
      id: `${StationUID}_${PlateNumber}`,
      name: lang === LANG.zhTW ? StationName.Zh_tw : StationName.En,
      address: lang === LANG.zhTW ? StationAddress.Zh_tw : StationAddress.En,
      lat: StationPosition.PositionLat,
      lng: StationPosition.PositionLon,
      plateNumber: PlateNumber,
      pickupTime: PickupTime,
      destination: lang === LANG.zhTW ? Destination.Zh_tw : Destination.En,
    }
  }
}

export class ScenicSpotPosition extends Position {
  constructor(props) {
    super(props)

    const { distance = 0, openTime = null, pictures = [], ...rest } = props ?? {}

    Object.assign(this, {
      ...rest,
      distance,
      openTime,
      pictures,
      class: rest.class ?? null,
    })
  }

  get type() {
    return Position.Type.ScenicSpot
  }

  static mapKeysFromApi(props) {
    // NOTE: may have not the keys of `Class1`
    const { center, ScenicSpotID, ScenicSpotName, OpenTime, Picture, Address, Position: PositionFromApi, Class1 } = props || {}

    return {
      id: ScenicSpotID,
      name: ScenicSpotName,
      address: Address,
      lat: PositionFromApi.PositionLat,
      lng: PositionFromApi.PositionLon,

      distance: new H.geo.Point(PositionFromApi.PositionLat, PositionFromApi.PositionLon).distance(center), // meters
      openTime: OpenTime,
      pictures: Position.getPicturesFromTourism(Picture),
      class: Class1,
    }
  }
}

export class HotelPosition extends Position {
  constructor(props) {
    super(props)

    const { distance = 0, pictures = [], ...rest } = props ?? {}

    Object.assign(this, {
      ...rest,
      distance,
      pictures,
      class: rest.class ?? null,
    })
  }

  get type() {
    return Position.Type.Hotel
  }

  static mapKeysFromApi(props) {
    const { center, HotelID, HotelName, Picture, Address, Position: PositionFromApi, Class } = props || {}

    return {
      id: HotelID,
      name: HotelName,
      address: Address,
      lat: PositionFromApi.PositionLat,
      lng: PositionFromApi.PositionLon,

      distance: new H.geo.Point(PositionFromApi.PositionLat, PositionFromApi.PositionLon).distance(center), // meters
      pictures: Position.getPicturesFromTourism(Picture),
      class: Class,
    }
  }
}

export class RestaurantPosition extends Position {
  constructor(props) {
    super(props)

    const { distance = 0, openTime = null, pictures = [], ...rest } = props ?? {}

    Object.assign(this, {
      ...rest,
      distance,
      openTime,
      pictures,
      class: rest.class ?? null,
    })
  }

  get type() {
    return Position.Type.Restaurant
  }

  static mapKeysFromApi(props) {
    const { center, RestaurantID, RestaurantName, OpenTime, Picture, Address, Position: PositionFromApi, Class } = props || {}

    return {
      id: RestaurantID,
      name: RestaurantName,
      address: Address,
      lat: PositionFromApi.PositionLat,
      lng: PositionFromApi.PositionLon,

      distance: new H.geo.Point(PositionFromApi.PositionLat, PositionFromApi.PositionLon).distance(center), // meters
      openTime: OpenTime,
      pictures: Position.getPicturesFromTourism(Picture),
      class: Class,
    }
  }
}
