import i18 from 'i18next'

import { LANG } from './lang'

export const LOCATED_UNIT = {}
i18.on('initialized', () => {
  const tTW = i18.getFixedT(LANG.zhTW)
  const tUS = i18.getFixedT(LANG.enUS)

  LOCATED_UNIT[window.LOCATION.TAIWAN] = {
    TIME_COST: tTW('common.unit.time-cost'),
    TIME_SPEED: tTW('common.unit.time-speed'),
    DOLLAR: tTW('common.unit.dollar'),
  }
  LOCATED_UNIT[window.LOCATION.HOUSTON] = {
    TIME_COST: tUS('common.unit.time-cost'),
    TIME_SPEED: tUS('common.unit.time-speed'),
    DOLLAR: tUS('common.unit.dollar'),
  }
})
