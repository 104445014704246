import React from 'react'
import PropTypes from 'prop-types'
import { format, addMinutes } from 'date-fns'
import { Stack, Flex, Box, Button, Text, Circle, Divider, Icon, IconButton, Tooltip, Image } from '@chakra-ui/react'
import { Icons } from '@metropia/react-tools'
import i18 from 'i18next'
import AiFillInfoCircle from '@meronex/icons/ai/AiFillInfoCircle'
import BiHotel from '@meronex/icons/bi/BiHotel'
import RiRestaurant2Line from '@meronex/icons/ri/RiRestaurant2Line'

import { Position } from 'src/libs/models'
import { getDistanceWithUnit } from 'src/libs/utils'
import { ReactComponent as Camera } from 'src/assets/images/camera.svg'
import { ReactComponent as Carpool } from 'src/assets/images/carpool.svg'
import { ReactComponent as Youbike } from 'src/assets/images/youbike.svg'

export const Card = React.forwardRef(
  ({ title, content, onClick, isChecked, imageSrc, actionText, onActionClick, containerProps, imgProps, ...rest }, ref) => {
    return (
      <Flex
        ref={ref}
        direction='column'
        width={{ base: '100%', md: '19.125rem' }}
        bgColor='white'
        borderRadius='xl'
        shadow='bold'
        border='none'
        onClick={onClick}
        role='group'
        _checked={{ shadow: '0 0 0 4px #0c9f2c' }}
        {...(isChecked ? { 'data-checked': true } : null)}
        {...rest}
      >
        {imageSrc && <Image src={imageSrc} alt={imageSrc} height='7.5rem' borderTopRadius='inherit' fit='cover' {...imgProps} />}

        <Flex px='0.75rem' py='0.5rem' direction='column' {...containerProps}>
          <Box fontWeight='600'>{title}</Box>

          <Box flex='1' marginTop='0.5rem' fontSize='sm' color='gray.700'>
            {content}
          </Box>
        </Flex>

        {onActionClick && (
          <Button
            mt='auto'
            mb='1rem'
            mx='1rem'
            variant='outline'
            size='lg'
            color='green.500'
            _groupChecked={{ color: 'white', bgColor: 'green.500' }}
            onClick={onActionClick}
          >
            {actionText}
          </Button>
        )}
      </Flex>
    )
  },
)

Card.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
  imageSrc: PropTypes.string,
  actionText: PropTypes.node,
  onActionClick: PropTypes.func,
  containerProps: PropTypes.object,
  imgProps: PropTypes.object,
}

export const PlaceCard = React.forwardRef(({ position, ...rest }, ref) => {
  return <Card ref={ref} title={position.name} content={position.address} actionText={i18.t('trip-planning.position.view-route')} {...rest} />
})

PlaceCard.propTypes = {
  position: PropTypes.instanceOf(Position),
}

export const BikeCard = React.forwardRef(({ position, ...rest }, ref) => {
  return (
    <Card
      ref={ref}
      title={
        <Stack direction='row'>
          <Circle boxSize='1.5rem' borderWidth='1px'>
            <Icon as={Youbike} boxSize='1rem' />
          </Circle>
          <Text as='span'>{i18.t('trip-planning.bike.title', { version: Number(position.version).toFixed(1) })}</Text>
        </Stack>
      }
      content={
        <Flex direction='column' height='100%'>
          <Stack marginBottom='1rem' spacing='0.25rem'>
            <Text>{position.name}</Text>
            <Text fontSize='xs'>{position.address}</Text>
          </Stack>

          <Stack marginTop='auto' direction='row' divider={<Divider height='0.75rem' orientation='vertical' />} align='center'>
            <Stack direction='row' spacing='0.25rem' align='center'>
              <Icon as={Icons.TransportBiking} boxSize='1.25rem' />
              <Text color='green.500' fontSize='1rem' fontWeight='600'>
                {position.availableBikes}
              </Text>
              <Text>{i18.t('trip-planning.bike.available-bikes')}</Text>
            </Stack>

            <Stack direction='row' spacing='0.25rem' align='center'>
              <Text color='black' fontSize='1rem' fontWeight='600'>
                {position.availableDocks}
              </Text>
              <Text>{i18.t('trip-planning.bike.available-docks')}</Text>
              <Tooltip
                hasArrow
                placement='top'
                label={i18.t('trip-planning.hint.cycling-card')}
                fontSize='sm'
                shadow='lg'
                color='gray.800'
                bg='green.100'
                closeOnClick={false}
              >
                <IconButton
                  aria-label={i18.t('trip-planning.hint.cycling-card')}
                  icon={<Icon as={AiFillInfoCircle} boxSize='0.875rem' color='gray.600' />}
                  variant='unstyled'
                  size='xs'
                />
              </Tooltip>
            </Stack>
          </Stack>
        </Flex>
      }
      actionText={i18.t('trip-planning.position.view-route')}
      {...rest}
    />
  )
})

BikeCard.propTypes = {
  position: PropTypes.instanceOf(Position),
}

export const CarpoolCard = React.forwardRef(({ position, ...rest }, ref) => {
  return (
    <Card
      ref={ref}
      title={
        <Stack direction='row'>
          <Circle boxSize='1.5rem' borderWidth='1px'>
            <Icon as={Carpool} boxSize='1.25rem' />
          </Circle>
          <Text>{i18.t('trip-planning.carpool.title')}</Text>
        </Stack>
      }
      content={
        <Stack>
          <Text>{position.name}</Text>
          <Stack spacing='0.25rem' fontSize='xs'>
            <Text>
              {i18.t('trip-planning.carpool.pickup-location')}
              {position.address}
            </Text>
            <Text>
              {i18.t('trip-planning.carpool.pickup-time')}
              {[
                format(position.pickupTime, 'M/d (EEEEE) a h:mm', { locale: window.datefns_locale }),
                format(addMinutes(position.pickupTime, 15), 'h:mm', { locale: window.datefns_locale }),
              ].join(' - ')}
            </Text>
            <Text>
              {i18.t('trip-planning.carpool.driver-destination')}
              {position.destination}
            </Text>
          </Stack>
        </Stack>
      }
      actionText={i18.t('trip-planning.carpool.request-carpooling')}
      {...rest}
    />
  )
})

CarpoolCard.propTypes = {
  position: PropTypes.instanceOf(Position),
}

const POSITION_ICON_BY_TYPE = {
  [Position.Type.ScenicSpot]: Camera,
  [Position.Type.Hotel]: BiHotel,
  [Position.Type.Restaurant]: RiRestaurant2Line,
}
export const TourismCard = React.forwardRef(({ position, ...rest }, ref) => {
  return (
    <Card
      ref={ref}
      imageSrc={position.pictures[0]}
      title={
        <Stack direction='row'>
          <Circle boxSize='1.5rem' borderWidth='1px' _groupChecked={{ bgColor: 'brand.500', borderColor: 'brand.500' }}>
            <Icon as={POSITION_ICON_BY_TYPE[position.type]} color='gray.600' boxSize='1rem' _groupChecked={{ color: 'white' }} />
          </Circle>
          <Text>{position.name}</Text>
        </Stack>
      }
      content={
        <Stack>
          <Stack direction='row' spacing='0.25rem' divider={<Text as='span'>・</Text>}>
            <Text>
              {position.type === Position.Type.ScenicSpot
                ? `${i18.t('common.tourism.scenic-spot')}${position.class ? ` (${position.class})` : ''}`
                : position.class}
            </Text>
            <Text>{getDistanceWithUnit(position.distance)}</Text>
          </Stack>
          <Stack spacing='0.25rem' fontSize='xs'>
            <Text>{position.address}</Text>
            {position.openTime && <Text>{position.openTime}</Text>}
          </Stack>
        </Stack>
      }
      containerProps={{ maxH: '4rem', overflow: 'scroll' }}
      imgProps={{ h: '6rem' }}
      _checked={{ shadow: '0 0 0 4px #1266c7' }}
      {...rest}
    />
  )
})

TourismCard.propTypes = {
  position: PropTypes.instanceOf(Position),
}
