export const VIEW = {
  SEARCH: 'search',
  GROUP: 'group',
  LIST: 'list',
  DETAIL: 'detail',
}

export const MD_WIDTH = 768
export const VIEWPORT_PADDING = {
  // top, right, bottom, left
  MD: [48, 24, 424, 24],
  LG: [80, 24, 300, 480],
}
