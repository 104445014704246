import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import {
  Button,
  ButtonGroup,
  Text,
  Flex,
  Center,
  Textarea,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Icon,
} from '@chakra-ui/react'
import i18 from 'i18next'
import TextareaAutosize from 'react-textarea-autosize'
import { FormikChakraForm, formikHelper } from '@metropia/react-tools'
import FiPlus from '@meronex/icons/fi/FiPlus'

import * as api from 'src/libs/api'
import { Group, Position } from 'src/libs/models'
import { QueryCombobox } from 'src/components'
import VALIDATION_SCHEMA from './validation-schema'

const ChakraTextareaAutosize = React.forwardRef((props, ref) => <Textarea ref={ref} as={TextareaAutosize} rows='1' resize='none' {...props} />)

export const GroupModifier = ({ isOpen, onClose, group, onSubmit }) => {
  const addressFetcher = React.useCallback((index, keyword) => api.fetchPositionListByAddress({ address: keyword }), [])

  return (
    <Modal size='md' scrollBehavior='outside' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <Formik validateOnMount enableReinitialize initialValues={group} validationSchema={VALIDATION_SCHEMA} onSubmit={onSubmit}>
        {(formik) => {
          return (
            <FormikChakraForm>
              <ModalContent>
                <ModalHeader as={Stack} py='0.75rem' direction='row' spacing='1rem' borderBottomWidth='1px'>
                  <Field name='name'>
                    {({ field, meta }) => (
                      <FormControl as={Stack} isInvalid={formikHelper.checkIsInvalid(meta)}>
                        <Stack direction='row' align='center'>
                          <FormLabel m='0' htmlFor={field.name} flexShrink='0' color='green.500' fontSize='md' fontWeight='500'>
                            {i18.t('embedded.group-name')}
                          </FormLabel>

                          <ChakraTextareaAutosize
                            {...field}
                            id={field.name}
                            variant='unstyled'
                            lineHeight='1.5'
                            fontSize='md'
                            placeholder={i18.t('embedded.group-name-placeholder')}
                          />
                        </Stack>

                        <FormErrorMessage>{i18.t(meta.error)}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </ModalHeader>

                <ModalBody as={Stack} py='2rem' spacing='2rem'>
                  <Field name='positions'>
                    {({ field: fieldOfPositions }) => (
                      <>
                        {fieldOfPositions.value.map((position, index) => (
                          <Stack key={index} direction='row'>
                            <Flex py='0.25rem'>
                              <Center boxSize='1.25rem' color='white' fontSize='sm' bgColor='gray.800' borderRadius='md'>
                                {index + 1}
                              </Center>
                            </Flex>

                            <Stack flex='1'>
                              <Field name={`${fieldOfPositions.name}.${index}.name`}>
                                {({ field, meta }) => (
                                  <FormControl as={Stack} isInvalid={formikHelper.checkIsInvalid(meta)}>
                                    <Stack direction='row' align='flex-start'>
                                      <FormLabel m='0' py='0.25rem' minWidth='3em' fontSize='sm' htmlFor={field.name}>
                                        {i18.t('embedded.position-name')}
                                      </FormLabel>

                                      <ChakraTextareaAutosize
                                        {...field}
                                        id={field.name}
                                        px='0.75rem'
                                        py='0.375rem'
                                        lineHeight='1.25'
                                        placeholder={i18.t('embedded.position-name-placeholder')}
                                      />
                                    </Stack>

                                    <FormErrorMessage>{i18.t(meta.error)}</FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <Field name={`${fieldOfPositions.name}.${index}.address`}>
                                {({ field, meta }) => (
                                  <FormControl as={Stack} isInvalid={formikHelper.checkIsInvalid(meta)}>
                                    <Stack direction='row' align='flex-start'>
                                      <FormLabel m='0' py='0.25rem' minWidth='3em' fontSize='sm' htmlFor={field.name}>
                                        {i18.t('embedded.position-address')}
                                      </FormLabel>

                                      <Flex w='100%' pos='relative'>
                                        <QueryCombobox
                                          fetcher={addressFetcher}
                                          propsOf={{ downshift: { itemToString: (item) => item?.address ?? '' } }}
                                          value={fieldOfPositions.value[index]}
                                          onChange={(position) =>
                                            formik.setFieldValue(
                                              `${fieldOfPositions.name}.${index}`,
                                              new Position({ ...position, name: fieldOfPositions.value[index].name }),
                                            )
                                          }
                                          empty={<QueryCombobox.TextContent>{i18.t('common.empty-content')}</QueryCombobox.TextContent>}
                                          loading={<QueryCombobox.TextContent>{i18.t('common.searching')}</QueryCombobox.TextContent>}
                                          renderInput={(props) => (
                                            <ChakraTextareaAutosize
                                              {...props}
                                              {...field}
                                              id={field.name}
                                              rows='1'
                                              px='0.75rem'
                                              py='0.375rem'
                                              lineHeight='1.25'
                                              placeholder={i18.t('embedded.position-address-placeholder')}
                                            />
                                          )}
                                        >
                                          {({ getItemProps, item, index, ...rest }) => (
                                            <QueryCombobox.Item key={index} px='1rem' py='0.75rem' {...getItemProps({ item, index })}>
                                              <Flex align='center' lineHeight='1' isTruncated>
                                                <Icon
                                                  as={Position.CategoryIcon[item.category]}
                                                  mr='1.125rem'
                                                  flexShrink='0'
                                                  boxSize='1.25rem'
                                                  color='gray.600'
                                                />
                                                <Text as='span' fontSize='sm' isTruncated>
                                                  {item.name}
                                                </Text>
                                              </Flex>
                                            </QueryCombobox.Item>
                                          )}
                                        </QueryCombobox>
                                      </Flex>
                                    </Stack>

                                    <FormErrorMessage>{i18.t(meta.error)}</FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                            </Stack>

                            <Flex py='0.25rem'>
                              <Button
                                size='xs'
                                variant='ghost'
                                colorScheme='green'
                                isDisabled={fieldOfPositions.value.length === 1}
                                onClick={() =>
                                  formik.setFieldValue(
                                    fieldOfPositions.name,
                                    fieldOfPositions.value.filter((_, i) => i !== index),
                                  )
                                }
                              >
                                {i18.t('common.delete')}
                              </Button>
                            </Flex>
                          </Stack>
                        ))}

                        <Flex>
                          <Button
                            size='xs'
                            variant='ghost'
                            colorScheme='green'
                            leftIcon={<Icon as={FiPlus} boxSize='0.75rem' />}
                            iconSpacing='0.25rem'
                            onClick={() => formik.setFieldValue(fieldOfPositions.name, [...fieldOfPositions.value, new Position()])}
                          >
                            {i18.t('embedded.position')}
                          </Button>
                        </Flex>
                      </>
                    )}
                  </Field>
                </ModalBody>

                <ModalFooter as={ButtonGroup} colorScheme='green' size='sm' borderTopWidth='1px'>
                  <Button variant='outline' onClick={onClose} isDisabled={formik.isSubmitting}>
                    {i18.t('common.cancel')}
                  </Button>
                  <Button type='submit' isLoading={formik.isSubmitting}>
                    {i18.t('common.save')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </FormikChakraForm>
          )
        }}
      </Formik>
    </Modal>
  )
}

GroupModifier.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  group: PropTypes.instanceOf(Group),
  onSubmit: PropTypes.func,
}
