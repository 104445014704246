import { tripService } from 'src/libs/api/_service'
import { TrafficEvent } from 'src/libs/models'

export const fetchStationMap = ({ stationId } = {}) => {
  return tripService({
    method: 'GET',
    url: '/traffic/station-mappings',
    params: {
      station_id: stationId,
    },
  }).then((response) => {
    return { ...response, data: response.data.data }
  })
}

export const fetchTrafficEvents = async () => {
  const { data: stationMap } = await fetchStationMap()

  return tripService({
    method: 'GET',
    url: '/traffic/alerts',
  }).then((response) => {
    return {
      ...response,
      data: response.data.data?.alerts?.map?.((event) => new TrafficEvent({ ...TrafficEvent.mapKeysFromApi(event), stationMap })) ?? [],
    }
  })
}
