export const clickAnchor = (url, attributeEntries) => {
  const anchorElement = document.createElement('a')

  anchorElement.href = url
  attributeEntries.forEach(([key, value]) => anchorElement.setAttribute(key, value))

  document.body.appendChild(anchorElement)
  anchorElement.click()
  document.body.removeChild(anchorElement)
}
