import React from 'react'
import ReactDOM from 'react-dom'
import { SWRConfig } from 'swr'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ChakraProvider } from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { Loading } from '@metropia/react-tools'
import i18next from 'i18next'

import { THEME, SWR_OPTIONS } from 'src/configs'
import reportWebVitals from './reportWebVitals'
import './i18n'

import Directions from 'src/views/directions'
import Marketing from 'src/views/marketing'
import Pharmacy from 'src/views/pharmacy'
import Embedded from 'src/views/embedded'
const HereRoutingComparison = React.lazy(() => import('src/views/here-routing-comparison'))
const TripPlanning = React.lazy(() => import('src/views/trip-planning'))
const NotFound = React.lazy(() => import('src/views/not-found'))

i18next.on('initialized', () => {
  ReactDOM.render(
    <React.StrictMode>
      <SWRConfig value={SWR_OPTIONS}>
        <ChakraProvider theme={THEME}>
          {/* figure out 碧筵綰 issue - https://www.wikiwand.com/zh-tw/%E5%BE%AE%E8%BB%9F%E6%AD%A3%E9%BB%91%E9%AB%94 */}
          <Global styles={`@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&display=swap');`} />

          <HelmetProvider>
            <React.Suspense fallback={<Loading usePortal />}>
              <BrowserRouter>
                <Routes>
                  <Route path='here-routing-comparison' element={<HereRoutingComparison />} />
                  <Route path='/' element={<TripPlanning />}>
                    <Route path='taiwan' element={<Directions />} />
                    <Route path='houston' element={<Directions />} />
                    <Route path='marketing' element={<Marketing />} />
                    <Route path='pharmacy' element={<Pharmacy />} />
                    <Route path='embedded' element={<Embedded />} />
                    <Route path='embedded/directions' element={<Embedded />} />
                    <Route path='trip-planning/directions' element={<Directions />} />
                    <Route path='' element={<Navigate to='taiwan' />} />
                  </Route>

                  <Route path='*' element={<NotFound />} />
                </Routes>
              </BrowserRouter>
            </React.Suspense>
          </HelmetProvider>
        </ChakraProvider>
      </SWRConfig>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
