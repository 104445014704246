import React from 'react'
import { useLocation } from 'react-router-dom'
import { Formik } from 'formik'
import { isValid, getDay, format, set } from 'date-fns'
import { Button, Flex, Text, Stack, CheckboxGroup, Checkbox, Divider, Icon, Portal, Collapse, useDisclosure } from '@chakra-ui/react'
import { FormikChakraForm, Loading, Icons, toast } from '@metropia/react-tools'
import H from '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp'
import i18 from 'i18next'

import { FORMAT_OF_DATE } from 'src/configs'
import * as api from 'src/libs/api'
import { Position } from 'src/libs/models'
import { checkIsYouBike, checkIsIRent, convertSecondsToDuration } from 'src/libs/utils'
import {
  DirectionsSearchboxes,
  TourismCard,
  Selector,
  RouteCard,
  RouteDetail,
  TripPlanningOption,
  NavigationArrow,
  NAVIGATION_DIRECTION,
} from 'src/components'

import {
  Context,
  MAP_ICON,
  VIEW,
  MD_WIDTH,
  VIEWPORT_PADDING,
  LEAVE_TYPE_TEXT,
  SORT_OPTIONS,
  TRANSIT_MODE_TO_METROPIA_API,
  TRANSIT_MODE_TO_GOOGLE_API,
  TRANSPORT_MODE,
  INITIAL_SORT,
  VALIDATION_SCHEMA,
} from 'src/views/trip-planning/constants'
import { sortRoutes, getFromEncodedPolyline, getPolylineWithBorder, getTransitNotation, getInitialOption } from 'src/views/trip-planning/utils'
import { TOURISM_TYPE_OPTIONS } from './constants'

function Directions(props) {
  const location = useLocation()
  const { env, mapRef, uiRef, mapCtrlContainerRef } = React.useContext(Context)

  const [view, setView] = React.useState(VIEW.SEARCH)
  const [directions, setDirections] = React.useState([null, location.state?.destination ? new Position(location.state.destination) : null])
  const [option, setOption] = React.useState(getInitialOption(env))
  const [sort, setSort] = React.useState(INITIAL_SORT)
  const [routes, setRoutes] = React.useState([])
  const [tourisms, setTourisms] = React.useState([])
  const [isTourismLoading, setIsTourismLoading] = React.useState(false)
  const [tourismType, setTourismType] = React.useState(Position.Type.ScenicSpot)
  const [tourismId, setTourismId] = React.useState(null)
  const [checkedIndexes, setCheckedIndexes] = React.useState([])
  const { isOpen: isTourismVisible, onToggle: toggleTourism } = useDisclosure({ defaultIsOpen: true })
  const { isOpen: isDetailExtended, onToggle: toggleDetail } = useDisclosure({ defaultIsOpen: false })

  const highlightedIndex = React.useMemo(() => checkedIndexes.slice(-1)[0] ?? null, [checkedIndexes])
  const sortedRoutes = React.useMemo(() => sortRoutes(sortRoutes(routes, sort.then), sort.first), [routes, sort])

  const selectTourism = React.useCallback(
    (position) => {
      if (position.id === tourismId) {
        setTourismId(null)
      } else {
        setTourismId(position.id)
        mapRef.current.setCenter(position)
        document.querySelector(`[data-scroll-target=${position.id}`).scrollIntoView({ behavior: 'smooth' })
      }
    },
    [mapRef, tourismId],
  )
  const updateTourisms = React.useCallback(
    async (to, type) => {
      let tourisms = []
      try {
        setTourismType(type)
        setIsTourismLoading(true)

        switch (type) {
          case Position.Type.Restaurant:
            tourisms = (await api.fetchRestaurants({ location: to, radius: env.TOURISMS.RESTAURANT_RADIUS })).data ?? []
            break

          case Position.Type.Hotel:
            tourisms = (await api.fetchHotels({ location: to, radius: env.TOURISMS.HOTEL_RADIUS })).data ?? []
            break

          case Position.Type.ScenicSpot:
          default:
            tourisms = (await api.fetchScenicSpots({ location: to, radius: env.TOURISMS.SCENIC_SPOT_RADIUS })).data ?? []
            break
        }
      } catch (error) {
        console.error(`Error of ${i18.t(Position.TypeText[type])}: `, error.message)
      } finally {
        setIsTourismLoading(false)
      }
      setTourisms(tourisms)
    },
    [env],
  )
  const onTourismTypeClick = React.useCallback(
    (event) => updateTourisms(directions[1], Number(event.currentTarget.dataset.type)),
    [directions, updateTourisms],
  )
  const onBack = React.useCallback(() => {
    switch (view) {
      case VIEW.LIST:
        setTourisms([])
        setTourismType(Position.Type.ScenicSpot)
        setTourismId(null)
        setRoutes([])
        setCheckedIndexes([])
        setView(VIEW.SEARCH)
        break
      case VIEW.DETAIL:
        setView(VIEW.LIST)
        break
    }
  }, [view])
  const onSubmit = React.useCallback(
    async (values, formik) => {
      const [from, to] = directions
      let routes = []

      try {
        const departureDate = values.leaveType === metropia.LeaveType.LEAVE_NOW ? new Date() : values.date
        const { data: routesOfMetropia = [] } = await api.fetchDirectionRoutesByMetropia({
          ...values,
          origin: Position.stringify(from),
          originName: from.name,
          originAddress: from.address,
          destination: Position.stringify(to),
          destinationName: to.name,
          destinationAddress: to.address,
          weekday: getDay(departureDate),
          departureDate,
          departureTime: { [metropia.LeaveType.LEAVE_NOW]: new Date(), [metropia.LeaveType.DEPART_AT]: values.time }[values.leaveType] ?? null,
          arrivalTime: values.leaveType === metropia.LeaveType.ARRIVE_AT ? values.time : null,
          transitModes: values.preferredTransitModes.flatMap((mode) => TRANSIT_MODE_TO_METROPIA_API[mode]).filter(Boolean),
          minTransferSeconds: values.transferMinutesRange[0] * 60,
          maxTransferSeconds: values.transferMinutesRange[1] * 60,
        })
        const { data: routesOfGoogle = [] } =
          env.VISIBLE_OPTION.ADVANCED && values.useGoogle
            ? await api.fetchDirectionRoutesByGoogle({
                origin: Position.stringify(from),
                destination: Position.stringify(to),
                transitOptions: {
                  departureTime: set(values.date, { hours: values.time.getHours(), minutes: values.time.getMinutes() }),
                  modes: values.preferredTransitModes.map((mode) => TRANSIT_MODE_TO_GOOGLE_API[mode]).filter(Boolean),
                },
              })
            : { data: [] }
        routes = routesOfMetropia.concat(routesOfGoogle)
      } catch (error) {
        if (error.status === 401) {
          toast({ status: 'warning', description: i18.t('warn.engine-busy') })
        } else {
          toast({ status: 'error', description: i18.t('error.unspecific') })
        }
      } finally {
        setOption(values) // to render leaveType, date and time, use google or not in list view
        formik.setSubmitting(false)
      }

      if (routes.length === 0) {
        toast({ status: 'warning', description: i18.t('common.empty-content') })
      } else {
        env.TOURISMS.VISIBLE && updateTourisms(to, tourismType)
        setRoutes(routes)
        setCheckedIndexes([0])
        setView(VIEW.LIST)
      }
    },
    [env, directions, tourismType, updateTourisms],
  )
  const onCheckboxChange = React.useCallback((stringIndexes) => setCheckedIndexes(stringIndexes.map(Number)), [])

  React.useEffect(() => {
    if (sortedRoutes.length === 0 || checkedIndexes.length === 0) return

    const group = new H.map.Group()
    const [departure, arrival] = directions
    const departureBubble = new H.ui.InfoBubble(departure, { content: departure.name })
    const arrivalBubble = new H.ui.InfoBubble(arrival, { content: arrival.name })
    arrivalBubble.addClass('arrival')
    const bubbles = [departureBubble, arrivalBubble]

    const marksAndLines = checkedIndexes.flatMap((checkedIndex) => {
      const { sections, seconds, source } = sortedRoutes[checkedIndex]
      const isHighlighted = checkedIndex === highlightedIndex

      const { lineStringsBySection, directionsBySection, center } = getFromEncodedPolyline(sections, source)
      const timingBubble = new H.ui.InfoBubble(center, { content: FORMAT_OF_DATE.DURATION(convertSecondsToDuration(seconds)) })
      timingBubble.addClass(`timing${isHighlighted ? ' is-highlighted' : ''}`)
      bubbles.push(timingBubble)

      return sections
        .flatMap((section, index) => {
          const { icon } = getTransitNotation(section, isHighlighted)
          const [departure, arrival] = directionsBySection[index]

          if (isHighlighted && section.type === metropia.TransportMode.TRANSIT) {
            const departureBubble = new H.ui.InfoBubble(departure, { content: section.departure.place.name })
            const arrivalBubble = new H.ui.InfoBubble(arrival, { content: section.arrival.place.name })
            bubbles.push(departureBubble, arrivalBubble)
          }

          return [new H.map.Marker(departure, { icon }), new H.map.Marker(arrival, { icon })]
        })
        .concat(
          sections.flatMap((section, index) => {
            const { color } = getTransitNotation(section, isHighlighted)
            const isDashed = isHighlighted && [...TRANSPORT_MODE.CYCLING.split(','), ...TRANSPORT_MODE.WALKING.split(',')].includes(section.type)
            const opacity = isHighlighted ? 0.9 : 0.6

            return getPolylineWithBorder(lineStringsBySection[index], color, isDashed, opacity)
          }),
        )
        .concat(
          isHighlighted ? [new H.map.Marker(departure, { icon: MAP_ICON.DEPARTURE }), new H.map.Marker(arrival, { icon: MAP_ICON.ARRIVAL })] : [],
        )
        .map((object) => object.setData(checkedIndex))
    })
    const onTap = (event) => {
      const index = event.target.getData()
      if (highlightedIndex === index) return

      setCheckedIndexes([...checkedIndexes.filter((checkedIndex) => checkedIndex !== index), index]) // move new highlighted index to last to display on the map
    }

    const map = mapRef.current
    const ui = uiRef.current
    const viewPort = map.getViewPort()
    const isMobile = viewPort.width < MD_WIDTH
    if (isMobile) {
      viewPort.setPadding(...VIEWPORT_PADDING.MD)
    } else {
      viewPort.setPadding(...VIEWPORT_PADDING.LG)
    }

    bubbles.forEach((bubble) => ui.addBubble(bubble))
    group.addObjects(marksAndLines)
    group.addEventListener('tap', onTap, false)
    map.addObject(group)
    map.getViewModel().setLookAtData({ bounds: group.getBoundingBox() })

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      group.removeObjects(marksAndLines)
      group.removeEventListener('tap', onTap, false)
      map?.removeObject(group)
    }
  }, [mapRef, uiRef, directions, sortedRoutes, checkedIndexes, highlightedIndex])

  // render transit stops' dots
  React.useEffect(() => {
    if (sortedRoutes.length === 0 || checkedIndexes.length === 0) return

    const bubbles = []
    const dotMarkers = checkedIndexes
      .flatMap((checkedIndex) => sortedRoutes[checkedIndex].sections)
      .filter((section) => section.type === metropia.TransportMode.TRANSIT)
      .flatMap((section) => section.intermediateStops)
      .map(({ departure }) => {
        bubbles.push(new H.ui.InfoBubble(departure.place.location, { content: departure.place.name }))
        return new H.map.Marker(departure.place.location, { icon: MAP_ICON.DOT2 })
      })
      .map((marker, index) => marker.setData(index))

    const onEnter = (event) => bubbles[event.target.getData()].open()
    const onLeave = (event) => bubbles[event.target.getData()].close()

    const map = mapRef.current
    const ui = uiRef.current
    bubbles.forEach((bubble) => {
      bubble.close()
      ui.addBubble(bubble)
    })
    dotMarkers.map((marker) => marker.addEventListener('pointerenter', onEnter, false))
    dotMarkers.map((marker) => marker.addEventListener('pointerleave', onLeave, false))
    map.addObjects(dotMarkers)

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      dotMarkers.map((marker) => marker.removeEventListener('pointerenter', onEnter, false))
      dotMarkers.map((marker) => marker.removeEventListener('pointerleave', onLeave, false))
      map?.removeObjects(dotMarkers)
    }
  }, [mapRef, uiRef, sortedRoutes, checkedIndexes])

  // render highlighted bike marks
  React.useEffect(() => {
    if (sortedRoutes.length === 0 || highlightedIndex === null) return

    const bubbles = []
    const bikeMarkers = sortedRoutes[highlightedIndex].sections
      .filter((section) => TRANSPORT_MODE.CYCLING.includes(section.transport.mode) && checkIsYouBike(section))
      .flatMap(({ departure, arrival }) => {
        const directionBubble = [
          new H.ui.InfoBubble(departure.place.location, { content: departure.place.name }),
          new H.ui.InfoBubble(arrival.place.location, { content: arrival.place.name }),
        ]
        directionBubble.forEach((bubble) => bubble.addClass('station'))
        bubbles.push(...directionBubble)
        return [
          new H.map.Marker(departure.place.location, { icon: MAP_ICON.BIKE }),
          new H.map.Marker(arrival.place.location, { icon: MAP_ICON.BIKE }),
        ]
      })
      .map((marker, index) => marker.setData(index))

    const onEnter = (event) => bubbles[event.target.getData()].open()
    const onLeave = (event) => bubbles[event.target.getData()].close()

    const map = mapRef.current
    const ui = uiRef.current
    bubbles.forEach((bubble) => {
      bubble.close()
      ui.addBubble(bubble)
    })
    bikeMarkers.map((marker) => marker.addEventListener('pointerenter', onEnter, false))
    bikeMarkers.map((marker) => marker.addEventListener('pointerleave', onLeave, false))
    map.addObjects(bikeMarkers)

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      bikeMarkers.map((marker) => marker.removeEventListener('pointerenter', onEnter, false))
      bikeMarkers.map((marker) => marker.removeEventListener('pointerleave', onLeave, false))
      map?.removeObjects(bikeMarkers)
    }
  }, [mapRef, uiRef, sortedRoutes, highlightedIndex])

  // render highlighted rental marks
  React.useEffect(() => {
    if (sortedRoutes.length === 0 || highlightedIndex === null) return

    const bubbles = []
    const carMarkers = sortedRoutes[highlightedIndex].sections
      .filter((section) => TRANSPORT_MODE.DRIVING.includes(section.transport.mode) && checkIsIRent(section))
      .map(({ departure, transport }) => {
        const bubble = new H.ui.InfoBubble(departure.place.location, { content: transport.station })
        bubble.addClass('station')
        bubbles.push(bubble)
        return new H.map.Marker(departure.place.location, { icon: MAP_ICON.RENTAL_CAR })
      })
    const scooterMarkers = sortedRoutes[highlightedIndex].sections
      .filter((section) => TRANSPORT_MODE.SCOOTER.includes(section.transport.mode) && checkIsIRent(section))
      .map(({ departure, transport }) => {
        const bubble = new H.ui.InfoBubble(departure.place.location, { content: transport.carNumber })
        bubble.addClass('station')
        bubbles.push(bubble)
        return new H.map.Marker(departure.place.location, { icon: MAP_ICON.RENTAL_SCOOTER })
      })

    const map = mapRef.current
    const ui = uiRef.current
    bubbles.forEach((bubble) => ui.addBubble(bubble))
    map.addObjects([...carMarkers, ...scooterMarkers])

    return () => {
      bubbles.forEach((bubble) => ui?.removeBubble(bubble))
      map?.removeObjects([...carMarkers, ...scooterMarkers])
    }
  }, [mapRef, uiRef, sortedRoutes, highlightedIndex])

  React.useEffect(() => {
    if (!env.TOURISMS.VISIBLE || tourisms.length === 0) return

    const group = new H.map.Group()
    const markers = tourisms.map(
      (position) =>
        new H.map.DomMarker(position, {
          icon: MAP_ICON.MARK,
          data: {
            position,
            isChecked: position.id === tourismId,
            onClick: () => selectTourism(position),
          },
        }),
    )
    const map = mapRef.current
    group.addObjects(markers)
    map.addObject(group)

    return () => group.removeObjects(markers)
  }, [env, mapRef, tourisms, tourismId, selectTourism])

  // --- Tourism navigating ---
  const navigateTourism = React.useCallback((event) => {
    const elements = Array.from(document.querySelectorAll('[data-is-in-viewport]'))
    const visibles = Array.from(document.querySelectorAll('[data-is-in-viewport=true]'))
    if (event.currentTarget.dataset.direction === NAVIGATION_DIRECTION.PREV) {
      const firstVisibleIndex = elements.indexOf(visibles[0])
      const scrollToIndex = visibles.length > firstVisibleIndex ? 0 : firstVisibleIndex - visibles.length
      elements[scrollToIndex]?.scrollIntoView({ behavior: 'smooth' })
    } else if (event.currentTarget.dataset.direction === NAVIGATION_DIRECTION.NEXT) {
      const lastVisibleIndex = elements.indexOf(visibles.slice(-1)[0])
      const scrollToIndex = lastVisibleIndex + visibles.length > elements.length - 1 ? elements.length - 1 : lastVisibleIndex + visibles.length
      elements[scrollToIndex]?.scrollIntoView({ behavior: 'smooth', inline: 'end' })
    }
  }, [])
  const observerRef = React.useRef(
    // DOC OF OPTIONS: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
    new window.IntersectionObserver(
      (entries) => {
        // display or hide navigation
        const { parentElement: parent } = entries[0].target
        const directions = document.querySelectorAll('[data-navigation]')
        if (directions.length > 0 && parent !== null) {
          Object.assign(directions[0].style, { display: parent.scrollLeft > 50 ? 'inline-flex' : 'none' })
          Object.assign(directions[1].style, { display: parent.scrollWidth - parent.scrollLeft - parent.clientWidth > 50 ? 'inline-flex' : 'none' })
        }

        // navigation scrolling anchor
        entries.forEach((entry) => (entry.target.dataset.isInViewport = entry.intersectionRatio === 1))
      },
      { threshold: 1 },
    ),
  )
  React.useEffect(() => {
    if (!env.TOURISMS.VISIBLE || tourisms.length === 0) return
    document.querySelectorAll('[data-scroll-target]').forEach((element) => observerRef.current.observe(element))
  }, [env, tourisms.length])
  // --- Tourism navigating ---

  return (
    <Flex
      pointerEvents='none'
      zIndex='1'
      position='fixed'
      top={{ base: '1rem', md: '1.5rem' }}
      left={{ base: '0.5rem', md: '1rem' }}
      right={{ base: '0.5rem', md: 'auto' }}
      // NOTE: full height on list and detail view
      bottom='1rem'
      // parent-100% - top-distance - bottom-distance
      maxHeight={{ base: 'calc(100% - 1rem * 2)', md: 'calc(100% - 1.5rem - 1rem)' }}
    >
      <Stack flex='1' direction={{ base: 'column', md: 'row' }} align='flex-start'>
        {view !== VIEW.SEARCH && <NavigationArrow direction={NAVIGATION_DIRECTION.PREV} onClick={onBack} />}

        <Flex
          width={{ base: `calc(${window.innerWidth}px - 1rem)`, md: '25.5rem' }}
          // NOTE: base: 100% for half down on list view, md: 100% for full height on list and detail view
          height='100%'
          // NOTE: base: (parent-100% - icon-height - margin-top(spacing of Stack parent)), md: (same as parent)
          maxHeight={{ base: view === VIEW.SEARCH ? '100%' : 'calc(100% - 2rem - 0.5rem)', md: '100%' }}
        >
          {view === VIEW.SEARCH ? (
            <Stack width='inherit'>
              <Flex pointerEvents='auto' width='inherit' borderRadius='xl' bgColor='white' shadow='bold'>
                <DirectionsSearchboxes
                  value={directions}
                  onChange={setDirections}
                  placeholder={[i18.t('common.origin'), i18.t('common.destination')]}
                />
              </Flex>

              {directions.every(Position.isValid) && (
                <Formik validateOnMount validationSchema={VALIDATION_SCHEMA[env.LOCATION]} initialValues={option} onSubmit={onSubmit}>
                  {(formik) => (
                    <Flex
                      as={FormikChakraForm}
                      pointerEvents='auto'
                      direction='column'
                      borderRadius='xl'
                      bgColor='gray.100'
                      shadow='bold'
                      overflow='hidden'
                    >
                      <TripPlanningOption formik={formik} />

                      <Flex paddingX='1.25rem' padding='0.75rem' shadow='0 -2px 6px 0 rgba(67, 86, 100, 0.1)'>
                        <Button type='submit' isLoading={formik.isSubmitting} colorScheme='brand' isFullWidth>
                          {i18.t('common.search')}
                        </Button>
                      </Flex>

                      {formik.isSubmitting && <Loading position='absolute' />}
                    </Flex>
                  )}
                </Formik>
              )}
            </Stack>
          ) : view === VIEW.LIST ? (
            <Stack
              pointerEvents='auto'
              spacing='0'
              divider={<Divider borderColor='gray.300' />}
              width='inherit'
              mt={{ base: 'auto', md: '0' }}
              height={{ base: '16rem', md: 'auto' }}
              transform={{ base: 'translateY(1rem)', md: 'none' }}
              borderTopRadius='xl'
              borderBottomRadius={{ base: 'none', md: 'xl' }}
              bgColor='white'
              shadow='bold'
            >
              <Stack spacing='0' paddingX='1rem' paddingY='0.75rem' bgColor='gray.100' borderTopRadius='inherit'>
                <Flex>
                  <Text flexShrink='0' width='2.5rem' color='gray.500' fontSize='sm'>
                    {i18.t('common.from')}
                  </Text>
                  <Text fontSize='sm' fontWeight='600'>
                    {directions[0].name}
                  </Text>
                </Flex>
                <Flex>
                  <Text flexShrink='0' width='2.5rem' color='gray.500' fontSize='sm'>
                    {i18.t('common.to')}
                  </Text>
                  <Text fontSize='sm' fontWeight='600'>
                    {directions[1].name}
                  </Text>
                </Flex>

                <Stack direction='row' spacing='1rem' align='center'>
                  <Text flexShrink='0' fontSize='sm' fontWeight='600'>
                    {i18.t('trip-planning.label.sort-preference')}
                  </Text>

                  <Flex flexDirection='row' flexWrap='wrap' align='center'>
                    <Stack mr='0.5rem' direction='row' spacing='0.25rem' align='center'>
                      <Text flexShrink='0' color='gray.500' fontSize='sm'>
                        {i18.t('trip-planning.label.sort-preference-first')}
                      </Text>
                      <Selector
                        options={SORT_OPTIONS}
                        value={sort.first}
                        onChange={(first) => {
                          setSort({ ...sort, first })
                          setCheckedIndexes([0])
                        }}
                      />
                    </Stack>

                    <Stack direction='row' spacing='0.25rem' align='center'>
                      <Text flexShrink='0' color='gray.500' fontSize='sm'>
                        {i18.t('trip-planning.label.sort-preference-then')}
                      </Text>
                      <Selector
                        options={SORT_OPTIONS}
                        value={sort.then}
                        onChange={(then) => {
                          setSort({ ...sort, then })
                          setCheckedIndexes([0])
                        }}
                      />
                    </Stack>
                  </Flex>
                </Stack>

                <Stack direction='row' spacing='0.25rem' align='center'>
                  <Icon as={Icons.Clock} color='gray.500' boxSize='1.25rem' />
                  <Text color='gray.500' fontSize='sm'>
                    {[LEAVE_TYPE_TEXT[option.leaveType]]
                      .concat(
                        option.leaveType !== metropia.LeaveType.LEAVE_NOW && isValid(option.date) && isValid(option.time)
                          ? [
                              format(option.date, i18.t('format-pattern.date-picker.input'), { locale: window.datefns_locale }),
                              format(option.time, i18.t('format-pattern.time-picker.input'), { locale: window.datefns_locale }),
                            ]
                          : [],
                      )
                      .join(' ')}
                  </Text>
                </Stack>
              </Stack>

              <Flex paddingY='0.5rem' direction='column' align='flex-start' overflow='auto'>
                <CheckboxGroup colorScheme='green' value={checkedIndexes.map(String)} onChange={onCheckboxChange}>
                  <Stack spacing='0' divider={<Divider />} width='100%'>
                    {sortedRoutes.map((route, index) => (
                      <Stack
                        key={index}
                        direction='row'
                        spacing='1rem'
                        paddingX='1rem'
                        align='center'
                        bgColor={checkedIndexes.includes(index) ? 'brand.50' : 'transparent'}
                        aria-selected={index === highlightedIndex}
                        _hover={{ bgColor: 'gray.50' }}
                        _selected={{ bgColor: 'gray.300' }}
                      >
                        <Checkbox value={String(index)} isDisabled={checkedIndexes.length === 3 && !checkedIndexes.includes(index)} />

                        <RouteCard
                          useGoogle={option.useGoogle}
                          route={route}
                          flex='1'
                          paddingY='1rem'
                          onClick={() => {
                            setCheckedIndexes([index])
                            setView(VIEW.DETAIL)
                          }}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </CheckboxGroup>

                {routes.length === 0 && (
                  <Text paddingY='1rem' paddingX='1rem' fontSize='sm' color='gray.700'>
                    {i18.t('trip-planning.empty-content')}
                  </Text>
                )}
              </Flex>
            </Stack>
          ) : (
            view === VIEW.DETAIL && (
              <Flex
                pointerEvents='auto'
                pos='relative'
                mt={{ base: 'auto', md: '0' }}
                w='inherit'
                h={{ base: isDetailExtended ? '100%' : '8rem', md: 'auto' }}
                transform={{ base: isDetailExtended ? 'none' : 'translateY(1rem)', md: 'none' }}
                transitionDuration='400ms'
                transitionTimingFunction='ease-in-out'
                borderTopRadius='xl'
                borderBottomRadius={{ base: isDetailExtended ? 'xl' : 'none', md: 'xl' }}
                bgColor='white'
                shadow='bold'
              >
                <NavigationArrow
                  display={{ md: 'none' }}
                  pos='absolute'
                  top='0'
                  left='50%'
                  transform='translateX(-50%)'
                  shadow='none'
                  borderColor='transparent'
                  size='xs'
                  onClick={toggleDetail}
                  direction={isDetailExtended ? NAVIGATION_DIRECTION.DOWN : NAVIGATION_DIRECTION.UP}
                />
                <RouteDetail option={option} route={sortedRoutes[highlightedIndex]} directions={directions} />
              </Flex>
            )
          )}
        </Flex>
      </Stack>

      {env.TOURISMS.VISIBLE && (
        <Portal containerRef={mapCtrlContainerRef}>
          {routes.length > 0 && (
            <Flex
              direction='column'
              ml={{ base: 'auto', md: '28.5rem' }}
              mb={{ base: view === VIEW.DETAIL ? '8rem' : '16rem', md: 'auto' }}
              position='relative'
              backdropFilter={{ base: isTourismVisible ? 'blur(3px)' : 'none', md: 'none' }}
            >
              <Stack direction='row' pt='0.5rem' pb={isTourismVisible ? '0' : '1rem'} px={{ base: '0.5rem', md: '1.25rem' }}>
                <NavigationArrow onClick={toggleTourism} direction={isTourismVisible ? NAVIGATION_DIRECTION.DOWN : NAVIGATION_DIRECTION.UP} />
                {isTourismVisible &&
                  TOURISM_TYPE_OPTIONS.map((option, index) => (
                    <Button
                      key={index}
                      data-type={option.value}
                      pointerEvents='auto'
                      size='sm'
                      variant='ghost'
                      colorScheme='brand'
                      bgColor='whiteAlpha.700'
                      shadow='bold'
                      onClick={option.value === tourismType ? null : onTourismTypeClick}
                      isActive={option.value === tourismType}
                      _active={{ color: 'white', bgColor: 'brand.500', shadow: 'bold' }}
                    >
                      {i18.t(option.label)}
                    </Button>
                  ))}
              </Stack>

              <Collapse in={isTourismVisible}>
                <Flex pointerEvents='auto' position='relative'>
                  {tourisms.length > 0 && (
                    <NavigationArrow
                      onClick={navigateTourism}
                      data-navigation
                      direction={NAVIGATION_DIRECTION.PREV}
                      zIndex='1'
                      pos='absolute'
                      left='1rem'
                      top='50%'
                      transform='translateY(-50%)'
                    />
                  )}
                  {tourisms.length > 0 && (
                    <NavigationArrow
                      onClick={navigateTourism}
                      data-navigation
                      direction={NAVIGATION_DIRECTION.NEXT}
                      zIndex='1'
                      pos='absolute'
                      right='1rem'
                      top='50%'
                      transform='translateY(-50%)'
                    />
                  )}

                  <Stack
                    direction='row'
                    spacing='0.75rem'
                    pl={{ base: '0.5rem', md: '1.25rem' }}
                    pr={{ base: '0.5rem', md: '1rem' }}
                    pt='0.5rem'
                    pb='1rem'
                    overflow='auto'
                  >
                    {tourisms.length === 0 ? (
                      <Flex padding='2rem 3.75rem' bgColor='white' borderRadius='xl' shadow='bold'>
                        {i18.t('common.empty-content')}
                      </Flex>
                    ) : (
                      tourisms.map((tourism, index) => (
                        <Flex key={index} shrink='0' data-scroll-target={tourism.id ?? index}>
                          <TourismCard position={tourism} width='16rem' onClick={() => selectTourism(tourism)} isChecked={tourism.id === tourismId} />
                        </Flex>
                      ))
                    )}
                  </Stack>
                </Flex>
              </Collapse>

              {isTourismLoading && <Loading position='absolute' pointerEvents='auto' />}
            </Flex>
          )}
        </Portal>
      )}
    </Flex>
  )
}

export default Directions
